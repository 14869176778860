<template >
    <div>
        <!-- 1level -->
        <div v-ripple.center="!props.disabled" class="d-flex justify-center align-center" :class="{
            largestyle_disabled: props.disabled,
            largestyle: !props.disabled
        }" v-if="props.size == 'large'" @click="handleclick">
            <div class="font-weight-bold d-flex align-center" :style="props.disabled ? '-webkit-filter: grayscale(1)' : ''" >
                <v-progress-circular indeterminate color="#ffffff99" :size="15" class="progress-circular"
                    v-if="_loading"></v-progress-circular>
                <div :style="_loading ? 'opacity: 0 !important;' : 'opacity: 1 !important;'">
                    <slot></slot>
                </div>
            </div>
        </div>
        
        <!-- 2level -->
        <div v-ripple.center="!props.disabled" class="norstyle_strong elevation-0 d-flex justify-center align-center"
            v-if="props.size == 'normal' && props.skin == 'strong'" @click="handleclick">
            <div class="font-weight-bold d-flex align-center" >
                <v-progress-circular indeterminate color="#ffffffcc" :size="15" v-if="_loading"></v-progress-circular>
                <slot v-if="!_loading"></slot>
            </div>
            <v-icon :icon="props.endicon"  v-if="props.endicon" style="position: absolute;top: 2px;right: 5px;font-size: 32px;" />
        </div>
        <div v-ripple.center="!props.disabled" v-ripple class="norstyle_weak-1 d-flex justify-center align-center"
            v-if="props.size == 'normal' && props.skin == 'weak-1'" @click="handleclick">
            <div class="font-weight-bold d-flex align-center " >
                <v-progress-circular indeterminate color="primary" :size="15" v-if="_loading"></v-progress-circular>
                <slot v-if="!_loading"></slot>
            </div>
            <v-icon :icon="props.endicon"  v-if="props.endicon" style="position: absolute;top: 2px;right: 5px; font-size: 32px;" />
        </div>
        <div v-ripple.center="!props.disabled" class="norstyle_weak-2  d-flex justify-center align-center"
            v-if="props.size == 'normal' && props.skin == 'weak-2'" @click="handleclick">
            <div class="font-weight-bold d-flex align-center" >
                <v-progress-circular indeterminate color="primary" :size="15" v-if="_loading"></v-progress-circular>
                <slot v-if="!_loading"></slot>
            </div>
            <v-icon :icon="props.endicon"  v-if="props.endicon" style="position: absolute;top: 2px;right: 5px;font-size: 32px;" />
        </div>

        <!-- 3level -->
        <div v-ripple.center="!props.disabled" class="smallstyle_strong  d-flex justify-center align-center"
            v-if="props.size == 'small' && props.skin == 'strong'" @click="handleclick"
            :style="props.tab ? 'border-radius:10px;' : ''">
            <div class="d-flex align-center">
                <v-progress-circular indeterminate color="#ffffff99" :size="15" class="progress-circular mr-2"
                    v-if="_loading"></v-progress-circular>
                <div class="d-flex align-center" :style="_loading ? 'opacity: 0 !important;' : 'opacity: 1 !important;'">
                    <slot></slot>
                </div>
            </div>
            <v-icon :icon="props.endicon"  v-if="props.endicon" style="position: absolute;top: -3px;right: 2px;" />
        </div>

        <div v-ripple.center="!props.disabled" class="smallstyle_weak-1  d-flex align-center" :class="`justify-${justify}`"
            v-if="props.size == 'small' && props.skin == 'weak-1'" @click="handleclick"
            :style="props.tab ? 'border-radius:10px;background: #4F40E333;' : ''">
            <div class="d-flex align-center btn-box">
                <v-progress-circular indeterminate color="primary" :size="15" class="progress-circular mr-2"
                    v-if="_loading" style="left: 43%;"></v-progress-circular>
                <div class="d-flex align-center btn-content" :style="_loading ? 'opacity: 0 !important;' : 'opacity: 1 !important;'" >
                    <slot></slot>
                </div>
            </div>
            <v-icon :icon="props.endicon"  v-if="props.endicon" style="position: absolute;top: 5px;right: 2px; " />
        </div>
        
        <div v-ripple.center="!props.disabled" class="smallstyle_weak-2 d-flex justify-center align-center"
            v-if="props.size == 'small' && props.skin == 'weak-2'" @click="handleclick"
            :style="props.tab ? 'border-radius:10px;' : ''">
            <div class="d-flex align-center">
                <v-progress-circular indeterminate color="primary" :size="15" class="progress-circular mr-2"
                    v-if="_loading"></v-progress-circular>
                <slot v-if="!_loading"></slot>
            </div>
            <v-icon :icon="props.endicon"  v-if="props.endicon" style="position: absolute;top: 5px;right: 2px;" />
        </div>
        <div v-ripple.center="!props.disabled" class="smallstyle_weak-3 d-flex justify-center align-center"
            v-if="props.size == 'small' && props.skin == 'weak-3'" @click="handleclick"
            :style="props.tab ? 'border-radius:10px;'  : ''">
            <div class="d-flex align-center" >
                <v-progress-circular indeterminate color="primary" :size="15" class="progress-circular mr-2"
                    v-if="_loading"></v-progress-circular>
                <slot v-if="!_loading"></slot>
            </div>
            <v-icon :icon="props.endicon"  v-if="props.endicon" style="position: absolute;top: 5px;right: 2px;" />
        </div>
    </div>
</template>
  
<script setup lang="ts">
import { watch, ref, onMounted, getCurrentInstance } from "vue";
const emit = defineEmits(['click'])

interface Props {
    size?: string,
    skin?: string,
    userdata?: any,
    endicon?: string,
    color?: string
    loading?: boolean,
    disabled?: boolean,
    tab?: boolean,
    justify?: string,
}
const props = withDefaults(defineProps<Props>(), {
    /**
     * @param {string} size large | normal | small
     * @description button size
     */
    size: "normal",

    /**
     * @param {string} skin strong | weak-1 | weak-2 
     * @description button skin
     */
    skin: "strong",

    /**
     * @description endicon font icon example $next
     */
    endicon: "",

    /**
     * @description text color
     */
    color: "#FFFFFF",

    /**
     * @description Transfer user information
     */
    userdata: {},

    loading: false,

    disabled: false,

    tab: false,
    justify: "center"
})

const handleclick = () => {
    if (props.disabled) return
    emit('click', props.userdata)
}

const _loading = ref(props.loading)
let tid: any = null
watch(() => props.loading, (val) => {
    _loading.value = val

    if (tid) clearTimeout(tid)
    tid = setTimeout(() => {
        _loading.value = false
    }, 10000);
})


</script>
<style lang="scss" scoped>
.progress-circular {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.largestyle {
    color: #FFFFFF;
    background-image: url(@/assets/images/button/bk.png) !important;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 40px;
    padding: 0 50px;
    border-radius: 32px !important;
    font-size: 20px;
    font-weight: 700;
    position: relative;
}

.largestyle_disabled {
    color: #FFFFFF;
    background-image: url(@/assets/images/button/bk2.png) !important;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 40px;
    padding: 0 50px;
    border-radius: 32px !important;
    font-size: 20px;
    font-weight: 700;
    position: relative;
}


.norstyle_strong {
    color: #FFFFFF;
    background-image: linear-gradient(96deg, #659BFF 1.8%, #5344E5 19.28%, #5945E4 68.17%, #C071EF 94.65%) !important;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 35px;
    padding: 0px 20px;
    border-radius: 25px !important;
    font-size: 15px;
    font-weight: 700 !important;
    position: relative;
    box-shadow: 0px -0.5px 1px 0px rgba(55, 49, 146, 0.50) inset, 0px 0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
}

.norstyle_weak-1 {
    color: #FFFFFF;
    min-height: 35px;
    padding: 0px 20px;
    border-radius: 25px !important;
    font-size: 15px;
    font-weight: bolder;
    position: relative;
    border-radius: 42px;
    
    background: rgba(79, 64, 227, 0.5);
    box-shadow: 0px 2px 2px 0px rgba(68, 64, 164, 0.20) inset;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.10));
}

.norstyle_weak-2 {
    color: #FFFFFF;
    background: linear-gradient(90deg, #727272 0%, #606060 52.08%, #6E6E6E 99.79%);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
    min-height: 35px;
    padding: 0 20px;
    border-radius: 25px !important;
    font-size: 15px;
    font-weight: 700;
    position: relative;
}

.norstyle_weak-3 {
    color: #FFFFFF;
    background: linear-gradient(90deg, #727272 0%, #606060 52.08%, #6E6E6E 99.79%);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 0 20px;
    border-radius: 25px !important;
    font-size: 15px;
    font-weight: 700;
    position: relative;
}

.smallstyle_strong {
    color: #FFFFFF;
    background-image: linear-gradient(96deg, #659BFF 1.8%, #5344E5 19.28%, #5945E4 68.17%, #C071EF 94.65%) !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 0px -0.5px 1px 0px rgba(55, 49, 146, 0.50) inset, 0px 0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
    height: 29px;
    padding: 0 10px !important;
    border-radius: 20px;
    font-size: 13px !important;
    font-weight: 700 !important;
    position: relative;
}

.smallstyle_weak-1 {
    color: #FFFFFF;
    min-height: 29px;
    padding: 0 10px !important;
    border-radius: 20px;
    font-size: 13px !important;
    font-weight: 700 !important;
    position: relative;

    background: #4F40E344;
    box-shadow: 0px 2px 2px 0px rgba(68, 64, 164, 0.20) inset;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.10));
}

.smallstyle_weak-2 {
    color: #FFFFFF;
    border-radius: 68px;
    background: linear-gradient(90deg, #727272 0%, #606060 52.08%, #6E6E6E 99.79%);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
    min-height: 29px;
    padding: 0 10px !important;
    border-radius: 20px;
    font-size: 13px !important;
    font-weight: 700 !important;
    position: relative;
}

.smallstyle_weak-3 {
    color: #FFFFFF;
    background: rgba(49, 47, 62, 0.5);
    min-height: 29px;
    padding: 0 10px !important;
    border-radius: 20px;
    font-size: 13px !important;
    font-weight: 700 !important;
    position: relative;
}
</style>
  