<template>
  <ClientOnly>
    <div class="popup-container">
      <!-- 页面级加载动画 -->
      <div v-if="pageloading" class="d-flex flex-column align-center justify-center loading"
        style="position: fixed;left: 0px;right: 0px;top:0px;bottom: 0px;pointer-events: none">
        <Vue3Lottie style="scale: 3;position: fixed;" :animationData="LoadingAnimationData" :width="100" />
      </div>
      <!-- 试玩游戏弹窗 -->
      <GamePop ref="gamePopRef" />
      <!--公共的消息条-->
      <v-snackbar v-model="snackbar" :timeout="1500" location="top" :color="colortype">
        {{ text }}
      </v-snackbar>
      <!--公共领取奖励的文字弹窗-->
      <v-snackbar v-model="gamesnackbar" :timeout="1500" location="top" rounded="pill" color="#0D1233"  class="custom-snackbar" :class="gamesnackbar? 'v-snackbar--active' : ''">
        <template v-slot:default>
          <img class="snackbar-img" :src="gameMsgImg"/>Bom! Você ganha <span class="snackbar-money">R${{gamereward}}</span>!
        </template>
      </v-snackbar>
      <Commdlg />
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { Vue3Lottie } from 'vue3-lottie'
import { useBus } from '~/composables/xfun/bus'
import LoadingAnimationData from '@/assets/ani/9GVY0RKKkP.json'
import gameMsgImg from '@/assets/components/gameMsg/receive.png'
const commonStore = useCommonStore()
const userStore = useUserStore()
const activeStore = useActiveStore()
const bus = useBus()

/* GamePop */
const gamePopRef = ref(null)
const openGameCard = async (card: any) => {
  if (gamePopRef.value) {
    if (userStore.isLogin) {
      const hascollect = await userStore.game_iscollect_actions({
        mer: card.userdata.mid,
        gameId: card.userdata.gameid
      })
      card.userdata.hascollect = hascollect.data
    }
    let a = gamePopRef.value
    a.handleGame(card)
  }
}

/* PageLoading */
let timerId: any = null
const pageloading = ref(false)
if (process.client) {
  const bus = useBus()
  bus.firepageloading.reset()
  bus.fireShowGameCard.reset()
  bus.firepageloading.on((show: boolean) => {
    pageloading.value = show
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      pageloading.value = false
    }, 5000);
  })
  bus.fireShowGameCard.on(openGameCard)
}

/** 消息提示框 */
const snackbar = ref(false)
/**领取奖励文字弹窗 */
const gamesnackbar = ref(false)
const gamereward = ref('')
const text = ref('')
const colortype = ref('error')
bus.firemsg.reset()
bus.firemsg.on((msg: string, type: string) => {
  snackbar.value = true
  text.value = msg
  colortype.value = type
})
bus.firgameMsg.reset()
bus.firgameMsg.on((msg: string, type: string) => {
  gamesnackbar.value = true
  gamereward.value = msg
})
</script>

<style scoped>
.btbsize {
  min-width: 100px;
}

.custom-card {
  background: #20233C;
  border-radius: 20px 20px 30px 30px !important;
  border: 1px solid #505363;
  width: 327px;
  min-height: 150px;
}

.fixed-bottom {
  position: fixed;
  bottom: 80px;
  right: 14px;
  z-index: 100;
  fill: #4F40E3;
  box-shadow: 0px 1px 2px 0px rgba(180, 172, 255, 0.70) inset;
  filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.50));
  width: 40px;
  height: 40px;
}

.title {
  font-family: Microsoft YaHei Bold;
  font-size: 18px;
  font-weight: 700;
  height: 36px;

  background: linear-gradient(180deg, #9DD0F8 33.85%, #FBFDFF 75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.content {
  color: #A6A6BD;
  font-family: Arial !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.loading {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px) !important;
  opacity: 1;
  z-index: 999;
}

.custom-snackbar{
  .snackbar-img{
    width: 25px;
    height: 20px;
    display: inline-block;
    margin-right: 4px
  }
  .snackbar-money{
    color: #EABD31;
    font-size: 15px;
    line-height: normal;
  }
  :deep(.v-snackbar__wrapper){
    border: 2px solid rgba(255, 255, 255, 0.30);
    min-width:235px;
    min-height: 40px;
  }
  :deep(.v-snackbar__content){
    padding: 10px;
  }
}
</style>