// since `.js, .ts` files are not included by default,
// the following comment tells UnoCSS to force scan this file (to pick the logo icon).
// @unocss-include

export default {
  name: 'XFun Games',
  description: 'XFun Games',
  logo: 'i-fluent-emoji:shopping-bags',
  author: 'XFun',
  url: 'https://www.xfun.app',
  github: '',
  defaultLocale: 'pt', // default
  identity: {
    type: 'Organization',
  } as any,
  twitter: '',
  trailingSlash: false, // default
  titleSeparator: '|', // default
}
