const fireopendialog = useEventBus<any>(FireEvent.OPENDIAG)
const fireclosedialog = useEventBus<any>(FireEvent.COLSEDIAG)
const fireclosedialogComplate = useEventBus<any>(FireEvent.CLOSECOMPLATE)
const fireopenlimiteddialog = useEventBus<any>(FireEvent.OPENLIMITEDDIALOG)
const fireopenPWAdialog = useEventBus<any>(FireEvent.OPENPWADIALOG)
const fireopenFBguidedialog = useEventBus<any>(FireEvent.OPENFBGUIDEDIALOG)
const firelogin = useEventBus<any>(FireEvent.LOGIN)
const fireloginfinish = useEventBus<any>(FireEvent.LOGIN_FINISH)
const firelogoutfinish = useEventBus<any>(FireEvent.LOGOUT_FINISH)
const firereg = useEventBus<any>(FireEvent.REGISTER)
const fireroulette = useEventBus<any>(FireEvent.ROULETTE)
const fireredrain = useEventBus<any>(FireEvent.REDRAIN)
const fireslots = useEventBus<any>(FireEvent.SLOTS)
const fireslotsDaily = useEventBus<any>(FireEvent.SLOTSDaily)
const fireShowGameCard = useEventBus<any>(FireEvent.GETGAMECARD)
const firemsg = useEventBus<any>(FireEvent.MSG)
const firgameMsg = useEventBus<any>(FireEvent.MSGGAME)
const firegohome = useEventBus<any>(FireEvent.HOME)
const firekickout = useEventBus<any>(FireEvent.KICKOUT)
const fireCommDialog = useEventBus<any>(FireEvent.COMMDIALOG)
const fireMaintainDialog = useEventBus<any>(FireEvent.MaintainDialog)
const firepageloading = useEventBus<any>(FireEvent.PAGELOADING)
const fireMenu = useEventBus<any>(FireEvent.NDrawerShow)
const fireDoMenuItem = useEventBus<any>(FireEvent.DOMENU)
const fireLangChange = useEventBus<any>(FireEvent.LANGCHANGE)
const fireappdownload = useEventBus<any>(FireEvent.APPDOWNLOAD)
const firecollect = useEventBus<any>(FireEvent.Collect)
const fireActiveFab = useEventBus<any>(FireEvent.ACTIVEFAB)
const fireToPreMenuItem = useEventBus<any>(FireEvent.TOPREMENUITEM)
const fireWsMessage = useEventBus<any>(FireEvent.WSMESSAGE)
const fireNewVersion = useEventBus<any>(FireEvent.NewVersion)
const fireburyingpoint = useEventBus<any>(FireEvent.BURYINGPOINT)

// 红包雨动画控制事件
const fireRainStart = useEventBus<any>(FireEvent.RainStart)
const fireRainProgressStart = useEventBus<any>(FireEvent.RainProgressStart)
const fireRainProgressEnd = useEventBus<any>(FireEvent.RainProgressEnd)

const inst = {
  fireopendialog,
  fireclosedialog,
  fireclosedialogComplate,
  fireopenlimiteddialog,
  fireopenPWAdialog,
  fireopenFBguidedialog,
  firelogin,
  fireloginfinish,
  firelogoutfinish,
  firereg,
  fireroulette,
  fireredrain,
  fireslots,
  fireslotsDaily,
  fireShowGameCard,
  firemsg,
  firgameMsg,
  firegohome,
  firekickout,
  fireCommDialog,
  fireMaintainDialog,
  firepageloading,
  fireMenu,
  fireDoMenuItem,
  fireLangChange,
  fireappdownload,
  firecollect,
  fireActiveFab,
  fireToPreMenuItem,
  fireWsMessage,
  fireNewVersion,
  fireburyingpoint,

  fireRainStart,
  fireRainProgressStart,
  fireRainProgressEnd
}

export const useBus = () => inst
