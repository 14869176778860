
import { postMessage } from '~/utils'

export default defineNuxtPlugin((nuxtApp) => {
    
    /**
     * Add a global middleware to the route manager
     */
    addRouteMiddleware('global-middleware',(to, from) => {
    //   if (to.path !== '/LaunchPage') {
    //     return navigateTo('/LaunchPage')
    //   }
    let userStore = useUserStore()
        
        if (window.deviceinfo && to.name != 'Webview') {
            postMessage('contentView', { name: to.name });
        }
    },{ global: true })
})
