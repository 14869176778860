import MobileDetect from "mobile-detect"
import sha1 from 'crypto-js/sha1';
import md5 from 'crypto-js/md5';
import { useXCookie } from '~/composables/xfun/useXCookie'
import { useInitPage } from "~/composables/xfun/useInitPage";
import * as fs from 'node:fs';
import * as os from 'node:os';

const _window: any = process.client ? window : {}
const _navigator: any = process.client ? navigator : {}
const _document: any = process.client ? document : {}

export function classNames(...classes: any[string]) {
  return classes.filter(Boolean).join(' ')
}

export const kebabCase = (str: string) => {
  return str
    ?.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
    )
    ?.map((x) => x.toLowerCase())
    ?.join('-')
}

export const omit = (obj: object, keys: string[]) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key)),
  )
}

const hashRE = /#.*$/
const extRE = /(index)?\.(md|html)$/
const endingSlashRE = /\/$/

/**
 * Remove `.md` or `.html` extention from the given path. It also converts
 * `index` to slush.
 */
export function normalize(path: string): string {
  return ensureStartingSlash(
    decodeURI(path)
      .replace(hashRE, '')
      .replace(extRE, '')
      .replace(endingSlashRE, ''),
  )
}

function ensureStartingSlash(path: string): string {
  return path.startsWith('/') ? path : `/${path}`
}

export function joinUrl(base: string, path: string): string {
  if (path.startsWith('#')) return path
  return `${base}${path.startsWith('/') ? path.slice(1) : path}`
}

export function parseJumpParams(param: string) {
  let extparam: any = []
  let extobj: any = {}
  let redirectInfo: any = {}

  if (param != null && param.length > 0) {
    extparam = param.split('&')
  }

  extparam.forEach((item: string) => {
    let key = item.split('=')[0]
    let value = item.split('=')[1]
    extobj[key] = value
  })

  if (extobj['type'] == 0 && extobj['id'] == 5) {
    redirectInfo = {
      type: extobj['type'],
      id: extobj['id'],
      param: extobj['param'],
    }
  } else if (extobj['type'] == 5) {
    redirectInfo = {
      type: extobj['type'],
      gameId: extobj["gameId"],
      merId: extobj["merId"]
    }

    if (extobj["merId"] == 16) {
      redirectInfo.bet = extobj["bet"]
    }
  }
  else {
    redirectInfo = {
      type: extobj['type'],
      id: extobj['id'],
      param: extobj['param']
    }
  }

  return redirectInfo
}

export function transMenuData(server_data: any) {
  server_data = server_data.menuPageList
  if (server_data[0].menus && server_data[0].menus.length > 0) {
    let m1 = createMenu(server_data[0].menus)
    server_data[0].menus = m1
  }

  if (server_data[1] && server_data[1].menus && server_data[1].menus.length > 0) {
    let m2 = createMenu(server_data[1].menus)
    server_data[1].menus = m2
  }

  return server_data
}

export function parseparamstring(param: string) {
  let extparam: any = []
  const extobj: any = {}

  if (param != null && param.length > 0) {
    extparam = param.split('&')
  }

  extparam.forEach((item: string) => {
    const key = item.split('=')[0]
    const value = item.split('=')[1]
    extobj[key] = value
  })

  return extobj
}

export function redirectDictIndex(redirectContent: any) {
  let dict: any = {}
  const userStore = useUserStore()
  const redirectDict = userStore.getRedirectDict()
  console.log('redirectDictIndex', redirectDict)
  dict = redirectDict.filter((item: any) => {
    return item.value == redirectContent
  })
  if (dict[0]) {
    return dict[0].display
  } else {
    return redirectContent
  }
}


export function createMenu(menus: Array<any>) {
  const newmenus = menus.map(item => {
    console.log('createMenu----------------------------', item)
    const menuitem: MenuItem = {
      id: item.id,
      type: item.menuType,
      isopen: item.menuStatus == 1 ? true : false,
      title: {
        value: item.menuName,
        style: item.fontstyle || {
          'color': '#D0D1D3',
          'font-size': '15px',
          'font-weight': 'bold',
          'margin-left': '15px'
        }
      },
      icon: {
        type: IconType.Image,
        value: item.menuIcon,
        style: {
          width: "24",
          height: "24"
        }
      },
      params: item.extraParams != null ? parseparamstring(item.extraParams) : null,
      childs: [],
      redirectId: item.redirectId,
      redirectContent: item.redirectContent,
      extraParams: item.extraParams,
      itemstyle: item.menuType == 2 ? ItemStyle.Banner : ItemStyle.Normal
    }

    if (item.menus && item.menus.length > 0) {
      const childs = createMenu(item.menus)
      if (childs.length > 0) {
        menuitem.childs = childs
      }
    }

    return menuitem
  })

  return newmenus
}



export function createredirectinfo(redirectId: number, redirectContent: string, extraParams: string): RedirectInfo {
  let redirectinfo: RedirectInfo = { type: RedirectType.InnerPage }
  redirectinfo.type = redirectId
  //内部页面跳转 1 // 活动跳转 5  功能跳转 7
  if (redirectId == 1 || redirectId == 5 || redirectId == 7) {
    redirectinfo.id = redirectDictIndex(redirectContent)
    let needParse = [5, 6, 20, 24, 25, 26, 27] // 需要解析的id
    if (needParse.includes(+redirectinfo.id) && redirectId == 1) {
      redirectinfo.param = JSON.parse(extraParams);
    }
  } else if (redirectId == 6) { // 游戏跳转 6
    if (extraParams.length > 0 && extraParams[extraParams.length - 1] != '}') {
      extraParams += "\"}"
    }
    const gameinfo = extraParams && JSON.parse(extraParams);
    (redirectinfo as GameRedirectInfo).gameId = gameinfo.gid;
    (redirectinfo as GameRedirectInfo).merId = gameinfo.mer
    if (gameinfo.bet) (redirectinfo as GameRedirectInfo).bet = gameinfo.bet
  } else if (redirectId == 2) { // 内链跳转 (在app内部新开浏览器页面)2
    (redirectinfo as InnerOpenNewWebViewRedirectInfo).url = extraParams || redirectContent
  } else if (redirectId == 4) { // 富文本跳转 4
    (redirectinfo as RichTextOpenNewWebViewRedirectInfo).text = extraParams || redirectContent
  } else if (redirectId == 3) { // 外链跳转 (在app外部新开浏览器页面)3
    (redirectinfo as OutterOpenNewWebViewRedirectInfo).url = extraParams || redirectContent
  }

  return redirectinfo
  // if (redirectType == 3) {
  //   // 内部页面跳转

  //   if (redirectId == '5') {
  //     // 游戏分类页面跳转
  //     redirectinfo.type = RedirectType.InnerPage;
  //     redirectinfo.id = PageTypeId.gameclassify;
  //     (redirectinfo as any).param = JSON.parse(extraParams);
  //   }
  //   if (redirectId == '20') {
  //     // 游戏分类页面跳转
  //     redirectinfo.type = RedirectType.InnerPage;
  //     redirectinfo.id = PageTypeId.customclassify;
  //     (redirectinfo as any).param = JSON.parse(extraParams);
  //   }
  //   if (redirectId == '8') {
  //     // 充值
  //     redirectinfo.type = RedirectType.InnerPage;
  //     redirectinfo.id = PageTypeId.recharge;
  //   }
  //   else if (redirectId == '10') {
  //     // 转账
  //     redirectinfo.type = RedirectType.InnerPage;
  //     redirectinfo.id = PageTypeId.transferaccounts;
  //   }
  //   else if (redirectId == '12') {
  //     // 内部webview
  //     redirectinfo.type = RedirectType.InnerOpenNewWebView;
  //     (redirectinfo as InnerOpenNewWebViewRedirectInfo).url = extraParams
  //   }
  //   else if (redirectId == '13') {
  //     // 下载页
  //     redirectinfo.type = RedirectType.InnerPage;
  //     redirectinfo.id = PageTypeId.install;
  //   }
  //   else if (redirectId == '14') {
  //     // 客服跳转
  //     redirectinfo.type = RedirectType.Function;
  //     redirectinfo.id = FunctionTypeId.LiveChat;
  //   }
  //   else if (redirectId == '15') {
  //     // 应用安装功能
  //     redirectinfo.type = RedirectType.Function;
  //     redirectinfo.id = FunctionTypeId.Install
  //   }
  //   else if (redirectId == '16') {
  //     // VIP
  //     redirectinfo.type = RedirectType.InnerPage;
  //     redirectinfo.id = PageTypeId.vip;
  //   }
  //   else if (redirectId == '17') {
  //     // 首充
  //     redirectinfo.type = RedirectType.InnerPage
  //     redirectinfo.id = PageTypeId.vip;
  //   }
  //   else if (redirectId == '18') {
  //     // 活动跳转
  //     redirectinfo.type = RedirectType.Activity;
  //     (redirectinfo as ActivityRedirectInfo).id = redirectId
  //   }
  //   else if (redirectId == '23') {
  //     // 文本跳转
  //     redirectinfo.type = RedirectType.RichTextOpenNewWebView;
  //     (redirectinfo as RichTextOpenNewWebViewRedirectInfo).text = extraParams
  //   }
  //   else if (redirectId == '22') {
  //     // 在app外部新开浏览器页面
  //     redirectinfo.type = RedirectType.OutterOpenNewWebView;
  //     (redirectinfo as OutterOpenNewWebViewRedirectInfo).url = extraParams
  //   }
  //   else if (redirectId == '24') {
  //     // 在app外部新开浏览器页面
  //     redirectinfo.type = RedirectType.InnerPage;
  //     redirectinfo.id = redirectId;
  //     (redirectinfo as any).param = parseparamstring(extraParams);
  //   }
  //   else if (redirectId == '2') {
  //     // 在app外部新开浏览器页面
  //     redirectinfo.type = RedirectType.InnerPage;
  //     redirectinfo.id = redirectId;
  //     (redirectinfo as any).param = parseparamstring(extraParams);
  //   }
  //   else if (redirectId >= 25 && redirectId <= 27) {
  //     // 在app外部新开浏览器页面
  //     redirectinfo.type = RedirectType.InnerPage;
  //     redirectinfo.id = redirectId;
  //     (redirectinfo as any).param = parseparamstring(extraParams);
  //   }
  //   else {
  //     // 内部页面跳转
  //     redirectinfo.type = RedirectType.InnerPage;
  //     (redirectinfo as InnerPageRedirectInfo).id = redirectId
  //   }
  // } else if (redirectType == 2) {
  //   // 游戏跳转
  //   redirectinfo.type = RedirectType.Game;
  //   if (extraParams.length > 0 && extraParams[extraParams.length - 1] != '}') {
  //     extraParams += "\"}"
  //   }
  //   const gameinfo = extraParams && JSON.parse(extraParams);
  //   (redirectinfo as GameRedirectInfo).gameId = gameinfo.gid;
  //   (redirectinfo as GameRedirectInfo).merId = gameinfo.mer
  //   if (gameinfo.bet) (redirectinfo as GameRedirectInfo).bet = gameinfo.bet
  // } else if (redirectType == 0) {
  //   // 功能跳转
  //   redirectinfo.type = RedirectType.Function;
  //   redirectinfo.id = redirectId
  // } else if (redirectType == 1) {
  //   // 活动跳转
  //   redirectinfo.type = RedirectType.Activity;
  //   redirectinfo.id = redirectId
  // }
}




const keyprefix = 'xfun_'
export const writeKV = (key: string, v: any) => {
  console.log("Write Local Storage", key, v)
  key = keyprefix + key
  localStorage.setItem(key, JSON.stringify(v));
}

export const readKV = (key: string): any => {
  key = keyprefix + key
  const v = localStorage.getItem(key);
  let res = null
  if (v != null && v != 'undefined') {
    try {
      res = JSON.parse(v)
    } catch (e) {
      res = v
    }
  }
  return res
}

export const removeKV = (key: string) => {
  key = keyprefix + key
  localStorage.removeItem(key)
}


export const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}


export const encryption = (pwd: string) => {
  const code = md5(pwd) + "sOmWrZOVeFhovz9U5l7K"
  return sha1(code).toString()
}


export const getBaseUrl = () => process.env.NUXT_BASE_API


export const cookieParser = (cookieString: string) => {
  console.log('cookieParser', cookieString)
  // Return an empty object if cookieString
  // is empty
  if (cookieString == undefined || cookieString === "")
    return {};

  // Get each individual key-value pairs
  // from the cookie string
  // This returns a new array
  let pairs = cookieString.split(";");

  // Separate keys from values in each pair string
  // Returns a new array which looks like
  // [[key1,value1], [key2,value2], ...]
  let splittedPairs = pairs.map(cookie => cookie.split("="));


  // Create an object with all key-value pairs
  const cookieObj = splittedPairs.reduce(function (obj: any, cookie) {

    // cookie[0] is the key of cookie
    // cookie[1] is the value of the cookie 
    // decodeURIComponent() decodes the cookie 
    // string, to handle cookies with special
    // characters, e.g. '$'.
    // string.trim() trims the blank spaces 
    // auround the key and value.
    obj[decodeURIComponent(cookie[0].trim())]
      = decodeURIComponent(cookie[1].trim());

    return obj;
  }, {})

  return cookieObj;
}







export const getEventValue = (eventName: string) => {
  switch (eventName) {
    case "top_recharge": return 1
    case "bottom_recharge": return 2;
    case "personal_recharge": return 3;
    case "cash_rain_recharge": return 4;
    case "slide_recharge": return 5;
    case "game_recharge": return 6;
    case "recharge": return 7;
    case "recharge_success": return 8;
    case "recharge_fail": return 9;
    case "recharge_cancel": return 10;
    case "index_register": return 11;
    case "email_register": return 12;
    case "phone_register": return 13;
    case "phone_promote": return 14;
    case "banner": return 15;
    case "active_icon": return 16;
    case "active": return 17;
    case "bottom_active": return 18;
    case "redeem_code": return 19;
    case "active_service": return 20;
    case "active_join": return 21;
    case "novice_task": return 22;
    case "cash_rain": return 23;
    case "index_game": return 24;
    case "game_real": return 25;
    case "game_collect": return 26;
    case "game_try": return 27;
    case "personal_withdraw": return 28;
    case "slide_withdraw": return 29;
    case "withdraw_success": return 30;
    case "withdraw_fail": return 31;
    case "withdraw_auth": return 32;
    case "index_service": return 33;
    case "personal_service": return 34;
    case "slide_service": return 35;
    case "bottom_invite": return 36;
    case "invite_link": return 37;
    case "invite_code": return 38;
    case "social_share": return 39;
    case "box_receive": return 40;
    case "invite_tg_service": return 41;
    case "invite_faq": return 42;
    case "invite_tutorial": return 43;
    case "bottom_personal": return 44;
    case "bottom_personal_recharge": return 45;
    case "bottom_personal_withdraw": return 46;
    case "bottom_personal_exit": return 47;
    case "bottom_personal_email": return 48;
    case "bottom_personal_system_email": return 49;
    case "bottom_personal_personal_email": return 50;
    default: console.log("eventName not found", eventName)
  }
}


// @ts-ignore
// var meta = _document.querySelector('meta[name="application-name"]')
// var version = ''
// if (meta) {
//   var gitversion = meta.getAttribute('content')
//   if (gitversion) {
//     version = gitversion.split(":")[1]
//     version.trim()
//   }
// }

export const requestCommonAfterlogin = async () => {
  // 重新拉取通用数据
  const initPage = useInitPage()
  await initPage.initAfterLogin()
  resetPixel()
}

export const getuuid = () => {
  if (getJsBridge() && getJsBridge().getUUid) {
    return getJsBridge().getUUid()
  }
  return window.visitorId
}

export const requestAfterlogin = async (opts?) => {

  const userStore = useUserStore()
  const activity = useActiveStore()

  try {
    // 拉取用户关联数据
    let arr = [
      userStore.user_basedata_actions(opts),
      userStore.vip_level_actions(opts),
      userStore.user_logincount_actions(opts),
      userStore.pay_config__home_common_actions(opts),
      userStore.user_notice_advertisementStatus_actions(opts),
      activity.activity_center_homeInfo_actions(opts),
      activity.activity_promote_promoteurl_action(opts),
    ]
    await Promise.all(arr)
  }
  catch (err) {
    console.log('[requestAfterlogin]', err)
  }

  if (userStore.getAdvertisementStatus()) {
    await userStore.user_notice_advertisement_actions(opts)
  }

  if (process.client) {
    const app = useNuxtApp()
    if (app.$ws) {
      app.$ws.bindUser(userStore.userToken, userStore.userInfo.id)
    }
  }
  // const usercommonStore = usecommonStore()
  // const actcommstore = useActcommonStore()
  // const userinfostore = useUserInfoStore()
  // const paystore = usePayConifgStore()
  // const walletstore = useWalletStore()
  // const activityfirststore = useActivityFirstStore()
  // const taskStore = useActivityTaskStore()
  // const promoteStore = useActivityPromote()
  // const turnInfo = useActivityTurntableStore()
  // const useLottery = useActivityLottery()
  // const noticeStore = useNotice()
  // const menuStore = useMenuStore()
  // const redPacketstore = useActivityRedPacketStore()
  // const championShipStore = useActivityChampionshipStore()
  // const weekCardStore = useActivityWeekCardStore()
  // const limitTimeStore = useActivityLimitTimeStore()
  // const accstore = useAccountInfoStore()

  // await useUserInfoStore().request_userinfo_bycomm()
  // await paystore.request_payconfigcomm()
  // await actcommstore.request_activityCenterHome_token_Info()

  // await menuStore.request_popup_status()
  // if (menuStore.get_popup_status()) {
  //   await menuStore.request_popup_date()
  // }
  // const requestArr = [
  //   taskStore.request_activity__task_has_award_bycomm(),
  //   turnInfo.request_activity_turntable_point_bycomm(),
  //   useLottery.request_activity_lottery_status_bycomm(),
  //   weekCardStore.request_activity_weekcard_CanGetRewardReward_bycomm(),
  //   limitTimeStore.request_activity_limittimeOpportunity_bycomm(),
  //   paystore.request_payConfig_bycomm(),
  //   walletstore.request_walletinfo_bycomm(),
  //   walletstore.request_walletinfo_refresh_bycomm(),
  //   noticeStore.request_get_notice_bycomm(),
  //   useLottery.request_activity_lottery_flag_bycomm(),
  //   redPacketstore.request_activity_red_packet_highlight_bycomm(),
  //   promoteStore.request_activity_promote_link()
  // ]
  // await Promise.allSettled(requestArr)
  // const res: any = await activityfirststore.request_activity_firstconfig_bycomm()
  // const status = activityfirststore.get_activity_first_config()
  // const firstReId = userinfostore.getuserinfo().firstReId
  // if (accstore.token) {
  //   wssenduserinfo(accstore.token, userinfostore.getuserinfo().id)
  // }
  // // 判断是否显示首充的奖励标识
  // activityfirststore.get_activity_first_config().hasReward = (firstReId === null && status.status === 1)
}

/**
 * 初始化第三方统计
 */
// gtagid
function initgtagId() {
  const userStore = useUserStore()
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', userStore.channelInfo.pixelIdGoogle);
}

// tiktok_pixel
function inittiktok_pixel() {
  const userStore = useUserStore()
  ttq.load(userStore.channelInfo.pixelIdTiktok);
  ttq.page();
}

// kwai_pixel
function initkwai_pixel() {
  const userStore = useUserStore()
  kwaiq.load(userStore.channelInfo.pixelIdKwai);
  kwaiq.page();
}

// fb_pixel
function initpixel() {
  const userStore = useUserStore()
  window.fbq('init', userStore.channelInfo.pixelIdFb);
  window.fbq('track', 'PageView');
}

// 初始化埋点第三方库
export function resetPixel() {
  const userStore = useUserStore()
  if (userStore.channelInfo.pixelIdGoogle) {
    //initgtagId()
  }
  if (userStore.channelInfo.pixelIdTiktok) {
    inittiktok_pixel()
  }
  if (userStore.channelInfo.pixelIdKwai) {
    initkwai_pixel()
  }
  if (userStore.channelInfo.pixelIdFb) {
    initpixel()
  }

  console.log("init pixel", userStore.channelInfo)
}

/** 初始化桥接层 **/
export function initBridge() {
  if (_window.jsBridge == null) {
    console.log('burying point===>', 'If there is no active injection of a bridging object into the shell, we will create one ourselves')
    _window.jsBridge = {
      postMessage: function (eventName: string, params: any) {
        console.log('burying point===>', eventName, params)
      },
      mock: true
    }
    //_window.$appbus.fireburyingpoint.emit("runinwebview")
  }
  else {
    console.log('burying point===>', 'Discovering the jsBridge object injected into the shell')
    //_window.$appbus.fireburyingpoint.emit("runinapp")

    setTimeout(() => {
      getJsBridge().google_login_init('936568899487-d7m3v35m49g2v3pagabvp4v2a8pcc727.apps.googleusercontent.com')
    }, 2000)
  }
}

export function inShell() {
  if (getJsBridge() != null && getJsBridge().mock == null) {
    return true
  }
  return false
}
_window.inShell = inShell

export function openWebview(url: string, winparam: string = '') {
  if (inShell()) {
    if (_window.jsBridge.openUrlByDefaultBrowser) {
      // andoid router
      _window.jsBridge.openUrlByDefaultBrowser(url)
    }
    else {
      postMessage('openUrlByDefaultBrowser', url)
    }

    return
  }
  _window.open(url, winparam)
}
_window.openWebview = openWebview

export function getJsBridge() {
  // android
  if (_window.jsBridge && _window.jsBridge.mock == null) {
    return _window.jsBridge
  }
  // ios
  if (_window.webkit && _window.webkit.messageHandlers && _window.webkit.messageHandlers.jsBridge) {
    return _window.webkit.messageHandlers.jsBridge
  }
  return null
}
_window.getJsBridge = getJsBridge

// 给原生壳子发送消息
export function postMessage(eventName: string, params?: any) {
  console.log('burying point===>', 'postMessage', eventName, params)
  if (params == null) params = {}

  let _window: any = window

  const userStore = useUserStore()
  let cookie = useXCookie()

  if (cookie.getClientCookie('channel') && typeof params == 'object') {
    params = Object.assign(params, {
      channel: cookie.getClientCookie('channel')
    })
  }
  // if (_window.user_email && typeof params == 'object') {
  //   params = Object.assign(params, {
  //     user_email: _window.user_email
  //   })
  // }

  var jsBridge = getJsBridge()

  if (jsBridge) {

    if (eventName == 'firstrecharge' || eventName == 'recharge') {
      params.currency = "BRL"
    }

    if (_window.deviceinfo.model == 'iPhone') {
      if (typeof params == 'string') {
        jsBridge.postMessage([eventName, params])
      }
      else {
        jsBridge.postMessage([eventName, JSON.stringify(params)])
      }
    }
    else {
      let secodeeventName = eventName
      if (eventName == 'recharge') {
        secodeeventName = 'Purchase'
      }

      jsBridge.postMessage(secodeeventName, JSON.stringify(params))
    }
  }

  if (eventName == 'openUrlByDefaultBrowser') {
    return
  }

  // FB像素对接
  if (_window.fbq) {
    let e;
    if (eventName == 'register') {
      e = 'CompleteRegistration'
      _window.fbq('track', e, params)
    }
    else if (eventName == 'recharge') {
      e = 'Purchase'

      params.value = params.money
      params.currency = 'BRL'
      delete params.money
      console.log('burying point===>', e, params)
      _window.fbq('track', e, params)
    }
    else {
      _window.fbq('trackCustom', eventName, params)
    }
  }

  // Google GTM对接
  if (_window.dataLayer) {
    _window.dataLayer.push({
      event: eventName,
      param: params
    })
  }

  // 51LA
  if (_window.LA) {
    if (eventName == 'deskdl') {
      _window.LA.track("event_deskdl");
    }
  }

  // tiktok 像素对接
  if (_window.ttq) {
    let e = ''

    if (eventName == 'registerClick') {
      e = 'ViewContent'
      params.product = 'InitiateCheckout'
    }
    else if (eventName == 'register') {
      e = 'CompleteRegistration'
    }
    else if (eventName == 'loginClick') {
      e = 'ViewContent'
      params.product = 'loginClick'
    }
    else if (eventName == 'login') {
      e = 'ViewContent'
      params.product = 'login'
    }
    else if (eventName == 'rechargeClick') {
      e = 'ViewContent'
      params.product = 'rechargeClick'
    }
    else if (eventName == 'firstrecharge') {
      e = 'CompletePayment'
    }
    // else if (eventName == 'recharge') {
    // 	e = 'CompletePayment'
    // }
    else if (eventName == 'withdrawClick') {
      e = 'ViewContent'
      params.product = 'withdrawClick'
    }
    else if (eventName == 'withdrawOrderSuccess') {
      e = 'ViewContent'
      params.product = 'withdrawOrderSuccess'
    }
    else if (eventName == 'enterGame') {
      e = 'ViewContent'
      params.product = 'enterGame'
    }
    else if (eventName == 'logout') {
      e = 'ViewContent'
      params.product = 'logout'
    }

    if (e != '') {
      _window.ttq.track(e, params)
    }
  }

  //  快手像素对接
  if (_window.kwaiq) {

    let e = ''

    if (eventName == 'registerClick') {
      e = 'buttonClick'
      params.product = 'registerClick'
    }
    else if (eventName == 'register') {
      e = 'completeRegistration'
    }
    else if (eventName == 'loginClick') {
      e = 'buttonClick'
      params.product = 'loginClick'
    }
    else if (eventName == 'login') {
      e = 'buttonClick'
      params.product = 'login'
    }
    else if (eventName == 'rechargeClick') {
      e = 'addToCart'
    }
    else if (eventName == 'firstrecharge') {
      e = 'firstDeposit'
    }
    else if (eventName == 'recharge') {
      e = 'purchase'
    }
    else if (eventName == 'withdrawClick') {
      e = 'buttonClick'
      params.product = 'withdrawClick'
    }
    else if (eventName == 'withdrawOrderSuccess') {
      e = 'buttonClick'
      params.product = 'withdrawOrderSuccess'
    }
    else if (eventName == 'enterGame') {
      e = 'buttonClick'
      params.product = 'enterGame'
    }
    else if (eventName == 'logout') {
      e = 'buttonClick'
      params.product = 'logout'
    }
    else if (eventName == 'contentView') {
      e = 'contentView'
    }

    if (e != '') {
      console.info('kwaiq point===>', e, params)
      _window.kwaiq.track(e)
    }
  }
}

// 返回当前环境信息
export function getEnv() {
  return import.meta.env.MODE
}

export function setGameFrameStyle(show: boolean) {
  let _window: any = window
  let gameiframe = _document.getElementById('game-iframe')

  if (gameiframe) {
    if (show) {
      gameiframe.style.display = 'block';
    }
    else {
      gameiframe.style.display = 'none';
    }
  }
}

export function jsontype(str: any) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        //不是就返回false
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
}

export function getUserOsInfo() {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Windows NT 10.0") !== -1) return "Windows 10";
  if (userAgent.indexOf("Windows NT 6.2") !== -1) return "Windows 8";
  if (userAgent.indexOf("Windows NT 6.1") !== -1) return "Windows 7";
  if (userAgent.indexOf("Windows NT 6.0") !== -1) return "Windows Vista";
  if (userAgent.indexOf("Windows NT 5.1") !== -1) return "Windows XP";
  if (userAgent.indexOf("Windows NT 5.0") !== -1) return "Windows 2000";
  if (userAgent.indexOf("Mac") !== -1) return "Mac/iOS";
  if (userAgent.indexOf("X11") !== -1) return "UNIX";
  if (userAgent.indexOf("Linux") !== -1) return "Linux";
  return "Other";
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function getMobSysInfo() {
  (Array.prototype as any).contains = function (needle: any) {
    for (i in this) {
      if (typeof this[i] == "string" && this[i].indexOf(needle) > 0) return i
    }
    return -1
  }

  let device_type = navigator.userAgent //获取userAgent信息
  let md = new MobileDetect(device_type) //初始化mobile-detect
  let os = md.os() //获取系统
  let model: any = ''
  if (os == 'iOS') {
    //ios系统的处理
    os = md.os() + md.version('iPhone')
    model = md.mobile()
  } else if (os == 'AndroidOS') {
    //Android系统的处理
    os = md.os() + md.version('Android')
    let sss: any = device_type.split(';')
    var i = sss.contains('Build/')
    if (i > -1) {
      model = sss[i].substring(0, sss[i].indexOf('Build/'))
    }
  }
  else {
    os = getUserOsInfo()
  }
  return {
    os,
    model
  }
}

export function isAndroid(): any {
  return getMobSysInfo().os.includes("Android")
}

export function isRuninFacebook() {
  if (navigator.userAgent.includes("FBAN") ||
    navigator.userAgent.includes("FBAV") ||
    navigator.userAgent.includes("FBIOS")
  ) {
    return true
  }
  return false

}

export function initlivechat() {
  const userStore = useUserStore()
  var _window: any = window

  if (_window.LiveChatWidget) {
    _window.LiveChatWidget.init()
    _window.LiveChatWidget.once('ready', (s: any, c: any) => {
      console.info("LiveChat ready")
      if (userStore.isLogin) {
        console.info("LiveChat Successfully set user information phoneNo")
        _window.LiveChatWidget.call("set_customer_name", userStore.userInfo.id)

        if (userStore.userInfo.email) {
          _window.LiveChatWidget.call("set_customer_email", userStore.userInfo.email)
        }
      }
      _window.liveChatState = s
    })
  }
}

export function getLiveChatWidget() {
  let _window: any = window
  return _window.LiveChatWidget
}

export function livechatcall(method: string) {
  if (getLiveChatWidget() != null) {
    getLiveChatWidget().call(method)
  }
}

export function parseposinfo(param: string) {
  let extparam: any = []
  let extobj: any = {}

  if (param != null && param.length > 0) {
    extparam = param.split('&')
  }

  extparam.forEach((item: string) => {
    let key = item.split('=')[0]
    let value = item.split('=')[1]

    if (key == "offsetX" || key == "offsetY" || key == "scale") {
      extobj[key] = value
    }
  })

  return extobj
}

export function vibrate() {
  _navigator.vibrate = _navigator.vibrate ||
    _navigator.webkitVibrate ||
    _navigator.mozVibrate ||
    _navigator.msVibrate;

  if (_navigator.vibrate) {
    // 支持
    console.log("support vibrate！");
    _navigator.vibrate(50);
  }


}
_window.vibrate = vibrate

export function isEqualDay(d1: number, d2: number) {
  let date1 = new Date(d1)
  let date2 = new Date(d2)

  if (date1.getUTCFullYear() == date2.getUTCFullYear() &&
    date1.getUTCMonth() == date2.getUTCMonth() &&
    date1.getUTCDate() == date2.getUTCDate()) {

    return true
  }

  return false
}

export function copyText(text: string) {
  try {
    const textarea = _document.createElement('textarea');
    // 设置textarea的value为要复制的文本
    textarea.value = text;
    // 将textarea添加到DOM中
    _document.body.appendChild(textarea);
    // 选中textarea中的文本
    textarea.select();
    // 执行复制命令
    _document.execCommand('copy');
    // 移除textarea元素
    _document.body.removeChild(textarea);

  } catch (error) {

    return console.error(error);
  }
}
_window.copyTextShare = function (text: string) {
  try {
    const textarea = _document.createElement('textarea');
    // 设置textarea的value为要复制的文本
    textarea.value = text;
    // 将textarea添加到DOM中
    _document.body.appendChild(textarea);
    // 选中textarea中的文本
    textarea.select();
    // 执行复制命令
    _document.execCommand('copy');
    // 移除textarea元素
    _document.body.removeChild(textarea);
  } catch (error) {
    console.error(error);
  }
}

export function formatTime(value: number) {
  if (value < 10) {
    return '0' + value
  }

  return value
}

export function isfacebookWebview() {
  return navigator.userAgent.includes("FB")
}

export function isfromTabitem(name: any) {
  if (name == "start" || name == 'depositPIX' || name == 'promotion' || name == 'activityCenter' || name == 'userInfo') {
    return true
  }

  return false
}

export function clone(obj: any) {
  return JSON.parse(JSON.stringify(obj))
}


export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  var results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function isPwa() {
  return (window.self != window.top) || (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')
}

export function getDevicesId(params: string) {
  if (params == 'android_apk') {
    return 1
  }
  else if (params == 'android_pwa') {
    return 2
  }
  else if (params == 'iOS_desktop') {
    return 3
  }
  else if (params == 'android_web') {
    return 4
  }
  else if (params == 'iOS_web') {
    return 5
  }

  return -1
}

// device 设备 1-android_apk 2-android_pwa 3-iOS_pwa 4-android_web 5-IOS_web
export function getdevicesInfo() {

  if (inShell()) {
    if (_window.jsBridge) {
      return 1
    }
  }

  if (isPwa()) {
    if (isAndroid()) {
      return 2
    }
    else {
      return 3
    }
  }
  else {
    if (isAndroid()) {
      return 4
    }
    else {
      return 5
    }
  }
}

let apikey = "VzxHLMtvRbCwL3VJi96v6aqWlR3/itsGMAv5NIWZqfc6L3OKuq21ZrQ4/lBPZIlfEUjZA6kM20lOY7Oa1O9FAw358JEnxXeKlBbdQG0HpxujmHpweaox/kPJDa0IlgEAH58EvZplBT9mOwdSVPrmMgPPaJwkZFUwna7JE0jrkzEMR+2KLT3YJP+9uu2bSPn6q7rLRhTE4t+ZA0ouOgnD7DwqSpd5T/dBuhI04fGwLBDNe6Vk9aTdR5gE+onMJublsD1nPWSaWLbJHgCY6iAlv+0wKCk0W0+xQG+WtzxTr2Etf8STXRj8a1+ZHSDjCGAALRtVKA/1MpNtzZw3KFB9r7UXPu1MmsgejTiyypDSoaEOZpDkO5e6+Cf19MprYj5MM96x32XrT1L8p1zSOt5x6NrYYQYveLDuyK/fnY/dCMohIL/BEDuOFvEwYEZHuHvWasPMZhGnKey4OZSmvzCaidQx/+P+AOw+npsu5rz7XqWMlLYkTrduNYYJL2M72oDI3efeL3k8DlnTyPiU2dBtWGNjfDzehlkTfzXwSi6fLqzqO8mL9NwQU/E4AWt/tJQbog6HS0z26e/HynN/x6DgevmUXd6ertSrM4nkl+ZTx/A5bQ2gWsCgmO+AaeRw04Dunt75gOqV/+iPm7g1yZ5bTsPHRqZ9u4b6fuMF3q0/ldU=_1_1"

export const setCaptchaKey = (key: string) => {
  if (key != null) {
    apikey = key
  }
}

export const removeCaptcha = (key: string) => {
  var container = document.querySelector(key);
  var childs = container.childNodes;
  for (var i = 0; i < childs.length; i++) {
    container.removeChild(childs[i]);
  }
}

export const showMyCaptcha = (key: string) => {
  var container = document.querySelector(key);

  _window.AwsWafCaptcha.renderCaptcha(container, {
    apiKey: apikey,
    defaultLocale: "pt-BR",
    onSuccess: captchaExampleSuccessFunction,
    onError: captchaExampleErrorFunction
  });
}

const captchaExampleSuccessFunction = (wafToken) => {
  // Captcha completed. wafToken contains a valid WAF token. Store it for
  // use later or call AwsWafIntegration.fetch() to use it easily.
  // It will expire after a time, so calling AwsWafIntegration.getToken()
  // again is advised if the token is needed later on, outside of using the
  // fetch wrapper.

  // Use WAF token to access protected resources
  // _window.AwsWafIntegration.fetch("...WAF-protected URL...", {
  //     method: "POST",
  //     headers: {
  //         "Content-Type": "application/json",
  //     },
  //     body: "{ ... }" /* body content */
  // });
  if (window.captchaExampleSuccessFunction) window.captchaExampleSuccessFunction(wafToken)
}

const captchaExampleErrorFunction = (error) => {
  /* Do something with the error */
  if (window.captchaExampleErrorFunction) window.captchaExampleErrorFunction(error)
}


export const appendLog = (path: string, msg: string) => {
  try {
    let line = `[${formatDateTime(new Date())}] ${msg}\r\n`;
    fs.appendFileSync(`${os.homedir}/.pm2/logs/${path}`, line, 'utf8')
  } catch (err) {
    console.error(err);
  }
}


export const formatDateTime = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return `${year}-${pad(month)}-${pad(day)} ${pad(hour)}:${pad(minute)}:${pad(
    second
  )}`;
}

export const pad = (num: number) => {
  return num.toString().padStart(2, "0");
}