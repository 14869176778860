import type { ActivityCommonRes, GameModuleCommonRes, HomeHallRes, ViewModuleCommonRes, } from "~/types/xfun"
import { generateRequestFunction } from "./net"

const view_index = '/api/xfun/view'
const game_index = '/api/xfun/game'
const activity_index = '/api/xfun/activity'
const hall_index = '/api/xfun/hall'

/**
 * @description 主要提供全局的基础数据,与用户无关,首页SSR渲染时数据
 */
export const useCommon = () => {
  const fetchViewIndex = async (body?) => generateRequestFunction<ViewModuleCommonRes>(view_index, body)
  const fetchGameIndex = async (body?) => generateRequestFunction<GameModuleCommonRes>(game_index, body)
  const fetchActivityIndex = async (body?) => generateRequestFunction<ActivityCommonRes>(activity_index, body)
  const fetchHallIndex = async (body?) => generateRequestFunction<HomeHallRes>(hall_index, body)

  const fetchCommon = async (body?): Promise<CommonData> => {
    if (!body) body = {}
    let datas = []
    try {
      datas = await Promise.all([
        fetchViewBaseData(body),
        fetchGameBaseData(body),
        fetchActivityBaseData(body),
        //fetchHallBaseData(body),
      ])
    }
    catch (err) {
      console.log(err)
    }
    return {
      view: datas[0],
      game: datas[1],
      activity: datas[2],
      //hall: datas[3]
    }
  }

  const fetchViewBaseData = async (body?) => {
    let res = await fetchViewIndex(body)
    if (res.code !== 1) {
      throw createError({
        message: res.msg,
        statusMessage: `Couldn't fetch ${view_index}`,
      })
    }
    return res.data
  }

  const fetchGameBaseData = async (body?) => {
    const res = await fetchGameIndex(body)
    if (res.code !== 1) {
      throw createError({
        message: res.msg,
        statusMessage: `Couldn't fetch ${game_index}`,
      })
    }
    return res.data
  }

  const fetchActivityBaseData = async (body?) => {
    let res = await fetchActivityIndex(body)
    if (res.code != 1) {
      throw createError({
        message: res.msg,
        statusMessage: `Couldn't fetch ${activity_index}`,
      })
    }
    return res.data
  }

  const fetchHallBaseData = async (body?) => {
    let res = await fetchHallIndex(body)
    if (res.code != 1) {
      throw createError({
        message: res.msg,
        statusMessage: `Couldn't fetch ${hall_index}`,
      })
    }
    return res.data
  }

  return {
    fetchCommon
  }
}
