import { generateRequestFunction } from "./net"
import type { TokenParam } from "~/server/api/xfun/config"
import type { LoginReq, FindPasswordReq, BindEmailReq, BindPhoneReq, RegisterReq, ModPasswordReq, SendSMSVerifyReq as SendSMSCodeReq, SendEmailVerifyReq as SendEmailCodeReq, Auth2LoginReq, LoginRes, RegisterRes, ExtendTokenRes, Auth2LoginRes } from "~/types/xfun"


const user_login = '/user/login/login'
const user_register = '/user/login/register'
const user_logout = '/user/login/logout'
const user_retrievepwd = '/user/login/retrievepwd'
const user_verifyemail = '/user/login/verifyemail'
const user_verifyphone = '/user/login/verifyphone'
const user_extendToken = '/user/info/extendToken'
const user_modpwd = '/user/info/modpwd'
const user_sendsms = '/user/notice/sendsms'
const user_sendemail = '/user/notice/sendemail'
const user_auth2login = 'user/auth2/login/login'

/**
 * @description 和账户相关的接口
 */
export const useAccount = () => {

  const fetchLogin = async (args: LoginReq, opts?) => generateRequestFunction<LoginRes>(user_login, args, opts)
  const fetchRegister = async (args: RegisterReq, opts?) => generateRequestFunction<RegisterRes>(user_register, args, opts)
  const fetchLogout = async (args: TokenParam, opts?) => generateRequestFunction(user_logout, args, opts)
  const fetchRetrievePwd = async (args: FindPasswordReq & TokenParam, opts?) => generateRequestFunction(user_retrievepwd, args, opts)
  const fetchVerifyEmail = async (args: BindEmailReq & TokenParam, opts?) => generateRequestFunction(user_verifyemail, args, opts)
  const fetchVerifyPhone = async (args: BindPhoneReq & TokenParam, opts?) => generateRequestFunction(user_verifyphone, args, opts)
  const fetchExtendToken = async (args: TokenParam, opts?) => generateRequestFunction<ExtendTokenRes>(user_extendToken, args, opts)
  const fetchModPwd = async (args: ModPasswordReq & TokenParam, opts?) => generateRequestFunction(user_modpwd, args, opts)
  const fetchSendSMS = async (args: SendSMSCodeReq, opts?) => generateRequestFunction(user_sendsms, args, opts)
  const fetchSendEmail = async (args: SendEmailCodeReq, opts?) => generateRequestFunction(user_sendemail, args, opts)
  const fetchAuth2Login = async (args: Auth2LoginReq, opts?) => generateRequestFunction<Auth2LoginRes>(user_auth2login, args, opts)

  return {
    fetchLogin,
    fetchRegister,
    fetchLogout,
    fetchRetrievePwd,
    fetchModPwd,
    fetchVerifyEmail,
    fetchVerifyPhone,
    fetchExtendToken,
    fetchSendSMS,
    fetchSendEmail,
    fetchAuth2Login
  }
}