import type { BaseResponseParam } from "~/server/api/xfun/config"


/** 大厅模块类型 */
export enum ModuleType {
  Game = 1,
  Tag,
  Manufacturer,
  History,
  Collect
}

/** 大厅模块交互类型 */
export enum interactiveModel {
  Normal = 1,
  Jump,
}

/** 用户账户相关结构 */
export type LoginReq = {
  // 1:邮箱登录 2:手机登录
  type: number
  // 邮箱
  email?: string
  // 手机号码
  phoneNo?: string
  // 密码
  password: string
  // 国家区号
  countryCode: string
  // 设备id
  device: number
}
export type LoginRes = BaseResponseParam<{ token: string }>

export type AccountInfo = {
  account?: string,
  accounttype?: string
  password?: string
}

// 用户注册
export type RegisterReq = {
  // 注册类型 1-邮箱；2-手机号,可用值:1,2
  type: number
  // 	国家区号
  countryCode?: string
  // 手机号码
  phoneNo?: string
  // 验证码
  // verifyCode: string
  // 密码
  password: string
  // 邮箱
  email?: string
  // 推荐码
  promotionCode?: string
  // 渠道号
  channel?: string
  //全民代理
  agentCode?: string
  // 扩展数据
  extParams?: string
  device?: number
}
export type RegisterRes = BaseResponseParam<{ token: string }>


// Token续约响应
export type ExtendTokenRes = BaseResponseParam<{ token: string }>


// 找回密码
export type FindPasswordReq = {
  // 1-用邮箱找回；2-用电话找回
  type: number
  // 新密码
  newpwd: string
  // 确认密码
  confirmpwd: string
  // 	邮箱
  email?: string
  // 验证码
  verifyCode: string
  // 国家区号
  countryCode?: string
  // 手机号码
  phoneNo?: string
}

// 修改密码
export type ModPasswordReq = {
  oldpwd: string
  newpwd: string
  confirmpwd: string
}

// 绑定邮箱
export type BindEmailReq = {
  // 邮箱
  email: string
  // 验证码
  verifyCode: string
}

// 绑定手机号
export type BindPhoneReq = {
  // 国家区号
  countryCode: string
  // 手机号码
  phoneNo: string
  // 验证码
  verifyCode: string
}

// 发送电话验证码
export type SendSMSVerifyReq = {
  countryCode: string
  phoneNo: string
  otype: number
}

// 发送邮箱验证码
export type SendEmailVerifyReq = {
  email: string
  otype: number
}

/** 用户相关 */
export type UserInfo = {
  id: number
  platId: number
  email: string
  thirdPartyAccount: string,
  registerType: number
  countryNo: string,
  phoneNo: string,
  password: string
  salt: string
  exchangePassword: string
  realname: string
  nickname: string
  sex: string
  avatar: string
  lang: string
  status: number
  exchangeStatus: number
  online: number
  channel: string
  device: string
  lastDevice: string
  regTime: number
  lastLoginTime: number
  continuousLoginDay: number
  regIp: string
  lastLoginIp: string
  channelPackage: string
  lastOnlineRechargeTime: number
  lastOnlineRechargeMoney: number
  createTime: number
  firstReId: string
  firstReAmount: number
  promoteCode: string
  extParams: string
  taskMinutes: number
  vipFreeze: boolean
}
export type UserInfoRes = BaseResponseParam<UserInfo>

// 修改头像
export type ModAvatarReq = {
  // 头像
  avatar: string
}

// 修改昵称
export type ModNicknameReq = {
  // 昵称
  nickname: string
}

//用户头像合集
export type AvatarInfo = {
  id: number
  category: number
  avatar: string
  vipLevel: number
  unlockedStatus: number
  createTime: number
  updateTime: number
}

export type UserAvatarListRes = BaseResponseParam<{ category: string, avatarList: AvatarInfo[] }>


export type UserLoginTime = BaseResponseParam

// VIP等级集合
export type VipLevelInfo = {
  id: number,
  vipId: number,
  name: string,
  reward: number,
  betNeedTimes: number,
  exp: number,
}
export type VipLevelListRes = BaseResponseParam<VipLevelInfo[]>

//VIp提现详情
export type VipWithdrawDetail = {
  todayWithdrawTimes: number,
  withdrawTimesLimit: number,
  todayWithdrawAmount: number,
  withdrawAmountLimit: number
}
export type VipWithdrawDetailRes = BaseResponseParam<VipWithdrawDetail>


//权限列表集合
export type VipAuthInfo = {
  vipId: number
  validWeeklyCard: string,
  withdrawTimesLimit: number,
  withdrawAmountLimit: number,
  turntableLimit: number,
  descPt: string,
  createTime: number,
  updateTime: number
}
export type VipAuthsRes = BaseResponseParam<VipAuthInfo[]>

//vip配置
export type VipConfig = {
  descPt: string
}
export type VipConfigRes = BaseResponseParam<VipConfig>


// 资金日志
export type WalletFundLogsReq = {
  // 页码，从0开始
  page?: number,
  // 每页多少条记录,示例值(10)
  pageSize?: number,
  // 资金类型
  fundType?: number,
  // 开始时间,示例值(2021-07-23 00:00:00)
  startTime: string,
  // 结束时间,示例值(2021-07-23 00:00:00)
  endTime: string,
  //1: 奖金, 2: 赠金,示例值(1)
  rewardType?: number
}

export type WalletFundLog = {
  id: number,
  userid: number,
  type: number,
  orderid: string,
  beforeBalanceGift: number,
  beforeBalance: number,
  amount: number,
  afterBalance: number,
  afterBalanceGift: number,
  tableIdx: number,
  remark: string,
  createTime: number
}
export type WalletFundLogsRes = BaseResponseParam<WalletFundLog[]>

// 资金日志汇总
export type WalletFundLogsTotalInfo = {
  id: number,
  userid: number,
  type: number,
  orderid: string,
  beforeBalanceGift: number,
  beforeBalance: number,
  amount: number,
  afterBalance: number,
  afterBalanceGift: number,
  tableIdx: number,
  remark: string,
  createTime: number
}
export type WalletFundLogsTotalResult = {
  totalAmount: number
  total: number
  list: WalletFundLogsTotalInfo[]
}
export type WalletFundLogsTotalRes = BaseResponseParam<WalletFundLogsTotalResult>






// 查询余额
export type BalanceInfo = {
  // 	余额
  balance: number,
  // 赠金余额
  balanceGift: number,
  // 是否有首充订单
  firstReId: string,
  // 是否有未下分厂家
  curMerIds: number[],
  // 剩余稽核
  remainingBalanceAudit: number,
  // 赠金剩余稽核
  remainingGiftAudit: number,
  totalBalance: number,
}
export type BalanceInfoRes = BaseResponseParam<BalanceInfo>

// 查询钱包
export type WalletInfo = {
  /*用户id */
  id: number;

  /*总提现余额 */
  totalExchange: number;

  /*总提现奖金 */
  totalExchangeBonus: number;

  /*总余额下注流水 */
  totalBet: number;

  /*总奖金下注流水 */
  totalBetBonus: number;

  /*总余额赢钱 */
  totalWin: number;

  /*总奖金赢钱 */
  totalWinBonus: number;

  /*总奖金 */
  totalReward: number;

  /*经验值 */
  exp: number;

  /*vip对应的id */
  vipId: number;

  /*总充值 */
  totalRecharge: number;

  /*积分 */
  points: number;

  /*升级时间 */
  upgradeTime: number;
};
export type WalletInfoRes = BaseResponseParam<WalletInfo>


// 用户基础信息合集
export type UserBaseInfo = {
  systemNotices: Array<NoticeInfo>
  userWallet: WalletInfo
  balanceResp: BalanceInfo
  userInfo: UserInfo
}
export type UserBaseRes = BaseResponseParam<UserBaseInfo>

// 积分记录
export type WalletPointsLogsReq = {
  page?: number,
  pageSize?: number,
  type?: number,
  startTime: string,
  endTime: string
}

export type WalletPointsLogsRes = {
  id: number,
  userid: string,
  // 积分类型
  type: number,
  // 积分数量
  points: number,
  // 创建时间
  createTime: number,
}

// 轮盘获奖记录(最近10条)
export type WalletRouletteLogsRes = {
  id: number,
  // 用户id
  userid: string,
  // 类型
  type: number,
  // 订单号
  orderid: string,
  // 变动前赠金
  beforeBalanceGift: number,
  // 变动前金额
  beforeBalance: number,
  // 变动金额
  amount: number,
  // 变动后金额
  afterBalance: number,
  // 变动后赠金
  afterBalanceGift: number,
  // 索引表
  tableIdx: string,
  // 备注
  remark: string,
  // 创建时间
  createTime: number
}

//推广奖励
export type WalletPromoteRewardRes = {
  yesterdayReward: number,
  totalReward: number
}

// 奖金记录参数
export type WalletQueryRewardReq = {
  // 页码，从0开始
  page?: number,
  // 每页多少条记录,示例值(10)
  pageSize?: number,
  // 资金类型
  activityDicId?: number | null,
  // 开始时间,示例值(2021-07-23 00:00:00)
  startTime: string,
  // 结束时间,示例值(2021-07-23 00:00:00)
  endTime: string,
  //1: 余额, 2: 奖金,示例值(1)
  rewardType?: number | null
}

// 奖金记录
export type WalletQueryRewardRes = {
  activityName: number,
  rewardType: number,
  amount: number,
  createTime: number
}
export type WalletQueryRewardListRes = BaseResponseParam<WalletQueryRewardRes[]>


// 奖金中心列表

export type BonusCenterList= {
    sort: number,
    type: number,
    flag: number,
    icon: string,
    name: string,
    desc: string,
    tips: string,
    btnText: string,
    redirectId: number,
    redirectContent: string,
    innerList:Array<{
      name: string,
      value: string,
  }>
}[]
export type BonusCenterListRes = BaseResponseParam<BonusCenterList>
// 奖金中心详情
export type BonusCenterDetail = {
  totalBonus: number,
  totalDesignatedBonus: number,
  totalVipBonus: number,
  designatedBonus:Array<{
    icon: string,
    name: string,
    amount: number,
  }>,
  vipBonus:Array<{
    icon: string,
    name: string,
    amount: number,
  }>,
}

export type BonusCenterDetailRes = BaseResponseParam<BonusCenterDetail>


/** 基础结构相关 */

// 渠道信息
export type ChannelInfo = {
  id: number
  platId: number
  name: string
  agentId: number
  code: string
  activityConfigId: number
  gameConfigId: number
  rechargeConfigId: number
  apkUrl: string
  iosUrl: string
  pixelIdGoogle: string
  pixelIdTiktok: string
  pixelIdKwai: string
  pixelIdFb: string
  promoteType: number
  apkStyleId: number
  status: number
  createTime: number
}

// 轮播图
export type CarouselInfo = {
  id: number
  title: string
  sort: number
  linkUrl: string
  icon: string
  // 1: 启用 0: 禁用
  status: number
  createTime: number
  /** 前端扩展字段 */
  redirectInfo: {
    type?: RedirectType
    id?: string
    param?: any
    title?: string
    [key: string]: any
  }
}

// 大厅
export type ModuleTextListItem = {
  id: number
  name: string
  redirectType: number
  content: string
  sort: number
  status: number
  createTime: number
}

export type RegisterBannerInfo = {
  status: number
  icon: string
  jumpCondition: string
}

export type ApkDownloadInfo = {
  iosUrl: string
  apkUrl: string
}

export type ViewCommonInfo = {
  menuIndexResp: MenuInfo //侧滑菜单数据
  ads: CarouselInfo[] //轮播图数据
  bottomResp: BottomInfo //首页底部的媒体链接
  registerBannerConfig: RegisterBannerInfo //注册界面上的banner图
  moduleActivityIcons: ActivityIconInfo[] //首页上的活动入口图标
  moduleActivityIconsStatus: number //首页上活动图标的状态
  moduleAnnouncement: AnnouncementInfo[] //弹窗的公告信息
  channelCodeInfoResp: ChannelInfo //渠道信息
  channelCodeResp: ApkDownloadInfo //渠道apk信息
}
export type ViewModuleCommonRes = BaseResponseParam<ViewCommonInfo>

//弹窗的公告信息
export type AnnouncementInfo = {
  status: number
  name: string
  icon: string
  redirectId: string
  content_en: string
  content_pt: string
  content: string
}

// 获取分页菜单
export type Menu = {
  id: number
  menuName: string
  menus: Array<SubMenu>
  menuIcon: string
  menuIconSub: string
  status: number
  sort: number
  createTime: number
}

export type SubMenu = {
  id: number
  menuName: string
  menuIcon: string
  menuType: number
  parentId: number
  // 0:无 1:展开 2:收起
  menuStatus: number
  // 0:功能 1:模块 2:活动 3:游戏
  redirectType: number
  // 跳转id
  redirectId: number
  // 额外参数
  extraParams: string
  menuIconSub: string
  isTop: number
  layout: number
  // 0：关闭 1：启用
  status: number
  sort: number
  createTime: number
}

export type MRedirectInfo = {
  id: number
  redirectType: number
  redirectId: number
  content: string
  status: number
  sort: number
  createTime: number
}

export type MenuInfo = {
  menuPageList: Array<Menu>
  redirect: Array<MRedirectInfo>
}

export type ActivityIconInfo = {
  id: number
  icon: string
  redirectId: number
  redirectContent: string
  status: number
  sort: number
  createTime: number
  updateTime: number
  type: number
  // 前端扩展字段
  jsonIcon: any,
  redirectInfo: {
    type?: RedirectType
    id?: number
    param?: any
    title?: string
  }
}

// 弹窗广告状态（登录后）
export type AdvertisementStatsRes = BaseResponseParam<{
  status: number
}>

// 获取弹窗广告
export type PopupData = {
  id: number
  remark: string
  channel: string
  sort: number
  name: string
  icon: string
  content: string
  redirectId: number
  status: number
  openBegin: number
  openEnd: number
  type: number
}

export type PopupDataRes = BaseResponseParam<PopupData[]>

//获取底部图标
export type BottomInfo = {
  mediumList: Array<{
    sort: number
    link: string
    icon: string
  }>
  manufacturerList: Array<{
    sort: number
    icon: string
  }>
}

// 公告信息
export type NoticeInfo = {
  /* */
  id: number;
  /*标题 */
  title: string;
  /*内容 */
  content: string;
  /*消息类型 */
  msgType: number;
  /*接收类型 */
  type: number;
  /*目标 */
  target: string;
  /*消息发送时间 */
  sendTime: number;
  /*创建时间 */
  createTime: number;
  /*状态:1正常2删除 */
  status: number;
  /*平台ID 0：全平台 */
  platId: number;
}
export type NoticeListRes = BaseResponseParam<NoticeInfo[]>


// 弹窗广告信息(登录前)
export type AdvertisementInfo = Array<{
  id: number
  name: string
  icon: string
  content: string
  jumpCondition: string
  content_en: string
  content_pt: string
}>
export type AdvertisementRes = BaseResponseParam<AdvertisementInfo>


// Auth2登录
export type Auth2LoginReq = {
  email?: string,
  thirdPartyAccount?: string,
  promotionCode?: string,
  channel?: string,
  agentCode?: string,
  code?: string,
  registerType?: RegisterType
  userInfo?: any,
  extParams?: string,
  device?: number
}
export type Auth2LoginRes = BaseResponseParam<{ token: string }>

// 注册类型
export enum RegisterType {
  App = 1,
  Google,
  Facebook,
  Twitter
}

/** 支付充值相关 */

// 提现接口
export type PayExchangeReq = {
  // 金额
  money: number,
  // 充值/提现方式 1：PIX 2: USDT
  pay_type: number,
  // 充值/提现id
  id: number,
  // 账户类型 1-余额；2-奖金
  accountType?: number,
}

// 提现风控单处理
export type PayExchangeRiskReq = {
  // 订单id
  order_id: string,
  // 风控处理状态，2:直接退单
  status: number,
  // 风控标记信息
  remark: string,
}

// 充值/提现 记录
export type PayLogsReq = {
  // 页码，从0开始
  page?: number,
  // 每页多少条记录,示例值(10)
  pageSize?: number,
  // 开始时间,示例值(2021-07-23 00:00:00)
  startTime: string,
  // 结束时间,示例值(2021-07-23 00:00:00)
  endTime: string,
  // 支付记录类型，1：充值， 2：提现
  logsType: number,
}

export type PayLog = {
  // 时间：yyyy-MM-dd HH:mm:ss
  createTime: string,
  // 订单金额
  amount: number,
  // 实际金额
  realAmount: number,
  // 本地订单id
  orderId: string,
  // 订单状态值，值类型代表意思，跟以前一样
  payStatus: number,
}
export type PayLogsRes = BaseResponseParam<PayLog[]>


// 充值-提现配置
export type PayItem = {
  id: number
  // 	商户ID
  merId: number,
  // 支付/提现 1：充值 2: 提现
  inOut: number,
  // 支付/提现方式 0：PIX 1: USDT
  payType: number,
  // 币种 0：BRL 1: USDT
  coinType: number,
  // 排序
  sort: number,
  // 固定金额[多个以,分割]
  fixedMoney: string,
  // 自定义支持 0：不支持 1: 支持
  selfSupport: number
  // 自定义最小值 单位 分
  selfMin: number,
  // 自定义最大值 单位 分
  selfMax: number,
  // 手续费 [0-100]
  rebate: number,
  // 单人每日最大次数
  dayMax: number,
  // 间隔时长 单位 秒
  interval: number,
  // 超时时长 单位 分
  timeout: number,
  // 0：不推荐 1：推荐
  recommend: number,
  // 0：禁用 1：正常
  status: number,
  // 创建时间
  createTime: number
}
export type PayInfo = {
  id: number,
  // 用户id
  userid: number,
  // 类型：bank、usdt
  type: string
  // 币种类型
  usdtType: string,
  // usdt地址
  usdtAddress: string,
  // 银行编号
  bankNo: string,
  // 银行名称
  bankName: string,
  // 银行编码
  bankCode: string,
  // PIX账号
  pixNumber: string,
  // PIX账号类型（CPF、CNPJ、PHONE、EMAIL、EVP）
  pixType: string,
  // 开户人姓名或公司名称
  pixName: string,
  // CPF号（格式化后的11位数字）
  cpfNumber: string,
  // 状态0：禁用1：正常
  status: number,
  // 创建时间
  createTime: number
}

export type PayConfigInfo = {
  in: Array<PayItem>
  out: Array<PayItem>
  payInfo: PayInfo,
  outTipsLevel: number,
}
export type PayConfigRes = BaseResponseParam<PayConfigInfo>

// 充值配置V2
export type pageV2Item = {
  rechargeType: number,
  icon:string,
  iconDesc:string,
  desc:string,
  levels:Array<levelsItem>,
  fixedAmounts:Array<{
    amount:string,
    hotAmount:boolean,
    rewardAmount:string,
    type:number,
  }>,
}

export type levelsItem = {
  maxAmount:number,
  minAmount:number,
  reward:number,
  type:number,
}

export type PayConfigV2Info={
  content:string,
  selfMax:number,
  selfMin:number,
  pageV2RespList:Array<pageV2Item>
}
export type PayConfigV2Res = BaseResponseParam<PayConfigV2Info>

export type CommonPayConfig = {
  paymentConfigResp: PayConfigInfo,
  payBriefRespList: []
}
export type CommonPayConfigRes = BaseResponseParam<CommonPayConfig>

export interface PayConfigChannelReq {
  /*页码，从0开始 */
  page?: number;

  /*每页多少条记录 */
  pageSize?: number;

  /*排序类型：sort,id,count */
  sort_by?: string;

  /*排序方式：asc,desc */
  sort?: string;

  /*渠道号 */
  channel?: string;
}

// 充值接口
export type PayRechargeReq = {
  id: number,
  money: number,
  pay_type: number,
  type?: number,// 1:普通充值 2:周卡1档 3:周卡2档 4:周卡3档
}
export type PayRechargeResult = {
  pay_url: string,
  order_id: string,
  money?: number,
  payCode?: string,
  expireTime?:string
}
export type PayRechargeRes = BaseResponseParam<PayRechargeResult>

// 设置pix pay info数据
export type PaySetPayInfoReq = {
  // pix number,示例值(142.129.272-68)
  pixnumber: string,
  // name
  name: string,
  // cpf number,示例值(142.129.272-68)
  cpfnumber: string,
  // pix type,示例值(['CPF', 'CNPJ', 'PHONE', 'EMAIL', 'EVP'])
  pix_type: string,
}

// 充值-提现简介数据,前端统计上报
export type PayBriefInfo = {
  // 是否首充, 1:是，0：不是
  isFirst: number,
  // 订单id
  order_id: string,
  // 充提类型，1：充值，2:提现
  type: number,
  // 时间戳
  time: number,
}
export type PayBriefListRes = BaseResponseParam<PayBriefInfo[]>

//充值页配置
export type PayPageConfig = {
  rechargeType: number, // 充值类型 1:无活动 2:首充 3:限时充值 4:连续充值
  status: number,
  bottomText: string,
  topIconList: Array<any>,
}
export type PayPageConfigRes = BaseResponseParam<PayPageConfig[]>

//快捷充值配置
export type QuickRechargeConfig = {
  id: number,
  money: number,
  sort: number,
  status: number,
  createTime: number
}
export type QuickRechargeConfigRes = BaseResponseParam<QuickRechargeConfig[]>

/** 游戏相关 */

// 游戏搜索
export type GameSearchReq = {
  "page"?: number,
  "pageSize"?: number,
  "mer"?: string,
  "keyword"?: string,
}

// 游戏列表
export type GameListReq = {
  // 页码，从0开始
  "page"?: number,
  // 每页多少条记录,示例值(10)
  "pageSize"?: number,
  // 商户号
  "mer"?: number,
  // 类型id
  "tid"?: number,
  // 排序方式：asc,desc
  "sort"?: string,
  // 是否热门 1:热门
  "isHot"?: number
  // 排序类型
  sortBy?: string,
  // 模块id
  modelId?: number | string,
}
export type GameListRes = BaseResponseParam<GameInfo[]> // 返回游戏列表接口返回类型一样

export type GameInfo = {
  // 游戏Id
  id: number
  // 商户Id
  gameMid: number
  // 游戏类型
  typeId: number
  // 厂商游戏Id
  gameId: number
  // 游戏名称
  gameName: string
  // 游戏简要说明
  gameRemark: string
  // 游戏使用的技术
  gameTechnology: string
  // 技术描述
  gameTechnologyDesc: string
  // 打开游戏的平台
  gamePlatform: string
  // 0:不支持试玩1：支持试玩
  hasDemo: number
  // 游戏比例
  gameScale: string
  // 游戏id数值
  gameIdNumeric: number
  // 排序
  sort: number
  // 图标尺寸1:220*304 2:338*226
  size: true
  // 游戏图片
  icon: string
  // 1：启用0：禁用
  status: number
  // 是否热点游戏
  ishot: string
  // 创建时间
  createTime: number
  //角标ID
  subIconId: number
  //角标生效开启时间
  subIconStartTime: number
  //角标生效结束时间
  subIconEndTime: number
  //角标位置:1代表顶部，2代表右上角，3 代表右上角不贴边
  subIconPos: number
  //角标图片
  subIcon: string,
  //主题标签
  topicNames: Array<string>,
  //赔率
  rtp?: number,
  //最大赔率
  maxOdds?: number,
  //角标宽度
  subIconWidth?: number,
  //角标高度
  subIconHeight?: number,
}

//game/info/types 游戏类型列表
export type GameTypeListItem = {
  id: number
  name: string
  sort: number
  icon: string
  iconActive: string
  desc: string
  status: number
  gameTotal: number
  createTime: number
  games: Array<GameInfo>,
  subtitle: string
}

export type MerchantTypesReq = {
  "page"?: number,
  "pageSize"?: number,
  "mer"?: number,
  "keyword"?: string,
}


export type MerchantListItem = {
  // 商户id
  merId: number,
  // 商户名称
  merName: string,
  // 商户昵称
  nickname: string,
  // 游戏数量
  count: number,
}
export type GameTypeMerchantsResponse = BaseResponseParam<MerchantListItem[]>

// 游戏类型下厂家列表
export type GameTypeMerchantsReq = {
  // 游戏类型id
  tid: number
}

//game/info/topic 游戏主题列表
export type GameTopicListItem = {
  id: number
  name: string
  sort: number
  count: number
  games: Array<GameInfo>,
  subtitle: string,
}

//game/info/merchant 游戏厂家列表
export type GameMerchantListItem = {
  count: number
  id: number
  name: string
  nickname: string
  adsPic: string
  subtitle: string
  gameRow: number
  gameColumn: number
  games: Array<GameInfo>
}


//game/info/category 游戏自定义标签列表
export type GameCategoryListItem = {
  id: number
  name: string
  icon: string
  iconActive: string
  sort: number
  createTime: number
  count: number
  games: Array<GameInfo>,
  subtitle: string
}

export type GameCommonInfo = {
  gamesTypes: Array<GameTypeListItem> // 游戏类型列表
  gamesTopicRespList: Array<GameTopicListItem> // 游戏主题列表
  merchant: Array<GameMerchantListItem> // 游戏厂家列表
  gamesCategories: Array<GameCategoryListItem> // 游戏自定义标签列表
}
export type GameModuleCommonRes = BaseResponseParam<GameCommonInfo>


export type HallModuleItem = {
  id: number,
  icon: string,
  name: string,
  type: number,
  status: number,
  sort: number,
  interactiveModel: number,
  defaultRow: number,
  defaultColumn: number,
  relatedContent: string,

  // SSR扩展
  games: Array<any>,  // 模块下的游戏列表
  blocks: Array<any>,  // 模块下的板块列表
  total: number       // 模块的游戏总数
}

export type HomeHallItem = {
  id: number
  icon: string,
  name: string,
  relatedModule: Array<HallModuleItem>
}

// game/info/game/hall 首页游戏tab分类
export type HomeHallList = Array<HomeHallItem>
export type HomeHallRes = BaseResponseParam<HomeHallList>

// 游戏分类相关(无游戏集合)
export type GameType = {
  id: number
  name: string
  sort: number
  icon: string
  iconActive: string
  desc: string
  status: number
  gameTotal: number
  createTime: number
}

export type GameTopic = {
  id: number
  name: string
  sort: number
  count: number
}

export type GameMerchant = {
  count: number
  id: number
  name: string
  nickname: string
  adsPic: string
  subtitle: string
  gameRow: number
  gameColumn: number
}

export type GameCategory = {
  id: number
  name: string
  icon: string
  iconActive: string
  sort: number
  createTime: number
  count: number
}

// 简单游戏信息
export type GameBriefInfo = {
  title: string
  sectitle: string
  value: number
  icon: string,
  userdata: {
    gameid: number,
    mid: number,
    id: number
  },
  //主题标签
  topicNames: Array<string>,
  //赔率
  rtp?: number,
  maxOdds?: number,
  hasDemo: number,
  subIcon?: string,
  subIconPos?: number //1代表顶部，2代表右上角，3 代表右上角不贴边 
  //角标宽度
  subIconWidth?: number,
  //角标高度
  subIconHeight?: number,
};

// 游戏板块的数据源
export type GameBlockDataSource = {
  id: number
  title: string
  subtitle: string
  icon: string
  count: number
  games: GameBriefInfo[],
  rows: number
}

//betby信息
export type GameBetByInfo = {
  brandId: string,
  token: string,
}
//维护信息
export type GameMaintainInfo = {
  repairTitle: string,
  repairContent: string,
  repairPic: string,
}
//游戏跳转
export type GameJumpInfo = {
  url: string
}



// 游戏注单列表
// 注单信息
export type GameBetInfo = {
  /*记录id */
  id: number;

  /*用户id */
  userid: number;

  /*平台ID */
  platId: number;

  /*渠道 */
  channel: string;

  /*游戏中用户id */
  gameUserid: string;

  /* */
  gameMid: number;

  /*游戏类型id */
  gameTid: number;

  /*游戏名 */
  gameName: string;

  /*游戏id */
  gameId: string;

  /*游戏会话id */
  sessionId: string;

  /*父游戏会话id */
  parentSessionId: string;

  /*开始时间 */
  startTime: number;

  /*结束时间 */
  endTime: number;

  /*游戏状态 1 - 正在进行中（尚未完成）5 - 已完成 */
  status: number;

  /*下注金额 */
  bet: number;

  /*赢钱金额 */
  win: number;

  /*交易币种 */
  currency: string;

  /*奖金 */
  jackpot: number;

  /*额外数据,json string */
  extra: string;

  /*创建时间 */
  createTime: number;

  /*有效投注金额 */
  validBetCoin: number;

  /*投注类型 1 余额 2 奖金 */
  accountType: number;
}

export type GameBetsReq = {
  "page"?: number,
  "pageSize"?: number,
  "date": string,
  "tid": string,
}

export type GameBetsResult = {
  // 游戏注单数量
  total: number,
  // 总输赢
  totalWin: number,
  // 总投注
  totalBet: number,
  // 投注记录列表信息
  data: Array<GameBetInfo>
}
export type GameBetsRes = BaseResponseParam<GameBetsResult>


// 正式游戏
export type GameLoginReq = {
  merId: number,
  gameId: string
}

export type GameLoginResult = {
  "gameUrl": string,
  gameName: string,
  balance: number,
  balanceGift: number,
  isMaintain: boolean,
  repairTitle: string,
  repairPic: string,
  repairContent: string,
  repairStartTime: string,
  urlType: number,
  merchantNo: string,
  token: string
}
export type GameLoginRes = BaseResponseParam<GameLoginResult>


// 试玩游戏
export type GameTrialReq = {
  merId: number,
  gameId: string
}

export type GameTrialResult = {
  "gameUrl": string,
  gameName: string,
  balance: number,
  balanceGift: number,
  isMaintain: boolean,
  repairTitle: string,
  repairPic: string,
  repairContent: string,
  urlType: number,
  merchantNo: string,
  token: string
}
export type GameTrialRes = BaseResponseParam<GameTrialResult>


// 游戏下分
export type UnderScoreResult = {
  balance: number,
  balanceGift: number,
  // 如果有数据表示这个厂商还可以下分
  merIds: number[],
}
export type UnderScoreRes = BaseResponseParam<UnderScoreResult>

//获取游戏名称
export type GameNameReq = {
  mer: number,
  gameId: string
}
export type GameNameResult = {
  gameName: string
}
export type GameNameRes = BaseResponseParam<GameNameResult>


// 公告详情
export type NoticeDetailReq = {
  /*消息ID */
  id?: number;
}
export type NoticeDetailRes = BaseResponseParam<NoticeInfo>

export enum MenuItemType {
  // 标准列表
  List = 'list',
  // 九宫格
  Squared = 'squared',
  // 纯媒体 位图 | 字体图标 | 动画图标
  Medium = 'medium',
  // 自定义HTML 数据从用户数据字段中获取
  HTML = 'html',
}

export enum IconType {
  // 位图
  Image = 'image',
  // 字体图标
  Fonticon = 'fonticon',
  // 动画图标
  Animal = 'animal',
}

export enum ItemStyle {
  Banner = 'banner',
  Normal = 'normal'
}

export interface MenuItem {
  id: number
  type?: MenuItemType
  isopen?: boolean
  title?: {
    value: string,
    style?: {
      color?: string
      'font-size'?: string
      'font-weight'?: string
    }
  },
  icon?: {
    type: IconType,
    value: string
    style?: {
      width?: string
      height?: string
    }
  },
  childs?: Array<MenuItem>
  userdata?: any,
  itemstyle: ItemStyle,
  params?: any,
  redirectId: number,
  redirectContent: number,
  extraParams: string,
}

// export type Menu = {
//   background: {
//       color?: string
//       image?: string
//   }
// }


export type SelectItem = { title: string, value: any, sectitle: string }


// 与用户无关的基本信息
export type CommonData = {
  view: ViewCommonInfo,
  game: GameCommonInfo,
  activity: ActivityCommonInfo,
  hall: HomeHallList
}


//奖励记录
export type PromoteRewardRecord = {
  yesterdayInviteNum: number,
  totalInviteNum: number,
  rechargeNum: number,
  acceptedRechargeNum: number
}
export type PromoteRewardRecordRes = BaseResponseParam<PromoteRewardRecord>


//推广奖励汇总
export type PromoteRewardSummary = {
  allReward: number,
  promoteTotalRewardList: Array<{
    userid: number,
    totalReward: number,
    avatar: string,
  }>
}
export type PromoteRewardSummaryRes = BaseResponseParam<PromoteRewardSummary>


//推广奖励汇总2
export type PromoteRewardSummary2 = {
  allReward: number,
  inviteNumFirst: number,
  inviteNumSecondThird: number,
  inviteNumTotal: number,
  promoteTotalRewardList: Array<{
    userid: number,
    totalReward: number,
    avatar: string,
  }>
}
export type PromoteRewardSummaryRes2 = BaseResponseParam<PromoteRewardSummary2>



//推广配置
export type PromoteConfigResult = {
  rewardConfig: {
    betRewardGameIds: string,  //流水奖励指定 游戏类型（逗号分割）
    betRewardRate: number, //流水转佣金比例（%）
    firstBetRewardRate: number, //1级佣金比例（%）
    limitTimes: number, //流水奖励稽核倍率
    rechargeRewardMin: number, //首充最低金额
    rewardType: number, //"奖励类型 1：奖金，2赠金"
    secondBetRewardRate: number,//2级佣金比例（%）
    status: number, //"开关 1：开启，2：关闭"
    thirdBetRewardRate: number //3级佣金比例（%）
  },
  config: Array<{
    id: number, //id
    limitTimes: number, //稽核倍数
    recharge: number, //下级首充金额
    reward: number, //奖金
    rechargeNum: number //下级首充人数
  }>
}
export type PromoteConfigRes = BaseResponseParam<PromoteConfigResult>

//推广链接
export type PromoteLinkResult = {
  promoteUrl: string,
}
export type PromoteLinkRes = BaseResponseParam<PromoteLinkResult>


//领取首充奖励
export type PromoteGetRewardResult = {
  yesterdayInviteNum: number,
  totalInviteNum: number,
  rechargeNum: number,
  acceptedRechargeNum: number
}
export type PromoteGetRewardRes = BaseResponseParam<PromoteGetRewardResult>

// 活动开关
export type ActiveTaskStatusResult = {
  data: boolean
}
export type ActiveTaskStatusRes = BaseResponseParam<ActiveTaskStatusResult>


//每日任务列表
// 1:新手任务,2:日常任务,3:周长任务,4:宝箱任务
export enum TaskType {
  New = 1,
  Daily,
  Weekly,
  Box,
}

export type TaskListReq = {
  taskType?: number
}

// 奖励项
export type RewardListItem = {
  "rewardId": number,
  "rewardType": number,
  "rewardAmount": number,
  "rewardAudit": number
}

// 任务项
export type TaskListItem = {
  "id": number,
  "userId": number,
  "taskId": number,
  "taskName": string
  "taskType": number,
  "taskCondition": number,
  "taskParam": number,
  "taskProgress": number,
  "taskCycle": string,
  "redirectType": number,
  "redirectId": number,
  "rewardList": RewardListItem[],
  "status": number,
  "sort": number,
  "remark": string,
  "gameMid": number,
  "gameId": number,
  "createTime": number,
  "updateTime": number
}
export type TaskListRes = BaseResponseParam<{ data: TaskListItem[] }>


//查询是否有奖励可以领取
export type TaskHasAwardResult = {
  data: boolean,
}
export type TaskHasAwardRes = BaseResponseParam<TaskHasAwardResult>

//领取任务奖励
export type TaskReceiveReq = {
  taskId: number
}

//领取新手任务奖励72h
export type NewTaskReceive = {
  id: number,
}

// 轮盘基础配置
export type TurntableConfig = {
  status: number,
  start_time: number,
  end_time: number,
  tips_en: string,
  tips_pt: string,
  tips: string,
  max_spin: number,
  type: number,
  limit_times: number,
  reward_levels: Array<{
    level: number,
    status: number,
    points: number,
    reward_list: Array<{
      sort: number,
      reward_amount: number,
      odds: number
    }>
  }>
}
export type TurntableConfigRes = BaseResponseParam<TurntableConfig>


// 轮盘抽奖参数配置
export type TurntableSpinReq = {
  level: number
  point: number
}

export type TurntableSpinResult = {
  balance: number,
  balanceGift: number,
}
export type TurntableSpinRes = BaseResponseParam<TurntableSpinResult>


// 轮盘抽奖记录参数配置
export type TurntableRewardLog = {
  id: number,
  userid: string,
  type: number,
  orderid: string,
  beforeBalanceGift: number,
  beforeBalance: number,
  amount: number,
  afterBalance: number,
  afterBalanceGift: number,
  tableIdx: string,
  remark: string,
  createTime: number
}
export type TurntableRewardLogsRes = BaseResponseParam<TurntableRewardLog[]>

//查询抽奖次数
export type TurntableSpinCountResult = {
  spinCount: number
}
export type TurntableSpinCountRes = BaseResponseParam<TurntableSpinCountResult>


// 充值分享领取标识
export type RechargeShareCanGetReward = {
  /*领取状态；0-不可领取；1-可领取 2-已领取 */
  status: number;
  /*是否充值；0-未充值；1-已充值 */
  isRecharge: number;
  /*是否分享；0-未分享；1-已分享 */
  isShare: number;
  /*当日累计充值金额 */
  rechargeAmount: number;
}
export type RechargeShareCanGetRewardRes = BaseResponseParam<RechargeShareCanGetReward>

// 充值分享配置
export type RechargeShareConfig = {
  /*开关 1：开启 2：关闭 */
  status: number;
  /*最低充值金额 */
  min_money: number;
  /*奖励金额 */
  reward: number;
  /*奖励类型 1：奖金 2：赠金 */
  type: number;
  /*稽核倍率 */
  limit_times: number;
  /*顶部广告图 */
  icon: string;
  /*内容英文 */
  desc_en: string;
  /*内容葡文 */
  desc_pt: string;
  /*内容 */
  desc: string;
}
export type RechargeShareConfigRes = BaseResponseParam<RechargeShareConfig>

// 每日抽奖活动
export type LotteryDailyResult = {
  activityName: string
  reward: number,
  remainingTimes: number,
  rewardStatus: number,
  tips: string,
  expiration: number
  taskList: Array<{
    taskType: number,
    taskName: string,
    finishValue: number,
    targetValue: number,
    redirectId: number
  }>,
}
export type LotteryDailyRes = BaseResponseParam<LotteryDailyResult>

// 抽奖
export type LotteryDrawReq = {
  activityType: number,
}

// 抽奖活动状态
export type LotteryStatus = {
  /*新手抽奖状态 0：未开始，1：进行中 */
  noviceStatus: number;
  /*每日抽奖状态 0：未开始，1：进行中 */
  dailyStatus: number;
}
export type LotteryStatusRes = BaseResponseParam<LotteryStatus>

// 抽奖标识状态
export type LotteryFlag = {
  /*活动开关 1：开启，2：未开启 */
  switchStatus: number;
  /*红点状态 0：不显示，1：显示 */
  showStatus: number;
};
export type LotteryFlagRes = BaseResponseParam<LotteryFlag>

//新手抽奖活动
export type LotteryNewResult = {
  activityName: string
  reward: number,
  remainingTimes: number,
  rewardStatus: number,
  tips: string,
  taskList: Array<{
    taskType: number,
    taskName: string,
    finishValue: number,
    targetValue: number,
    redirectId: number
  }>,
}
export type LotteryNewRes = BaseResponseParam<LotteryNewResult>


//抽奖页面
export type LotteryDrawPageReq = {
  activityType: number
}
export type LotteryDrawPageResult = {
  activityName: string,
  expiration: number,
  totalReward: number,
  remainingTimes: string,
  lotteryUser: {
    userid: number,
    userName: string,
    lotteryCount: number
  }
}
export type LotteryDrawPageRes = BaseResponseParam<LotteryDrawPageResult>


//中奖记录
export type LotteryDrawLog = {
  userId: string,
  username: string,
  activityName: string,
  createTime: number,
  reward: number,
  status: number
}
export type LotteryDrawLogsReq = {
  activityType: number
}
export type LotteryDrawLogsRes = BaseResponseParam<LotteryDrawLog[]>


//用户中奖记录
export type LotteryUserLogsReq = {
  page: number,
  pageSize: number
  activityType: number,
  startDate: string,
  endDate: string,
}
export type LotteryUserLogsRes = BaseResponseParam<LotteryDrawLog[]>


// 兑换奖励
export type RedeemPrizeReq = {
  redeemCode: string
}

//球门区域列表
export type RedPacketListResult = {
  chanceLeft: number,
  areaList: Array<{
    areaNo: number,
    displayAmount: number,
    isHit: number
  }>
}
export type RedPacketListRes = BaseResponseParam<RedPacketListResult>


//红包配置
export type RedPacketConfigResult = Array<{
  id: number,
  level: number,
  lowerLimit: number,
  maxAmount: number,
  minAmount: number,
  redPacketNum: number,
  updateTime: number
}>
export type RedPacketConfigRes = BaseResponseParam<RedPacketConfigResult>

//存在可射门情况
export type RedPacketHighlightResult = {
  showStatus: number,
  switchStatus: number, //1:开启;2:关闭
}
export type RedPacketHighlightRes = BaseResponseParam<RedPacketHighlightResult>


//射门
export type RedPacketShootReq = {
  areaNo: string
}
export type RedPacketShootResult = {
  areaNo: number,
  displayAmount: number,
  isHit: number,
}
export type RedPacketShootRes = BaseResponseParam<RedPacketShootResult>


// 用户射门记录
export interface RedPacketShootRecordReq {
  /*日期 */
  dateId?: string;
}

export type RedPacketShootRecord = {
  /*时间段内累计射门次数 */
  shootTimes: number;

  /*时间段内累计的收益 */
  sumAmount: number;

  /*时间段内射门记录 */
  shootList: {
    /*id */
    id: number;

    /*射门收益 */
    amount: number;

    /*射门时间 */
    collectionTime: number;
  }[];
}
export type RedPacketShootRecordRes = BaseResponseParam<RedPacketShootRecord>


// 锦标赛活动页
export type ChampionshipPageResult = {
  eventName: string, //赛事名称
  ranking: string,  //我的排名
  activityPoints: number, //我的积分
  rankingReward: number, //奖励排名
  totalBonus: number, //总奖金
  activityStartTime: number, //活动开始时间
  activityEndTime: number, //活动结束时间
  activityDirections: string, //活动说明
  activityBanner: string, //活动banner
  gamesList: Array<
    {
      id: string, //id
      type_id: string, //游戏类型id
      game_id: string, //游戏id
      icon: string //游戏图标
    }
  >,
  myBonusResp: Array<
    {
      userid: number, //用户id
      eventId: number, //赛事id
      id: number, //奖励id
      eventName: string, //赛事名称
      ranking: string, //排名
      bonus: number, //奖金
      status: number //状态 0-无 1-已领取 2-未领取 3-已发放
    }
  >
  eventSchedule: Array<
    {
      id: number,
      name: string,
      icon: string,
      startTime: number,
      endTime: number,
      progress: number
    }
  >
}
export type ChampionshipPageRes = BaseResponseParam<ChampionshipPageResult>


// 锦标赛活动弹窗
export type ChampionshipPopResult = {
  userid: number, //用户id
  activityEndTime: number, //活动结束时间
  eventName: string, //赛事名称
  eventIcon: string, //赛事图标
  ranking: string, //排名
  activityPoints: number, //我的积分
  rankingReward: number, //奖励名次
  totalBonus: number, //总奖金
  championshipRankingRespList: Array<
    {
      userid: number, //用户id
      ranking: number, //排名
      activityPoints: number //积分
    }
  >,
  rewardList: Array<
    {
      rankBegin: number, //排名开始
      rankEnd: number, //排名结束
      reward: number //奖励
    }
  >
}
export type ChampionshipPopRes = BaseResponseParam<ChampionshipPopResult>


// 锦标赛排行榜
//参数
export type ChampionshipRankingReq = {
  id: number | string, //赛事id
}
export type ChampionshipRankingResult = {
  eventName: string, //赛事名称
  userid: number, //用户id
  eventIcon: string, //赛事图标
  activityPoints: number, //我的积分
  ranking: string, //我的排名
  reward: number, //我的奖励
  rankingReward: number, //奖励名次
  totalBonus: number, //总奖金
  rankingBoard:
  {
    ranking: string, //排名
    userid: number, //用户id
    activityPoints: number, //积分
    reward: number //奖励
  }[]
}
export type ChampionshipRankingRes = BaseResponseParam<ChampionshipRankingResult>

// 锦标赛领取奖励
export type ChampionshipReceiveReq = {
  id: number | string
}

//游戏是否关联锦标赛
export type ChampionshipJoinReq = {
  gameMid: number | string, //游戏类型id
  gameId: number | string //游戏id
}


// 分享配置
export type SharingItem = {
  content?: string
  createTime?: string
  id?: number
  name?: string
  picUrl?: string
  status?: boolean
  title?: string
  type?: true
  updateTime?: number
  url?: number
}
export type ActivitySharingConfig = {
  config: SharingItem[]
}
export type ActivitySharingConfigRes = BaseResponseParam<ActivitySharingConfig>

// activity/sharing/mediaConfig 媒体配置
export type MediaItem = {
  id?: number
  name?: string
  type?: number
  url?: string
  title?: string
  content?: string
  picUrl?: string
  status?: true
  createTime?: number
  updateTime?: number
}
export type ActivityMediaConfig = Array<MediaItem>
export type ActivityMediaConfigRes = BaseResponseParam<ActivityMediaConfig>

// activity/marquee/status 跑马灯状态
export type ActivityMarqueeStatus = {
  textStatus: number //1:开启;2:关闭
  gameStatus: number //1:开启;2:关闭
}
export type ActivityMarqueeStatusRes = BaseResponseParam<ActivityMarqueeStatus>


export type RechargeLevelInfo = {
  /*档位下限(包含) */
  minAmount: number;

  /*档位上限(不包含) */
  maxAmount: number;

  /*奖励类型 1:固定金额 2:百分比 */
  type: number;

  /*奖励 类型为百分比时, 该值范围0-100, 类型为固定金额时, 该值为具体金额 */
  reward: number;
}

// activity/first/config 首充 基础配置
export type ActivityFirstConfig = {
  status: number
  start_time: number
  end_time: number
  type: number
  levels: RechargeLevelInfo[]
}
export type ActivityFirstConfigRes = BaseResponseParam<ActivityFirstConfig>


//免token-请求活动信息-信息合集
export type ActivityCommonInfo = {
  sharingConfig: ActivitySharingConfig //分享配置
  mediaConfig: ActivityMediaConfig //媒体配置
  marqueeStatusResp: ActivityMarqueeStatus //跑马灯状态
  firstRechargeConfig: ActivityFirstConfig //首充基础配置
}
export type ActivityCommonRes = BaseResponseParam<ActivityCommonInfo>


//连续充值 赠送金额
export type ContinuousRechargeItem = {
  /*档位下限(包含) */
  minAmount: number;
  /*档位上限(不包含) */
  maxAmount: number;
  /*奖励类型 1:固定金额 2:百分比 */
  type: number;
  /*奖励 类型为百分比时, 该值范围0-100, 类型为固定金额时, 该值为具体金额 */
  reward: number;
}
export type ContinuousRechargeRes = BaseResponseParam<{
  rechargeRewardDetailList: ContinuousRechargeItem[]
}>


// 现金雨动画页面
export type CashRainAnimationConfig = {
  status: number,
  inProgress: boolean,
  countdown: number,
  nextStartTime: string,
}
export type CashRainAnimationConfigRes = BaseResponseParam<CashRainAnimationConfig>

// 现金雨是否达标
export type CashRainIsReachStandardResult = {
  /*是否达标 */
  isReachStandard: boolean;
  /*是否领取 */
  isReceive: boolean;
  /*奖池是否溢出 */
  overflows: boolean;
}
export type CashRainIsReachStandardRes = BaseResponseParam<CashRainIsReachStandardResult>

// 现金雨页面
export type CashRainPageConfig = {
  reachStandard: number,
  nextStartTime: string,
  limitReward:number,
  singleMaxReward: number,
  weekendPeriodRespList: Array<{
    id: number,
    startTime: string,
    endTime: string,
    validDate: string, //生效日期 1:每天 2:仅周五~周日
    status: number //1-进行中，2-将要，3-未开始 4-过期
  }>
  dailyPeriodRespList: Array<{
    id: number,
    startTime: string,
    endTime: string,
    validDate: string, //生效日期 1:每天 2:仅周五~周日
    status: number //1-进行中，2-将要，3-未开始 4-过期
  }>
}
export type CashRainPageConfigRes = BaseResponseParam<CashRainPageConfig>


//限时充值奖励配置
export type LimitTimeRewardConfig = {
  /*id */
  id: number;
  rechargeAmount: number,
  rewardAmount: number,
  expiration: number
}
export type LimitTimeRewardConfigRes = BaseResponseParam<LimitTimeRewardConfig[]>


// 限时充值标识
export type LimitTimeOpportunityFlag = {
  haveQuota: number,
  activityList: Array<any>,
  expiration: number
}
export type LimitTimeOpportunityFlagRes = BaseResponseParam<LimitTimeOpportunityFlag>


// 新手任务
export type NoviceTask = {
  /*新手任务id */
  id: number;

  /*用户ID */
  userid: number;

  /*任务ID */
  taskId: number;

  /*奖励金额 */
  amount: number;

  /*奖励类型  1-奖金 2-赠金 */
  type: number;

  /*任务状态  1-进行中、2-可领取、3-已领取 */
  status: number;

  /*跳转参数 */
  jumpCondition: string;

  /*任务名称 */
  taskName: string;

  /*需要完成条件 */
  requiredComplete: string;

  /*完成进度 */
  completed: string;
}
export type NoviceTaskListRes = BaseResponseParam<NoviceTask[]>


//领取标识
export type CardCanGetReward = {
  showStatus: number
  switchStatus: number //1:开启;2:关闭
}
export type CardCanGetRewardRes = BaseResponseParam<CardCanGetReward>


// 领取奖励
export type CardGetBonusResult = {
  /*id */
  id: number;

  /*周卡名称 */
  cardName: string;

  /*购买金额 */
  rechargeAmount: number;

  /*购买金额+立刻到账 */
  rechargeAmountAndNow: number;

  /*奖励金额 */
  rewardAmount: number;

  /*奖金明细 */
  bonusDetails: Record<string, unknown>[];

  /*奖金比例 */
  bonusRatio: number;

  /*今日奖金 */
  bonusToday: number;

  /*创建时间 */
  createTime: number;

  /*下次领取倒计时 */
  nextTimeForGet: number;

  /*下次购买倒计时 */
  nextTimeForBuy: number;

  /*累计收益 */
  cumulativeIncome: number;

  /*可用状态，1-可购买状态；2-已购买可领取状态;3-已领取状态（未领完）;4-已领完状态 */
  cardStatus: number;

  /*剩余天数 */
  remainingDays: number;
}
export type CardGetBonusRes = BaseResponseParam<CardGetBonusResult>

//领取周卡奖励
export type CardGetBonusRewardReq = {
  id: number
}

//周卡配置
export type WeeklyCardConfigReq = {
  id: number
}
export type WeeklyCardConfigResult = {
  id: number,
  cardName: string,
  rechargeAmount: number,
  rewardAmount: number,
  bonusDetail: string,
  bonusRatio: string,
  bonusToday: number,
  createTime: number,
  nextTimeForGet: number,
  nextTimeForBuy: number,
  cumulativeIncome: number,
  cardStatus: number
}
export type WeeklyCardConfigRes = BaseResponseParam<WeeklyCardConfigResult>


// 周卡列表
export type WeeklyCard = {
  id: number,
  cardName: string,
  buyStatus: number,
}
export type WeeklyCardListRes = BaseResponseParam<WeeklyCard[]>


//周卡及奖励明细
export type CanWeeklyCardRewardDetail = {
  cardName: string,
  rechargeAmount: number,
  bonusDetailLeft: string,
  bonusDetailRight: string
}
export type CanWeeklyCardRewardDetailRes = BaseResponseParam<CanWeeklyCardRewardDetail[]>


//周流水开关
export type WeekBetSwitchResult = {
  /*开关 1：开启，2：关闭 */
  status: number;
  /*奖励类型 1：奖金，2赠金 */
  rewardType: number;
  /*流水奖励稽核倍率 */
  limitTimes: number;
}
export type WeekBetSwitchRes = BaseResponseParam<WeekBetSwitchResult>


// 我的排名
export type WeekBetMyRankingResult = {
  userid: number,
  rankingEn: string,
  avatar: string,
  betAmount: number
}
export type WeekBetMyRankingRes = BaseResponseParam<WeekBetMyRankingResult>


// 查询上周奖励
export type WeekBetQueryRankingRewardResult = {
  id: number,
  userid: number,
  rankingEn: string,
  reward: number,
  status: number
}
export type WeekBetQueryRankingRewardRes = BaseResponseParam<WeekBetQueryRankingRewardResult>


// 周流水排行
export type WeekBetRankingListItem = {
  userid: number,
  ranking: number,
  rankingEn: string,
  avatar: string,
  reward: number
}
export type WeekBetRankingListRes = BaseResponseParam<WeekBetRankingListItem[]>


//领取上周奖励
export type ReceiveRankingRewardReq = {
  id: number
}


//开启的活动列表
export type OpenActivityListResult = {
  activityIndex: number,
  activityName: string
}


//活动接口整合列表
export type ActivityHomeInfo = {
  cashRainAnimationResp: CashRainAnimationConfig,
  cashRainReachStandardResp: CashRainIsReachStandardResult,
  continuousRechargeBonus: {
    rechargeRewardDetailList: ContinuousRechargeItem[],
  }
  dailyTasksRewordReceive: TaskHasAwardResult,
  limitTimeRechargeBaseConfig: LimitTimeOpportunityFlag,
  lotteryStatus: LotteryFlag,
  newbieTaskList: NoviceTask[],
  rouletteCheckNumberDraws: {},
  shootingAble: RedPacketHighlightResult,
  weeklyCardReceiveLogo: CardCanGetReward,
  checkInConfig: SignConfigResult,
}

export type ActivityHomeInfoRes = BaseResponseParam<ActivityHomeInfo>


//签到配置
export type SignConfigResult = {
  rechargeAmount: number,
  betAmount: number,
  status: number,
  openDate:string,
  rewards:Array<{
    id: number,
    type: number,
    reward: number,
    auditRatio: number,
    ifCheckIn: number,
  }>
}

export type SignConfigRes = BaseResponseParam<SignConfigResult>


export type VipItem = {
  vipId: number,
  reward: number,
  exp: number,
  rewardStr: string,
}

//vip权限配置
export type VipConfigResult = {
  gradeReward: Array<VipItem>,
  lossRebate: Array<VipItem>,
  weeklyReturns:Array<VipItem>,
}

export type VipConfigRespone = BaseResponseParam<VipConfigResult>









/**
 * @name RedirectType
 * @description 跳转类型 
 */
export enum RedirectType {
  // 内部页面跳转 对应后台id=3
  InnerPage = 1,
  // 内链跳转 (在app内部新开浏览器页面) 对应后台id=3
  InnerOpenNewWebView = 2,
  // 外链跳转 (在app外部新开浏览器页面)
  OutterOpenNewWebView = 3,
  // 富文本跳转 (后台传递富文本在app内部新开一个浏览器实现显示)
  RichTextOpenNewWebView = 4,
  // 活动跳转
  Activity = 5,
  // 游戏跳转
  Game = 6,
  // 功能跳转(比如跳转到客服功能入口)
  Function = 7,
}

/** 页面ID定义 */
export enum PageTypeId {
  // 首页
  home = 1,
  // 登录
  login = 2,
  // 注册
  register = 3,
  // 游戏分类
  gameclassify = 5,
  // 厂家分类
  providerclassify = 6,
  // 用户信息
  userinfo = 7,
  // 充值
  recharge = 8,
  // 账户隐私
  accountprivacy = 9,
  // 转账
  transferaccounts = 10,
  // 历史投注
  betHistory = 11,
  // 安装整合页
  install = 14,
  // VIP页面
  vip = 15,
  // 内部邮件
  message = 17,
  // 兑换码
  RedemptionCode = 18,
  // 奖金记录
  bonusRecord = 19,
  // 自定义分类
  customclassify = 20,
  // 收藏
  collect = 21,
  // 历史
  history = 22,
  // 游戏厂家列表
  gameproviderlist = 24,
  // 模块游戏
  mtype = 25,
  // 模块产品
  mproviders = 26,
  // 模块标签
  mcategory = 27,
  // 签到
  checkIn = 28,
  // 红包雨活动入口
  RedActivity = 29,
  //VIP返利入口
  VipRebate = 30,
  //奖金中心
  BonusCenter = 31,
  //活动中心
  ActivityCenter = 32,
  //连续充值
  ContinuousRecharge=33,
  //首充页面
  FirstChargePage=34,
}

/** 活动ID定义 */
export enum ActivityTypeId {
  // 首充
  firstcharge = 0,
  // 推广
  promotion = 1,
  // 转盘
  turntable = 2,
  // 日常
  daily = 3,
  // 兑换码
  redemptioncode = 4,
  // 每日抽奖
  lotterydraw = 5,
  // 踢球
  kickball = 6,
  // 流水周榜
  weeklyList = 7,
  // 周卡
  weeklyCard = 8,
  // 分享返利
  SharingRebatesView = 9,
  // 现金雨
  RedRain = 10,
  // 竞标赛
  Championship = 11
}

/** 功能Id定义 */
export enum FunctionTypeId {
  // 客服
  LiveChat = 0,
  // Pwa应用安装
  Install = 1,
  // 保存快捷方式
  Shortcuts = 2,
  // PWA安装  
  PWAInstall = 3,
}

// 跳转信息
export type RedirectInfo = {
  type: RedirectType
  param?: {
    [key: string]: any
  },
  [key: string]: any
}

// 内部页面跳转信息
export type InnerPageRedirectInfo = RedirectInfo & {
  pageId: string
}

// 内链地址跳转信息
export type InnerOpenNewWebViewRedirectInfo = RedirectInfo & {
  url: string
}

// 外链地址跳转信息
export type OutterOpenNewWebViewRedirectInfo = RedirectInfo & {
  url: string
}

// 富文本跳转信息
export type RichTextOpenNewWebViewRedirectInfo = RedirectInfo & {
  text: string
}

// 活动跳转信息
export type ActivityRedirectInfo = RedirectInfo & {
  id: string
}

// 游戏跳转信息
export type GameRedirectInfo = RedirectInfo & {
  gameId: number
  merId: number
}

// 下载奖励配置
export type DownloadRewardConfig = {
  terminalType: string, // 终端类型 1-android_apk 2-android_pwa 3-iOS_pwa 4-android_web 5-OS_web
  rewardWay: number, // 派奖方式 1:手动 2:自动
  realStatus: number, // 真人检测 1:开启 2:关闭
  rewardAmount: number, // 奖励金额
  realPersonMonitoring: boolean // 人机检查
  isvaild: boolean // 是否有效
}

export type downLoadRewardRecordReq = {
  userid: number,
  uuid: string,
  device: number,
  realResult: number
}

/** bus */
interface IEventBus {
  fireclosedialog: any,
  firelogin: any,
  firereg: any
}


export enum FireEvent {
  /** 登录注册对话框 */
  OPENDIAG = 'openDiag',
  COLSEDIAG = 'closeDiag',
  CLOSECOMPLATE = 'closeComplate',

  /** 消息提示 */
  MSG = 'msg',
  MSGGAME = 'msggame',

  /** 通用弹框 */
  COMMDIALOG = 'commDialog',
  /** 游戏维护对话框 */
  MaintainDialog = 'maintainDialog',

  /** 页面加载提示 */
  PAGELOADING = 'pageLoading',

  /** 视图触发消息 */
  LOGIN = 'login',
  LOGIN_FINISH = 'loginfinish',
  LOGOUT_FINISH = 'logoutfinish',
  REGISTER = 'register',
  HOME = 'home',
  KICKOUT = 'kickout',
  ROULETTE = 'openRoulette',
  REDRAIN = 'openRedRain',
  SLOTS = 'openSlots',
  SLOTSDaily = 'openSlotsDaily',
  GETGAMECARD = 'getGameCard',

  /** 侧滑菜单 */
  NDrawerShow = "ndrawershow",
  /** 触发侧滑菜单动作 */
  DOMENU = "domenu",

  /** 语言切换 */
  LANGCHANGE = 'langChange',

  /** app下载 */
  APPDOWNLOAD = 'appdownload',

  /** collect */
  Collect = 'collect',

  ACTIVEFAB = 'activefab',

  /** 限时充值弹框 */
  OPENLIMITEDDIALOG = 'openLimitedDialog',

  /** 到之前的菜单项目 */
  TOPREMENUITEM = 'toPreMenuItem',

  /** WS MESSAGE */
  WSMESSAGE = 'wsmessage',

  /** PWA DIALOG */
  OPENPWADIALOG = 'openPWADialog',

  /** FB GUIDE DIALOG */
  OPENFBGUIDEDIALOG = 'openFBGuideDialog',

  /** 埋点 */
  BURYINGPOINT = 'buryingpoint',

  /** 发现新版本 */
  NewVersion = 'newveriosn',


  /** 红包雨开始 */
  RainStart = 'RainStart',
  /** 红包雨进程开始 */
  RainProgressStart = 'RainProgressStart',
  /** 红包雨进程结束 */
  RainProgressEnd = 'RainProgressEnd',
}

export enum MsgEnum {
  SOCKET_ERROR = 500,
  CONN = 10000,
  CONN_RETURN = 10001,
  HEART_BEAT = 10002,
  UPDATE_BALANCE = 10002,
  MARQUEE_TEXT = 20000,
  MARQUEE_GAME = 20001,
  REDRAIN_START = 20002,
  REDRAIN_END = 20003,
  EVENTT = 10003,
  RECHARGE_SUCCESS = 10003,
  EXCHANGE_SUCCESS = 10004,
  EXCHANGE_FAIL = 10005,
}

export const shareUrl = {
  "firstCharge": {
    tg: "https://t.me/xfun678/350",
    fb: "https://www.facebook.com/share/p/2TFwWf1ianaw42cR/?mibextid=qi2Omg",
    xx: "https://www.instagram.com/p/C16sfhkuyTL/?igsh=eDJ1OXdwN244eHo1",
    whatsapp: ""
  },// 首充

  "promotion": {
    tg: "https://t.me/xfun678/345",
    fb: "https://www.facebook.com/share/p/dYuSoQ5bTqrGVRNE/?mibextid=qi2Omg",
    xx: "https://www.instagram.com/p/C16suc9OXrp/?igsh=MWw2d20xejM0dzBuag==",
    whatsapp: ""
  },// 推荐邀请

  "agentpromotion": {
    tg: "https://t.me/xfun678/310",
    fb: "https://www.facebook.com/share/p/XLthyqGSZw5x5Vu8/?mibextid=WC7FNe",
    xx: "https://www.instagram.com/p/C1clJmxOAXF/?igsh=M240cnNtN2tla3ls",
    whatsapp: ""
  },// 代理邀请

  "roulette": {
    tg: "https://t.me/xfun678/315",
    fb: "https://www.facebook.com/share/p/tyYwKHhmyZw81n4r/?mibextid=WC7FNe",
    xx: "https://www.instagram.com/p/C1ckzUnu_k0/?igsh=cmFmNGFvY29taHBw",
    whatsapp: ""
  },// 转盘

  "redPacket": {
    tg: "https://t.me/xfun678/348",
    fb: "https://www.facebook.com/share/p/ij9fowhUvicuuLgR/?mibextid=qi2Omg",
    xx: "https://www.instagram.com/p/C16rxyeONBM/?igsh=MWlxNXp4czNkZDI0Mg==",
    whatsapp: ""
  },// 射门红包

  "redRain": {
    tg: "https://t.me/xfun678/120",
    fb: "https://www.facebook.com/share/p/ABckW7dd5rKjZrd1/?mibextid=WC7FNe",
    xx: "",
    whatsapp: ""
  },// 红包雨

  "dailyTasks": {
    tg: "https://t.me/xfun678/343",
    fb: "https://www.facebook.com/share/p/hhiphBXsU9TePYGi/?mibextid=qi2Omg",
    xx: "https://www.instagram.com/p/C16sXVyu8ee/?igsh=MTczbHRia3YybTJsMQ==",
    whatsapp: ""
  },
  // 每日任务
  "weeklylist": {},                               // 周排行榜
  "weeklycard": {
    tg: "https://t.me/xfun678/116",
    fb: "https://www.facebook.com/share/p/sD2656B8Bau32A4Q/?mibextid=WC7FNe",
    whatsapp: ""
  },
  // 周卡
  "lotterydraw": {
    tg: "https://t.me/xfun678/325",
    fb: "https://www.facebook.com/share/p/p97JEc3TtbVAWqAk/?mibextid=WC7FNe",
    xx: "https://www.instagram.com/p/C1clER9Owqw/?igsh=bDlzcXZnanM0NGph",
    whatsapp: ""
  },
  // 新手抽奖 每日抽奖
  "sharingRebates": {
    tg: "https://t.me/laura_ok6",
    fb: "https://www.facebook.com/share/p/QdmdBFYq4U5R9xwE/?mibextid=WC7FNe",
    xx: "",
    whatsapp: ""
  },
  //默认的XFUN分享
  "default": {
    tg: "https://t.me/xfun678/345",
    fb: "https://www.facebook.com/share/p/dYuSoQ5bTqrGVRNE/?mibextid=qi2Omg",
    xx: "https://www.instagram.com/p/C16suc9OXrp/?igsh=MWw2d20xejM0dzBuag==",
    whatsapp: ""
  }
}

export const shareAdvertisement = {
  "firstCharge": "Complete o primeiro valor de recarga para obter bônus de 100%. Quanto mais você recarrega, maior o bônus, o bônus máximo é RS 5000",   // 首充
  "promotion": "Você pode obter bônus convidando amigos para completar a primeira recarga. Quanto mais você convidar, mais bônus receberá. Ganhe até RS 3000. Desfrute de 3 níveis de recompensas de apostas ao mesmo tempo.",   // 推广邀请
  "roulette": "Basta completar a sua aposta e terá 3 chances na roda da roleta correspondente, com bônus máximo de RS 1700.",           // 转盘
  "redPacket": "As perdas de ontem, os envelopes vermelhos de hoje oferecem mais e o bônus chega a RS5000",   // 射门红包
  "dailyTasks": "Você pode participar de novas atividades todos os dias. Grandes prêmios podem ser ganhos todas as semanas. Os pontos podem ser trocados por baús de bônus. Ganhe até RS 1000 por semana",    // 每日任务
  "weeklylist": "Suas apostas semanais válidas serão classificadas por pontos e o bônus será sorteado todas as segundas-feiras",//周流水
  "weeklycard": "Compre um cartão uma vez, receba 7 recompensas e um bônus de até 120%",                  // 周卡  *
  "lotterydraw": "Sorteamos todos os dias, ganhamos muito todos os dias, com prêmios de até RS1000",      // 新手抽奖 每日抽奖
  "sharingRebates": "Após o Depositar, você receberá bônus postando nas redes sociais Se quiser mais bônus, procure Laura👈",
  "bonus_Promotion": "Poste para seus amigos. Mais amigos depositam mais bônus que você ganha!",
  "bonus_Roulette": "Parabéns! Diga aos seus amigos que você ganhou bônus na Roleta",
  "bonus_lotterydraw": "Parabéns! Desbloqueie suas recompensas exclusivas! Compartilhe com seus amigos",
  "bonus_dailydraw": "Parabéns pela vitória de hoje! Haverá recompensas esperando por você para desafiar amanhã",
  "bonus_dailyTasks_new": "Parabéns pela missão completa! Repost para mais bônus ~",
  "Rechargeforwarding": "Parabéns pela conclusão! Convide amigos para jogar juntos",
  "bonus_common": "Parabéns pela missão completa! Repost para mais bônus ~",
  "bonus_redRain": "6 chuvas de dinheiro, bônus de 1 milhão, grátis para o resto da vida após depositar uma vez",
  "bonus_championship": "Promoções exclusivas da XFUN para amantes de games. Através das apostas, ganhe classificação e ganhe bônus.",
  "agentpromotion": "Você pode obter bônus convidando amigos para completar a primeira recarga. Quanto mais você convidar, mais bônus receberá. Ganhe até RS 3000. Desfrute de 3 níveis de recompensas de apostas ao mesmo tempo.",
  "default":"Poste para seus amigos. Mais amigos depositam mais bônus que você ganha!"
}

/**
* 跳转配置
* type=0&id=8 跳转类型内部页面,跳转到的页面id=8    PageTypeId 为内部ID定义
* type=0&id=5&&param=2 跳转类型内部页面,跳转到页面id=5,参数=2    PageTypeId 为功能ID定义
* type=4&id=1 跳转类型活动页面,跳转到的活动id=1    ActivityTypeId 为活动ID定义
* type=2&param=http://www.baidu.com 跳转类型外链地址,跳转到的地址为http://www.baidu.com
* type=5&gameId=1&merId=vswaysbufking 跳转类型游戏,跳转到的游戏id=vswaysbufking,厂家id=1 
* type=6&param=0 跳转类型功能,跳转到的功能id=0    FunctionTypeId 为功能ID定义
*/

/*
行为埋点名称
#[DESC('用户点击Top bar充值按钮')]
case top_recharge = 1;

#[DESC('用户点击Bottom bar充值按钮')]
case bottom_recharge = 2;

#[DESC('用户点击个人中心充值按钮')]
case personal_recharge = 3;

//#[DESC('用户点击现金雨充值引导按钮')]
//case cash_rain_recharge = 4;

#[DESC('用户点击侧滑菜单充值按钮')]
case slide_recharge = 5;

#[DESC('用户点击游戏内充值引导按钮')]
case game_recharge = 6;

#[DESC('用户点击充值页面充值按钮')]
case recharge = 7;

#[DESC('用户点击充值页面充值按钮成功充值')]
case recharge_success = 8;

//#[DESC('用户点击充值页面充值按钮充值失败')]
//case recharge_fail = 9;

#[DESC('用户点击充值页面取消充值优惠')]
case recharge_cancel = 10;

#[DESC('用户点击首页注册按钮')]
case index_register = 11;

#[DESC('用户点击注册页邮件注册')]
case email_register = 12;

#[DESC('用户点击注册页手机注册')]
case phone_register = 13;

#[DESC('用户点击注册页手机推广码展开')]
case phone_promote = 14;

#[DESC('用户点击banner图')]
case banner = 15;

#[DESC('用户点击首页活动入口icon')]
case active_icon = 16;

#[DESC('用户点击活动图标')]
case active = 17;

#[DESC('用户点击Bottom bar活动中心按钮')]
case bottom_active = 18;

#[DESC('用户点击兑换码兑换按钮')]
case redeem_code = 19;

#[DESC('用户点击活动中心客服按钮')]
case active_service = 20;

#[DESC('用户点击活动中心各个活动参与按钮')]
case active_join = 21;

#[DESC('用户点击新手任务')]
case novice_task = 22;

#[DESC('用户点击现金雨')]
case cash_rain = 23;

#[DESC('用户点击首页游戏标签')]
case index_game = 24;

#[DESC('用户点击游戏真实模式')]
case game_real = 25;

#[DESC('用户点击游戏收藏')]
case game_collect = 26;

#[DESC('用户点击游戏试玩模式')]
case game_try = 27;

#[DESC('用户点击个人中心提现')]
case personal_withdraw = 28;

#[DESC('用户点击侧滑菜单提现')]
case slide_withdraw = 29;

#[DESC('用户点击提现按钮成功出款')]
case withdraw_success = 30;

#[DESC('用户点击提现按钮出款失败')]
case withdraw_fail = 31;

//#[DESC('用户点击提现认证按钮')]
//case withdraw_auth = 32;

#[DESC('用户点击首页活动入口客服按钮')]
case index_service = 33;

#[DESC('用户点击个人中心客服按钮')]
case personal_service = 34;

#[DESC('用户点击侧滑菜单客服按钮')]
case slide_service = 35;

#[DESC('用户点击Bottom bar邀请按钮')]
case bottom_invite = 36;

#[DESC('用户点击邀请链接复制按钮')]
case invite_link = 37;

#[DESC('用户点击邀请码复制按钮')]
case invite_code = 38;

#[DESC('用户点击社交媒体分享按钮')]
case social_share = 39;

#[DESC('用户点击宝箱领取')]
case box_receive = 40;

#[DESC('用户点击邀请tg客服')]
case invite_tg_service = 41;

#[DESC('用户点击邀请FAQ')]
case invite_faq = 42;

#[DESC('用户点击邀请教程')]
case invite_tutorial = 43;

#[DESC('用户点击Bottom bar个人中心')]
case bottom_personal = 44;

//#[DESC('用户点击Bottom bar 个人中心充值按钮')]
//case bottom_personal_recharge = 45;

//#[DESC('用户点击Bottom bar 个人中心提现按钮')]
//case bottom_personal_withdraw = 46;

#[DESC('用户点击Bottom bar个人中心退出按钮')]
case bottom_personal_exit = 47;

#[DESC('用户点击Bottom bar个人中心邮箱')]
case bottom_personal_email = 48;

#[DESC('用户点击Bottom bar个人中心邮箱系统邮件')]
case bottom_personal_system_email = 49;

#[DESC('用户点击Bottom bar个人中心邮箱个人邮件')]
case bottom_personal_personal_email = 50;
}

协议号使用10003
public class PointEventReq {
  private Long userId;
  private Integer eventType;
  private String extralParam;
}

*/