import { debug } from "console"
import { useXCookie } from "./useXCookie"

export const useInitPage = () => {

  const commonStore = useCommonStore()
  const userStore = useUserStore()
  const activeStore = useActiveStore()

  const initSession = async () => {
    console.log('[initSession] start')
    const xCookie = useXCookie()
    // 此处可以使用客户端cookie
    let token = xCookie.getClientCookie('token')
    if (token) {
      console.log('[initSession]检测token', token)
      let isTokenOk = true
      try {
        // token续约，模拟登录操作，验证token的有效性
        userStore.userToken = token
        await userStore.user_extendtoken_actions()
        console.log('[token续约]成功')
      }
      catch (err) {
        console.log('[token续约]错误', err)
        isTokenOk = false
      }

      // 如果续约成功，则模拟登录后的动作
      console.log(`[initSession]isTokenOk=${isTokenOk}`)
      if (isTokenOk) {
        try {
          await requestAfterlogin()
          console.log(`[requestAfterlogin] success`)
        }
        catch (err) {
          console.log('[requestAfterlogin] error:', err)
        }
      } else {
        // 强制登出
        userStore.user_force_logout_actions()
        console.log('--->强制登出', userStore.isLogin, userStore.userToken)
      }
    }
    console.log('[initSession] end')
  }


  const getChannel = () => {
    console.log('[initChannel] start')
    const route = useRoute()
    const xCookie = useXCookie()
    let oldChannel = xCookie.getClientCookie('channel')

    let channel = (userStore.isLogin
      ? userStore.userInfo.channel
      : (route.query.channel as string)) || oldChannel

    console.log('[initChannel]', { query: route.query, channel: channel })

    if (channel !== oldChannel) {
      xCookie.setClientCookie('channel', channel)
    }

    console.log('[initChannel] end')
    return channel
  }

  const initCommon = async (body?) => {
    // 基础数据直接从`server`获取，无需带上`baseURL`
    await commonStore.loadCommon(body)
    userStore.sync_common_data_actions()
   

    // 登录放在了前端，逻辑放到前端实现
    // if (userStore.isLogin) {
    //   userStore.addandremovecollecthistory()
    // }

    console.log('[initCommon] end')
  }

  const initHomeHalls = async (opts?) => {
    console.log('[initHomeHalls] start')
    let promiseArr = [
      userStore.user_home_halls_actions(opts),
      userStore.user_fetchredirect_dict()
    ]
    await Promise.all(promiseArr)
  }

  const initDepositPIXPage = async (body?, opts?) => {
    console.log('[initDepositPIXPage] start')

    if (!userStore.isLogin) {
      console.log('[initDepositPIXPage] nologin')

      // 非登录下的充值配置
      await userStore.pay_payment_configchannel_actions(body, opts)
    }
    else {
      console.log('[initDepositPIXPage] login')
      // 登录下的充值配置
      // `requestAfterlogin`已处理

      // 限时充值配置
      if (activeStore.getLimittimeOpportunityFlag().haveQuota) {
        let args = Object.assign({}, body,
          {
            activityIds: activeStore.getLimittimeOpportunityFlag().activityList.join(',')
          }
        )
        await activeStore.activity_limit_recharge_config_action(args)
      }

      // 连续充值, 充值配置集合
      let promiseArr = [
        activeStore.activity_continue_recharge_config_action(body, opts),
        userStore.pay_config_rechargepage_actions(body, opts),
      ]
      await Promise.allSettled(promiseArr)
    }
    console.log('[initDepositPIXPage] end')
  }

  const initPromotionPage = async (body?, opts?) => {
    console.log('[initPromotionPage] start')
    if (userStore.isLogin) {
      let arr = [
        activeStore.activity_promote_config_action(body, opts),
        activeStore.activity_promote_rewardtotal_action(body, opts),
        activeStore.activity_promote_rewardrecord_action(body, opts),
      ]
      if (!activeStore.getPromoteUrl()) {
        arr.push(activeStore.activity_promote_promoteurl_action(body, opts))
      }
      await Promise.all(arr)
    }
    console.log('[initPromotionPage] end')
  }

  const initActivityCenterPage = async (args: any = {}, opts?) => {
    console.log('[initActivityCenterPage] start')
    try {
      await activeStore.activity_centerlist_actions(args, opts)
    }
    catch (err) {
      console.log('[error][initActivityCenterPage]', err)
    }
    console.log('[initActivityCenterPage] end')
  }

  // 首次访问server时，在SSR阶段初始化基础数据
  const initOnServerLoad = async (ctx: any) => {
    console.log('[initOnServerLoad] start')
    let args = Object.assign({ channel: getChannel(), ctx })
    await initCommon(args)  // 根据渠道拉取信息
    
    // 按游客身份获取一级页面数据(前端可根据需要覆盖)
    let arr = [
      initDepositPIXPage(args, { ignore: true, useAbsPath: true }),
      initPromotionPage(args, { ignore: true, useAbsPath: true }),
      initActivityCenterPage(args, { ignore: true, useAbsPath: true })
    ]
    await Promise.all(arr)
    console.log('[initOnServerLoad] end')
  }

  // 客户端加载后后，初始化用户和一级页面的关键数据
  const initOnClientLoad = async () => {
    console.log('[initOnClientLoad] start')
    await initSession() // 处理会话
    // 按正常身份再次获取一级页面数据
    let arr = [
      initHomeHalls(),
      initDepositPIXPage(),
      initPromotionPage(),
      initActivityCenterPage()
    ]
    await Promise.all(arr)
    // 加入额外的分类
    if (userStore.isLogin) {
      userStore.addandremovecollecthistory()
    }

    console.log('[initOnClientLoad] end')
  }

  // 客户端登录成功后，重新初始化基础数据
  const initAfterLogin = async () => {
    console.log('[initAfterLogin] start')
    let channel = getChannel() // 处理渠道
    let args = { channel }
    await initCommon(args)  // 根据渠道拉取信息
    let arr = [
      initDepositPIXPage(),
      initPromotionPage(),
      initActivityCenterPage()
    ]
    await Promise.all(arr)
    // 加入额外的分类
    if (userStore.isLogin) {
      userStore.addandremovecollecthistory()
    }
    console.log('[initAfterLogin] end')
  }

  return {
    initOnServerLoad,
    initOnClientLoad,
    initAfterLogin
  }
}
