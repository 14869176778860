import type { EventHook } from "@vueuse/core";
import type { BaseResponseParam } from "~/server/api/xfun/config";
import { clone, appendLog } from "~/utils";
import { useXCookie } from '~/composables/xfun/useXCookie'

const waitForSeconds = async (secs: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, secs * 1000)
  })
}

const fetchEvent: EventHook<any> = createEventHook()
const onFetchEvent: any = fetchEvent.on

export { onFetchEvent }

export enum FetchEvents {
  EVENT_KICK_OUT = 'EVENT_KICK_OUT',
  EVENT_EXTEND_TOKEN = 'EVENT_EXTEND_TOKEN',
  EVENT_NET_MESSAGE = 'EVENT_NET_MESSAGE',
  EVENT_SERVICE_MESSAGE = 'EVENT_SERVICE_MESSAGE'
}

export function generateRequestFunction<T = BaseResponseParam<any>>(url: string, body?, opts?): Promise<T> {
  if (!body) body = {}
  if (!opts) opts = {}

  // `__useFetch__`内部会修改body，这里提前备份一次
  let bakBody = clone(body)
  let bakOpts = clone(opts)

  // 异步请求
  return (async () => {
    const a = await __useFetch__(url, { body: clone(body) }, opts)
    const { data, pending, error, refresh, status } = a
    let res = data.value

    // 发生错误
    if (error.value) {
      if (!opts.ignore) {
        fetchEvent.trigger({
          name: FetchEvents.EVENT_NET_MESSAGE,
          args: error.value
        })
      }
      return Promise.reject(error.value)
    }

    // 数据为空
    if (!data.value) {
      if (!opts.ignore) {
        fetchEvent.trigger({
          name: FetchEvents.EVENT_NET_MESSAGE,
          args: 'null'
        })
      }
      return Promise.reject(error.value)
    }

    // console.log('[res]', res)
    let code = res.code
    const msg = res.msg

    if (code == 1 || code == 6060) {
      // console.log('response post', "api: " + url, "ok")
      // 请求成功
      return Promise.resolve(res)
    }

    // 如果忽略错误码，则直接抛错
    if (opts.ignore) {
      return Promise.reject(msg)
    }

    // 进一步处理
    if (code == 108) {
      return new Promise((resolve, reject) => {
        // 如果现在是续期token的请求, 则把当前续期后再次发送
        console.log('[net]response extend token', "api: " + url)
        fetchEvent.trigger({
          name: FetchEvents.EVENT_EXTEND_TOKEN,
          args: {
            url: url,
            body: Object.assign({ 'x-resend': true }, bakBody),
            opts: bakOpts,
            resolve,
            reject
          }
        })
      })
    }

    if (code == 102) {
      // 被踢下线
      console.log('[net]response kickout', "api: " + url)
      fetchEvent.trigger(
        {
          name: FetchEvents.EVENT_KICK_OUT,
          args: ''
        }
      )
      return Promise.reject(msg)
    }

    // 其它错误
    console.log('[net]response error', "api: " + url)
    fetchEvent.trigger(
      {
        name: FetchEvents.EVENT_SERVICE_MESSAGE,
        args: msg
      }
    )
    return Promise.reject(msg)
  })()
}


function __useFetch__(url: string, args?, opts?): Promise<any> {
  if (!args) args = {}
  if (!opts) opts = {}

  // console.log('----------------->args', JSON.stringify(args))
  let _opts = clone(opts)

  args.method = 'POST'
  if (!args.body) args.body = {}

  args.headers = {
    lang: 'pt'
  }

  // 附加`token`
  let token = args.body.token
  if (token) {
    args.headers.token = token
    delete args.body.token
  }

  // 附加`x-domain`
  let isServer = import.meta.server
  if (isServer) {
    let client_host = args.body?.ctx?.client_host
    if (client_host) {
      args.headers['x-origin'] = 'x_app'
      args.headers['x-domain'] = client_host
      args.headers['x-point'] = 'server'
    }
  }
  else {
    args.headers['x-origin'] = 'x_app'
    args.headers['x-domain'] = window.location.origin
    args.headers['x-point'] = 'client'
  }

  // 写入特殊信息到文件
  let xDomain: string = args.headers['x-domain'] || ''
  if (isServer && xDomain.includes('localhost')) {
    if (xDomain !== 'localhost:8081') {
      let msg = `[net]${JSON.stringify(args)}`
      appendLog('xfun.log', msg)
    }
  }

  // 附加`channel`
  const cookie = useXCookie()
  let channel = args.body.channel || cookie.getClientCookie('channel') || 'xfun'
  if (channel) {
    args.body.channel = channel
    args.body.code = channel
  }
  // console.log('------>reqBody', args.body)

  // 如果设置了useAbsPath，则对URL进行相应的处理
  if (_opts && _opts.useAbsPath) {
    url = `${getBaseUrl()}${url}`
    delete _opts.useAbsPath
  }

  // 生成key
  args.key = cyrb53(`${url}_${JSON.stringify(args)}`).toString()

  // 合并来自`useAsyncData`的参数
  if (_opts) Object.assign(args, _opts)

  console.log('[useFetch]', url, args)
  return useFetch(`${url}`, args)
}


// 参考：后端判断逻辑
// protected String getDomain() {
//   String origin = getOrigin();
//   if (StringUtils.isNotEmpty(origin)) {
//     String domain = origin.replace("http://", "").replace("https://", "").replace("/", "");
//     log.info("getOrigin当前请求域名：{}", domain);
//     return domain;
//   } else {
//     String header = request.getHeader("x-origin");
//     log.info("获取当前请求头x-origin：{}", header);
//     if (StringUtils.isNotEmpty(header) && "x_app".equals(header)) {
//       String xOrigin = request.getHeader("x-domain");
//       String domain = xOrigin.replace("http://", "").replace("https://", "").replace("/", "");
//       log.info("app当前请求域名：{}", domain);
//       return domain;
//     }
//     log.info("getServerName当前请求域名：{}", request.getServerName());
//     return request.getServerName();
//   }
// }
