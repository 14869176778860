<template>
  <v-dialog transition="dialog-bottom-transition" width="auto" v-model="dialog" persistent>
      <template v-slot:default="{ isActive }">
        <v-card class="custom-card">
          <div class=" d-flex align-center justify-center" style="background: #1A1A25;">
            <text class="d-flex align-center justify-center title">{{ dialogOptions.title }}</text>
          </div>
          <v-card-text class="content pa-5 d-flex align-center justify-center">
            <div>{{ dialogOptions.content }}</div>
          </v-card-text>
          <div class="justify-end py-0 mb-3">
            <div class="d-flex flex-fill justify-center align-center mx-3"
              v-if="dialogOptions.type == DialogStyle.OKCANCEL">
              <xbutton size="small" class="btbsize" skin="weak-1"
                @click="dialogOptions.callbackClose() ? isActive.value = false : ''">{{dialogOptions.CancelText}}</xbutton>
              <xbutton size="small" class="btbsize ml-3"
                @click="dialogOptions.callbackOk() ? isActive.value = false : ''">
                {{dialogOptions.OkText}}
              </xbutton>
            </div>
            <div v-else-if="dialogOptions.type == DialogStyle.OK" class="d-flex align-center justify-center">
              <xbutton size="small" class="btbsize" @click="dialogOptions.callbackOk() ? isActive.value = false : ''">{{dialogOptions.OkText}}
              </xbutton>
            </div>
            <div v-else-if="dialogOptions.type == DialogStyle.CLOSE" class="d-flex align-center justify-center">
              <xbutton size="small" class="btbsize" @click="dialogOptions.callbackClose() ? isActive.value = false : ''">
                {{dialogOptions.CancelText}}</xbutton>
            </div>
          </div>
        </v-card>
      </template>
  </v-dialog>
</template>

<script lang="ts" setup>
import { useBus } from '~/composables/xfun/bus'
import xbutton from '~/components/lib/xbutton.vue'

let bus = useBus()
let dialog = ref(false)

const emit = defineEmits([])

const dialogOptions = reactive({
  title: 'hello',
  content: '',
  type: DialogStyle.OKCANCEL,
  OkText: 'OK',
  CancelText: 'Cancelar',
  callbackOk: () => { return true },
  callbackCancel: () => { return true },
  callbackClose: () => { return true }
})


bus.fireCommDialog.reset()
bus.fireCommDialog.on((options: any) => {
  if (dialog.value == false) {
    Object.assign(dialogOptions, options)
    dialog.value = true
  }
})

</script>

<style scoped>
.custom-card {
  background: #20233C;
  border-radius: 20px 20px 30px 30px !important;
  border: 1px solid #505363;
  width: 327px;
  min-height: 150px;
}
.title {
  font-family: Microsoft YaHei Bold;
  font-size: 18px;
  font-weight: 700;
  height: 36px;

  background: linear-gradient(180deg, #9DD0F8 33.85%, #FBFDFF 75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.content {
  color: #A6A6BD;
  font-family: Arial !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.btbsize {
  min-width: 100px;
}
</style>