import type { TokenParam } from "~/server/api/xfun/config"
import type { ActivityFirstConfigRes, ActivityMarqueeStatusRes, ActivityMediaConfigRes, CardCanGetRewardRes, CardGetBonusRewardReq, ChampionshipJoinReq, ChampionshipPageRes, ChampionshipPopRes, ChampionshipReceiveReq, LotteryDrawLogsReq, LotteryDrawLogsRes, LotteryDrawPageReq, LotteryDrawPageRes, LotteryDrawReq, LotteryStatus, PromoteRewardSummaryRes, ReceiveRankingRewardReq, RedPacketHighlightRes, RedPacketListRes, RedeemPrizeReq, TaskListReq, TaskReceiveReq, TurntableSpinCountRes, TurntableSpinReq, TurntableSpinRes, WeekBetMyRankingRes, WeekBetSwitchRes, LotteryStatusRes, LotteryFlagRes, CardGetBonusRes, WeeklyCardConfigReq, LimitTimeRewardConfigRes, LimitTimeOpportunityFlagRes, NoviceTaskListRes, RechargeShareCanGetRewardRes, RedPacketShootRecordReq, ActivityHomeInfoRes, NewTaskReceive,BonusCenterListRes,BonusCenterDetailRes } from "~/types/xfun"
import { generateRequestFunction } from "./net"

/**活动中心列表 */
const activity_CenterList = '/activity/activityCenter/list' //活动中心列表
const activity_CenterOpenList = '/activity/activityCenter/open/list' //已经开启的活动列表
const activity_activityCenter_homeInfo = '/activity/activityCenter/home/info' //活动接口整合

/**奖金中心 */
const active_bonusCenterStatistical = 'activity/activityCenter/statistical' //奖金中心统计和详情
const active_bonusCenterList = 'activity/activityCenter/bonusCenterList' //奖金中心列表

/**推广相关 */
const active_promoterewardrecord = '/activity/promote/reward/record'
const active_promoterewardtotal = '/activity/promote/reward/total'
const active_promoterewardtotal2 = '/activity/promote/rewardTotalV2'
const active_promoteconfig = '/activity/promote/config'
const active_promotepromoteUrl = '/activity/promote/promoteUrl'
const active_promoteGetReward = '/activity/promote/get/reward'

/**日常任务相关 */
const active_taskStatus = '/activity/task/status' // 任务状态
const active_taskList = '/activity/task/list'    // 任务列表
const active_taskHasReward = '/activity/task/hasAward'  // 查询是否有奖励可以领取
const active_taskReceiveAward = '/activity/task/receiveAward' // 领取任务奖励
const active_list123 = '/activity/task/list123' // 三个任务

/**转盘相关 */
const active_turntableConfig = '/activity/turntable/config' // 轮盘基础配置
const active_turntableSpin = '/activity/turntable/spin' // 转盘抽奖
const active_turntableRewardLogs = '/user/wallet/turntable/rewardLogs' // 转盘抽奖记录
const active_turntableSpinCount = '/activity/turntable/spin/count' // 转盘抽奖次数

const active_walletPointGroup = '/user/wallet/pointGroup' //积分分类汇总

/**充值分享 */
const active_rechargeShare = '/activity/rechargeSharing/canGetReward' // 领取标识
const active_rechargeShareConfig = '/activity/rechargeSharing/config' // 充值分享配置
const active_rechargeShareGetBonus = '/activity/rechargeSharing/getBonus' // 领取奖励
const active_rechargeShareSharing = '/activity/rechargeSharing/sharing' // 分享

/**新手、每日抽奖 */
const active_lotteryCurrentLotteryRecords = '/activity/lottery/currentLotteryRecords' // 用户中奖记录
const active_lotteryDailyActivity = '/activity/lottery/daily/activity' // 每日抽奖活动
const active_lotteryGetActivityStatus = '/activity/lottery/getActivityStatus' // 活动状态查询
const active_lotteryGetLotteryStatus = '/activity/lottery/getLotteryStatus' // 抽奖标识
const active_lotteryLottery = '/activity/lottery/lottery' // 抽奖
const active_lotteryNoviceActivity = '/activity/lottery/novice/activity' // 新手抽奖活动
const active_lotteryPage = '/activity/lottery/page' // 抽奖页面
const active_lotteryRecords = '/activity/lottery/records' // 中奖记录

/**兑换码 */
const active_redeemPrize = '/activity/redeem/code/redeemPrize' // 兑换码

/**红包雨（足球） */
const active_redPacketAreaList = '/activity/red/packet/areaList' // 球门区域列表
const active_redPacketConfig = '/activity/red/packet/config' // 红包雨配置
const active_redPacketHighlight = '/activity/red/packet/highlight' // 可射门高亮
const active_redPacketShoot = '/activity/red/packet/shoot' // 射门
const active_redPacketshootRecords = '/activity/red/packet/shootRecords' // 用户射门记录

/**锦标赛 */
const active_championshipPage = '/activity/championship/activity/page' // 锦标赛页面
const active_championshipPopUp = '/activity/championship/activity/pop/up' // 活动弹窗
const active_championshipRanking = '/activity/championship/ranking' // 锦标赛排行榜
const active_championshipReceive = '/activity/championship/receive' // 领取奖励
const active_championshipJoinChampionship = '/activity/championship/join/championship' //游戏是否关联锦标赛

/**媒体配置 */
const active_shareMediaConfig = '/activity/sharing/mediaConfig' // 媒体页配置
const active_shareSharingConfig = '/activity/sharing/sharingConfig' // 分享页配置

/**跑马灯 */
const active_marqueeStatus = '/activity/marquee/status' // 跑马灯状态

/**首充*/
const active_firstConfig = '/activity/first/config' // 首充配置

/**连续充值 */
const active_continueRecharge = '/activity/recharge/continuous/reward' //连续充值配置

/**现金雨（撒钱） */
const active_cashRainAnimation = '/activity/cash/rain/animation' // 现金雨动画接口
const active_cashIsReachStandard = '/activity/cash/rain/isReachStandard' // 用户是否达标
const active_cashRainPage = '/activity/cash/rain/page' // 现金雨页面
const active_cashRainReceiveReward = '/activity/cash/rain/receiveReward' // 领取奖励

/**限时充值 */
const active_limitTimeGetRewardConfig = '/activity/limit/time/getRewardConfig' // 限时充值奖励配置
const active_limitTimeIsOpportunity = '/activity/limit/time/isOpportunity' // 限时充值标识

/**新手任务72h */
const active_noviceTaskFollowMedia = '/activity/novice/task/follow/official/media' //关注官媒 跳转成功调用
const active_noviceTaskList = '/activity/novice/task/list' // 新手任务列表
const active_noviceTaskReceive = '/activity/novice/task/receive' // 领取奖励

/**周卡 */
const active_weeklyCardCanGetReward = '/activity/weekly/card/canGetReward' // 领取标识
const active_weeklyCardGetBonus = '/activity/weekly/card/getBonus' // 获取奖励
const active_weeklyCardWeeklyCardConfig = '/activity/weekly/card/weeklyCardConfig' //周卡配置
const active_weeklyCardWeeklyCardList = '/activity/weekly/card/weeklyCardList' //TODO 可用周卡列表(是否为数组)
const active_weeklyCardWeeklyCardRewardDetail = '/activity/weekly/card/weeklyCardRewardDetail' //周卡及奖励明细


/**周流水排行 */
const active_weeklyBetGetWeekBetSwitch = '/activity/week/bet/getWeekBetSwitch' //周流水排行开关
const active_weeklyBetMyRanking = '/activity/week/bet/myRanking' //我的排名
const active_weeklyBetQueryRankingReward = '/activity/week/bet/queryRankingReward' //查询上周奖励
const active_weeklyBetQueryRankingList = '/activity/week/bet/rankingList' //周流水排行
const active_weeklyBetReceiveRankingReward = '/activity/week/bet/receiveRankingReward' //领取上周奖励

/**签到数据 */
const active_checkIn = '/activity/checkIn/checkIn' //签到
const active_checkInConfig= '/activity/checkIn/config' //签到配置
const active_checkInIsCheckIn= '/activity/checkIn/isCheckIn' //是否已经签到


/**VIP权限 */
const active_vipConfig = '/user/vip/activity' //VIP配置
const active_viplossRebate ='/activity/week/vip/loss/queryLastWeekReward' //VIP周亏损返利
const  active_vipReceivelossRebate='/activity/week/vip/loss/receiveVipLossRebate' //领取亏顺返利奖励

// 下载奖励
const active_realPersonDetection = '/activity/downloadReward/realPersonDetection'

/**
 * @description 活动相关接口
 */
export const useActivity = () => {
  // 活动中心
  const fetchActivityCenterList = async (args: any = {}, opts?) => generateRequestFunction(activity_CenterList, args, opts)
  const fetchActivityCenterOpenList = async (args: TokenParam, opts?) => generateRequestFunction(activity_CenterOpenList, args, opts)
  const fetchActivityCenterHomeInfo = async (args: TokenParam, opts?) => generateRequestFunction<ActivityHomeInfoRes>(activity_activityCenter_homeInfo, args, opts)

  // 奖金中心
  const fetchBonusCenterStatistical = async (args: TokenParam, opts?) => generateRequestFunction<BonusCenterListRes>(active_bonusCenterStatistical, args, opts)
  const fetchBonusCenterList = async (args: TokenParam, opts?) => generateRequestFunction<BonusCenterDetailRes>(active_bonusCenterList, args, opts)

  // 推广相关
  const fetchPromoteRewardRecord = async (args: TokenParam, opts?) => generateRequestFunction<PromoteRewardRecordRes>(active_promoterewardrecord, args, opts)
  const fetchPromoteRewardTotal = async (args: TokenParam, opts?) => generateRequestFunction<PromoteRewardSummaryRes>(active_promoterewardtotal, args, opts)
  const fetchPromoteRewardTotal2 = async (args: TokenParam, opts?) => generateRequestFunction<PromoteRewardSummaryRes2>(active_promoterewardtotal2, args, opts)
  const fetchPromoteConfig = async (args: TokenParam, opts?) => generateRequestFunction<PromoteConfigRes>(active_promoteconfig, args, opts)
  const fetchPromotePromoteUrl = async (args: TokenParam, opts?) => generateRequestFunction<PromoteLinkRes>(active_promotepromoteUrl, args, opts)
  const fetchPromoteGetReward = async (args: TokenParam, opts?) => generateRequestFunction<PromoteGetRewardRes>(active_promoteGetReward, args, opts)
  const fetchPromoteWalletPointGroup = async (args: TokenParam, opts?) => generateRequestFunction(active_walletPointGroup, args, opts)
  const fetchPromoteWalletPointLogs = async (args: WalletPointsLogsReq & TokenParam, opts?) => generateRequestFunction(active_walletPointGroup, args, opts)

  // 日常任务相关
  const fetchTaskStatus = async (args?, opts?) => generateRequestFunction<ActiveTaskStatusRes>(active_taskStatus, args, opts)
  const fetchTaskList = async (args: TaskListReq & TokenParam, opts?) => generateRequestFunction<TaskListRes>(active_taskList, args, opts)
  const fetchTaskHasReward = async (args: TokenParam, opts?) => generateRequestFunction<TaskHasAwardRes>(active_taskHasReward, args, opts)
  const fetchTaskReceiveAward = async (args: TaskReceiveReq & TokenParam, opts?) => generateRequestFunction(active_taskReceiveAward, args, opts)
  const fetchTaskList123= async (args?, opts?) => generateRequestFunction(active_list123, args, opts)


  // 转盘相关
  const fetchTurntableConfig = async (args?, opts?) => generateRequestFunction<TurntableConfigRes>(active_turntableConfig, args, opts)
  const fetchTurntableSpin = async (args: TurntableSpinReq & TokenParam, opts?) => generateRequestFunction<TurntableSpinRes>(active_turntableSpin, args, opts)
  const fetchTurntableRewardLogs = async (args: TokenParam, opts?) => generateRequestFunction<TurntableRewardLogsRes>(active_turntableRewardLogs, args, opts)
  const fetchTurntableSpinCount = async (args: TokenParam, opts?) => generateRequestFunction<TurntableSpinCountRes>(active_turntableSpinCount, args, opts)

  //充值分享
  const fetchRechargeShare = async (args: TokenParam, opts?) => generateRequestFunction<RechargeShareCanGetRewardRes>(active_rechargeShare, args, opts)
  const fetchRechargeShareConfig = async (args: TokenParam, opts?) => generateRequestFunction<RechargeShareConfigRes>(active_rechargeShareConfig, args, opts)
  const fetchRechargeShareGetBonus = async (args: TokenParam, opts?) => generateRequestFunction(active_rechargeShareGetBonus, args, opts)
  const fetchRechargeShareSharing = async (args: TokenParam, opts?) => generateRequestFunction(active_rechargeShareSharing, args, opts)

  // 新手、每日抽奖
  const fetchLotteryCurrentLotteryRecords = async (args: LotteryUserLogsReq & TokenParam, opts?) => generateRequestFunction<LotteryUserLogsRes>(active_lotteryCurrentLotteryRecords, args, opts)
  const fetchLotteryRecords = async (args: LotteryDrawLogsReq & TokenParam, opts?) => generateRequestFunction<LotteryDrawLogsRes>(active_lotteryRecords, args, opts)
  const fetchLotteryDailyActivity = async (args: TokenParam, opts?) => generateRequestFunction<LotteryDailyRes>(active_lotteryDailyActivity, args, opts)
  const fetchLotteryGetActivityStatus = async (args: TokenParam, opts?) => generateRequestFunction<LotteryStatusRes>(active_lotteryGetActivityStatus, args, opts)
  const fetchLotteryGetLotteryStatus = async (args: TokenParam, opts?) => generateRequestFunction<LotteryFlagRes>(active_lotteryGetLotteryStatus, args, opts)
  const fetchLotteryLottery = async (args: LotteryDrawReq & TokenParam, opts?) => generateRequestFunction(active_lotteryLottery, args, opts)
  const fetchLotteryNoviceActivity = async (args: TokenParam, opts?) => generateRequestFunction<LotteryNewRes>(active_lotteryNoviceActivity, args, opts)
  const fetchLotteryPage = async (args: LotteryDrawPageReq & TokenParam, opts?) => generateRequestFunction<LotteryDrawPageRes>(active_lotteryPage, args, opts)

  //兑换码
  const fetchExchangeCode = async (args: RedeemPrizeReq & TokenParam, opts?) => generateRequestFunction(active_redeemPrize, args, opts)

  //红包雨（足球）
  const fetchRedPacketAreaList = async (args: TokenParam, opts?) => generateRequestFunction<RedPacketListRes>(active_redPacketAreaList, args, opts)
  const fetchRedPacketConfig = async (args: TokenParam, opts?) => generateRequestFunction<RedPacketConfigRes>(active_redPacketConfig, args, opts)
  const fetchRedPacketHighlight = async (args: TokenParam, opts?) => generateRequestFunction<RedPacketHighlightRes>(active_redPacketHighlight, args, opts)
  const fetchRedPacketShoot = async (args: RedPacketShootReq & TokenParam, opts?) => generateRequestFunction<RedPacketShootRes>(active_redPacketShoot, args, opts)
  const fetchRedPacketshootRecords = async (args: RedPacketShootRecordReq & TokenParam, opts?) => generateRequestFunction<RedPacketShootRecordRes>(active_redPacketshootRecords, args, opts)

  //锦标赛
  const fetchChampionshipPage = async (args: TokenParam, opts?) => generateRequestFunction<ChampionshipPageRes>(active_championshipPage, args, opts)
  const fetchChampionshipPopUp = async (args: TokenParam, opts?) => generateRequestFunction<ChampionshipPopRes>(active_championshipPopUp, args, opts)
  const fetchChampionshipRanking = async (args: ChampionshipRankingReq & TokenParam, opts?) => generateRequestFunction<ChampionshipRankingRes>(active_championshipRanking, args, opts)
  const fetchChampionshipReceive = async (args: ChampionshipReceiveReq & TokenParam, opts?) => generateRequestFunction(active_championshipReceive, args, opts)
  const fetchChampionshipJoinChampionship = async (args: ChampionshipJoinReq & TokenParam, opts?) => generateRequestFunction(active_championshipJoinChampionship, args, opts)

  //媒体配置
  const fetchShareMediaConfig = async (args: TokenParam, opts?) => generateRequestFunction<ActivityMediaConfigRes>(active_shareMediaConfig, args, opts)
  const fetchShareSharingConfig = async (args: TokenParam, opts?) => generateRequestFunction<ActivitySharingConfigRes>(active_shareSharingConfig, args, opts)

  //跑马灯
  const fetchMarqueeStatus = async (args: TokenParam, opts?) => generateRequestFunction<ActivityMarqueeStatusRes>(active_marqueeStatus, args, opts)

  //首充
  const fetchFirstConfig = async (args: TokenParam, opts?) => generateRequestFunction<ActivityFirstConfigRes>(active_firstConfig, args, opts)

  //连续充值
  const fetchContinueRecharge = async (args: TokenParam, opts?) => generateRequestFunction<ContinuousRechargeRes>(active_continueRecharge, args, opts)

  //现金雨（撒钱）
  const fetchCashRainAnimation = async (args: { userId: string }, opts?) => generateRequestFunction<CashRainAnimationConfigRes>(active_cashRainAnimation, args, opts)
  const fetchCashIsReachStandard = async (args: TokenParam, opts?) => generateRequestFunction<CashRainIsReachStandardRes>(active_cashIsReachStandard, args, opts)
  const fetchCashRainPage = async (args: { userId: string }, opts?) => generateRequestFunction<CashRainPageConfigRes>(active_cashRainPage, args, opts)
  const fetchCashRainReceiveReward = async (args: TokenParam, opts?) => generateRequestFunction(active_cashRainReceiveReward, args, opts)

  //限时充值
  const fetchLimitTimeGetRewardConfig = async (args: TokenParam, opts?) => generateRequestFunction<LimitTimeRewardConfigRes>(active_limitTimeGetRewardConfig, args, opts)
  const fetchLimitTimeIsOpportunity = async (args: TokenParam, opts?) => generateRequestFunction<LimitTimeOpportunityFlagRes>(active_limitTimeIsOpportunity, args, opts)

  //新手任务72h
  const fetchNoviceTaskFollowMedia = async (args: TokenParam, opts?) => generateRequestFunction(active_noviceTaskFollowMedia, args, opts)
  const fetchNoviceTaskList = async (args: TokenParam, opts?) => generateRequestFunction<NoviceTaskListRes>(active_noviceTaskList, args, opts)
  const fetchNoviceTaskReceive = async (args: NewTaskReceive & TokenParam, opts?) => generateRequestFunction(active_noviceTaskReceive, args, opts)

  //周卡
  const fetchWeeklyCardCanGetReward = async (args: TokenParam, opts?) => generateRequestFunction<CardCanGetRewardRes>(active_weeklyCardCanGetReward, args, opts)
  const fetchWeeklyCardGetBonus = async (args: CardGetBonusRewardReq & TokenParam, opts?) => generateRequestFunction<CardGetBonusRes>(active_weeklyCardGetBonus, args, opts)
  const fetchWeeklyCardWeeklyCardConfig = async (args: WeeklyCardConfigReq & TokenParam, opts?) => generateRequestFunction<WeeklyCardConfigRes>(active_weeklyCardWeeklyCardConfig, args, opts)
  const fetchWeeklyCardWeeklyCardList = async (args: TokenParam, opts?) => generateRequestFunction<WeeklyCardListRes>(active_weeklyCardWeeklyCardList, args, opts)
  const fetchWeeklyCardWeeklyCardRewardDetail = async (args: TokenParam, opts?) => generateRequestFunction<CanWeeklyCardRewardDetailRes>(active_weeklyCardWeeklyCardRewardDetail, args, opts)

  //周流水排行
  const fetchWeeklyBetGetWeekBetSwitch = async (args: TokenParam, opts?) => generateRequestFunction<WeekBetSwitchRes>(active_weeklyBetGetWeekBetSwitch, args, opts)
  const fetchWeeklyBetMyRanking = async (args: TokenParam, opts?) => generateRequestFunction<WeekBetMyRankingRes>(active_weeklyBetMyRanking, args, opts)
  const fetchWeeklyBetQueryRankingReward = async (args: TokenParam, opts?) => generateRequestFunction<WeekBetQueryRankingRewardRes>(active_weeklyBetQueryRankingReward, args, opts)
  const fetchWeeklyBetQueryRankingList = async (args: TokenParam, opts?) => generateRequestFunction<WeekBetRankingListRes>(active_weeklyBetQueryRankingList, args, opts)
  const fetchWeeklyBetReceiveRankingReward = async (args: ReceiveRankingRewardReq & TokenParam, opts?) => generateRequestFunction(active_weeklyBetReceiveRankingReward, args, opts)

  //签到活动
  const fetchCheckIn = async (args: TokenParam, opts?) => generateRequestFunction(active_checkIn, args, opts)
  const fetchCheckInConfig = async (args: TokenParam, opts?) => generateRequestFunction(active_checkInConfig, args, opts)
  const fetchCheckInIsCheckIn = async (args: TokenParam, opts?) => generateRequestFunction(active_checkInIsCheckIn, args, opts)

  //VIP
  const fetchVipPrivilege = async (args: any = {}, opts?) => generateRequestFunction(active_vipConfig, args, opts)
  const fetchVipLossRebate = async (args: TokenParam, opts?) => generateRequestFunction(active_viplossRebate, args, opts)
  const fetchReceiveVipLossRebate = async (args: TokenParam&{id:string}, opts?) => generateRequestFunction(active_vipReceivelossRebate, args, opts)

  // 下载奖励
  const fetchRealPersonDetection = async (args: TokenParam, opts?) => generateRequestFunction(active_realPersonDetection, args, opts)

  return {
    //活动中心列表
    fetchActivityCenterList,
    fetchActivityCenterOpenList,
    fetchActivityCenterHomeInfo,
    //奖金中心
    fetchBonusCenterStatistical,
    fetchBonusCenterList,
    // 推广相关
    fetchPromoteRewardRecord,
    fetchPromoteRewardTotal,
    fetchPromoteRewardTotal2,
    fetchPromoteConfig,
    fetchPromotePromoteUrl,
    fetchPromoteGetReward,
    // 日常任务相关
    fetchTaskStatus,
    fetchTaskList,
    fetchTaskHasReward,
    fetchTaskReceiveAward,
    fetchTaskList123,
    // 转盘相关
    fetchTurntableConfig,
    fetchTurntableSpin,
    fetchTurntableRewardLogs,
    fetchTurntableSpinCount,
    fetchPromoteWalletPointGroup,
    fetchPromoteWalletPointLogs,
    // 充值分享
    fetchRechargeShare,
    fetchRechargeShareConfig,
    fetchRechargeShareGetBonus,
    fetchRechargeShareSharing,
    // 新手、每日抽奖
    fetchLotteryCurrentLotteryRecords,
    fetchLotteryDailyActivity,
    fetchLotteryGetActivityStatus,
    fetchLotteryGetLotteryStatus,
    fetchLotteryLottery,
    fetchLotteryNoviceActivity,
    fetchLotteryPage,
    fetchLotteryRecords,
    // 兑换码
    fetchExchangeCode,
    // 红包雨（足球）
    fetchRedPacketAreaList,
    fetchRedPacketConfig,
    fetchRedPacketHighlight,
    fetchRedPacketShoot,
    fetchRedPacketshootRecords,
    // 锦标赛
    fetchChampionshipPage,
    fetchChampionshipPopUp,
    fetchChampionshipRanking,
    fetchChampionshipReceive,
    fetchChampionshipJoinChampionship,
    // 媒体配置
    fetchShareMediaConfig,
    fetchShareSharingConfig,
    // 跑马灯
    fetchMarqueeStatus,
    // 首充
    fetchFirstConfig,
    // 连续充值
    fetchContinueRecharge,
    // 现金雨（撒钱）
    fetchCashRainAnimation,
    fetchCashIsReachStandard,
    fetchCashRainPage,
    fetchCashRainReceiveReward,
    // 限时充值
    fetchLimitTimeGetRewardConfig,
    fetchLimitTimeIsOpportunity,
    // 新手任务72h
    fetchNoviceTaskFollowMedia,
    fetchNoviceTaskList,
    fetchNoviceTaskReceive,
    // 周卡
    fetchWeeklyCardCanGetReward,
    fetchWeeklyCardGetBonus,
    fetchWeeklyCardWeeklyCardConfig,
    fetchWeeklyCardWeeklyCardList,
    fetchWeeklyCardWeeklyCardRewardDetail,
    // 周流水排行
    fetchWeeklyBetGetWeekBetSwitch,
    fetchWeeklyBetMyRanking,
    fetchWeeklyBetQueryRankingReward,
    fetchWeeklyBetQueryRankingList,
    fetchWeeklyBetReceiveRankingReward,
    // 签到活动
    fetchCheckIn,
    fetchCheckInConfig,
    fetchCheckInIsCheckIn,
    // VIP
    fetchVipPrivilege,
    fetchVipLossRebate,
    fetchReceiveVipLossRebate,
    // 下载奖励
    fetchRealPersonDetection
  }
}
