/* eslint-disable @typescript-eslint/no-unused-vars */
import { useWebSocket } from '@vueuse/core'
import { MsgEnum } from '~/types/xfun'
import { useBus } from '~/composables/xfun/bus'
import { useGlobalStore } from '~/stores/xfun-global'

let bus = useBus()

type Msg = {
  msgType: number
  msgData: string
}

export default defineNuxtPlugin((nuxtApp) => {
  let globalStore = useGlobalStore()

  // setInterval(() => {
  //   console.log('--->env', globalStore.data)
  // }, 100)

  const wsconfig = {
    server: globalStore.data.wsServerURL,
    base: '/push',
  }

  const createMsg = (msgType: number, msgData: any): string => {
    return JSON.stringify({
      msgType: msgType,
      msgData: msgData,
    })
  }

  const token = ref()
  const userId = ref()

  const onConnected = (ws: WebSocket) => {
    console.log('websocket connected')

    if (token.value && userId.value) {
      const msg: string = createMsg(MsgEnum.CONN, {
        token: token.value,
        userId: userId.value,
      })
      wsref.send(msg)
    }
  }

  const onDisconnected = (ws: WebSocket, event: CloseEvent) => {
    console.log('websocket disconnected')
    setTimeout(() => {
      wsref.open()
    }, 3000)
  }

  const onError = (ws: WebSocket, event: Event) => {
    console.log('websocket error')
  }

  const onMessage = (ws: WebSocket, event: MessageEvent) => {
    console.log('websocket receive message')
    let data = {}
    if (event.data == 'pong') {
      data = event.data
    } else if (event.data.indexOf('ver') == 0) {
      // 版本更新消息
      data = {
        msgType: 'version',
        msgData: event.data.split(":")[1]
      }
    } else {
      try {
        data = JSON.parse(event.data)
      }
      catch (err) {
        console.log('[error][onMessage]', err)
      }
    }
    bus.fireWsMessage.emit(data)
  }

  let wsref: any = useWebSocket(wsconfig.server + wsconfig.base, {
    immediate: false,
    heartbeat: {
      message: createMsg(MsgEnum.HEART_BEAT, 'ping'),
      interval: 1000 * 10,
      pongTimeout: 1000 * 10,
    },
    onConnected: onConnected,
    onDisconnected: onDisconnected,
    onError: onError,
    onMessage: onMessage,
  })

  const bindUser  = (_token: string, _userId: string) => {
    token.value = _token
    userId.value = _userId

    if (token.value && userId.value) {
      const msg: string = createMsg(MsgEnum.CONN, {
        token: token.value,
        userId: userId.value,
      })
      wsref.send(msg)
    }
  }

  const wsconnent = (_token: string, _userId: string) => {
    token.value = _token
    userId.value = _userId

    // const msg: string = createMsg(MsgEnum.CONN, {
    //     token: token.value,
    //     userId: userId.value
    // })
    // wsref.send(msg)

    console.log('--->wsconnent', wsconfig)
    wsref.open()
  }

  const msg_buffer: any = []

  const wssend = (data: any) => {
    try {
      msg_buffer.unshift(data)
      if (wsref.status.value == 'OPEN') {
        wsref.send(data)
        console.log('websocket send message ' + data + 'success')
      }
      msg_buffer.shift()
    } catch (e) {
      msg_buffer.push(data)
      console.log('websocket cache message ' + data)
    }
  }

  nuxtApp.provide('ws', {
    wsconnent,
    wssend,
    bindUser
  })
})
