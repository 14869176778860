import type { TokenParam } from "~/server/api/xfun/config"
import type { BalanceInfoRes, GameBetsReq, GameBetsRes, GameListReq, GameListRes, GameLoginReq, GameLoginRes, GameNameReq, GameNameRes, GameSearchReq, GameTrialReq, GameTrialRes, ModAvatarReq, ModNicknameReq, NoticeDetailReq, NoticeInfo, PayBriefListRes, PayConfigChannelReq, PayLogsReq, PayLogsRes, PayRechargeReq, PayRechargeRes, VipAuthsRes, VipLevelListRes, VipWithdrawDetailRes, WalletFundLogsReq, WalletInfoRes, AdvertisementStatsRes, AdvertisementRes, WalletQueryRewardListRes, WalletQueryRewardReq, PopupDataRes, CommonPayConfigRes, UserLoginTime, HomeHallRes, downLoadRewardRecordReq,PayConfigV2Res } from "~/types/xfun"
import { generateRequestFunction } from "./net"

const user_home_halls = `/game/info/game/hall`
const user_basedata = '/user/info/home/info'
const user_info = '/user/info/info'
const user_modavatar = '/user/info/modavatar'
const user_modnickname = '/user/info/modnickname'
const user_queryAvatar = '/user/info/queryAvatar'
const user_LoginTimes = '/user/info/loginTimes'
const user_viplevel = '/user/vip/levels'
const user_vipwithdrawdetail = '/user/vip/withdrawDetail'
const user_vipauths = '/user/vip/auths'
const user_vipconfig = '/user/vip/config'
const user_walletfundlogs = '/user/wallet/fundLogs'
const user_walletfundlogstotal = '/user/wallet/fundLogs/total'
const user_payexchange = '/pay/exchange/index'
const user_paygetlogs = '/pay/get/logs'
const user_paypaymentconfig = '/pay/payment/config'
const uesr_paypaymentconfigV2='/pay/payment/config/v2' //充值整合接口
const user_payrechargeindex = '/pay/recharge/index'
const user_paysetpayinfo = '/pay/set/pay/info'
const user_payorderbrief = '/pay/order/brief'
const user_payconfigrechargePage = '/pay/payment/config/rechargePage'
const user_paypaymentconfigchannel = '/pay/payment/config/channel'
const user_payhomepaymentconfig = '/pay/home/payment/config'
const user_payhomepaymentQuickList = '/pay/payment/config/getOriginalQuickList'
const user_gamebets = '/game/info/bets'
const user_gamesearch = '/game/info/search'
const user_game_list = '/game/info/games'
const user_gamecategorygames = '/game/info/category/games'
const user_gametopicgames = '/game/info/topic/games'
const user_gamelogin = '/game/game/login'
const user_gametrial = '/game/game/trial'
const user_gameunderscore = '/game/game/underScore'
const user_gamegamename = '/game/info/gameName'
const user_gamecollectsadd = '/game/info/collects/add'
const user_gamecollectscancel = '/game/info/collects/cancel'
const user_gamecollects = '/game/info/collects'
const user_gamehistory = '/game/info/history'
const user_gamemerchantTypes = '/game/info/merchantTypes'
const user_gametypeMerchants = '/game/info/typeMerchants'
const user_gameothertypeMerchants = '/game/info/other/typeMerchants'
const user_gamecollectsjudge = '/game/info/collects/judge'
const user_noticenoticeDetail = '/user/notice/noticeDetail'
const user_walletinfo = '/user/wallet/getWallet'
const user_walletgetBalance = '/user/wallet/getBalance'
const user_walletQueryReward = '/user/wallet/queryReward'
const user_notcieAdvertisement = '/user/notice/advertisement'
const user_popupsAdvertisement = '/user/module/popups'
const user_noticeAnnouncement = '/user/notice/announcement'

const user_gamemodelMerchants = '/game/info/modelMerchants'
const user_modelMerchantGameTypes = '/game/info/modelMerchantGameTypes'
const user_modelMerchants = '/game/info/modelMerchants'

const user_gameinfocategory = '/game/info/category'


const user_downloadrewardconfig = '/user/download/reward/config'
const user_downloadrewardsave = '/user/download/reward/save'

const user_downloadrealKey  = '/user/download/reward/realKey'

const user_redirect_dict = '/user/common/redirect/content'

/**
 * @description 用户相关的操作
 */
export const useUser = () => {
  const fetchUserHomeHalls = async (opts?) => generateRequestFunction<HomeHallRes>(user_home_halls, {}, opts)
  const fetchUserBase = async (args: TokenParam, opts?) => generateRequestFunction<UserBaseRes>(user_basedata, args, opts)
  const fetchUserInfo = async (args: TokenParam, opts?) => generateRequestFunction<UserInfoRes>(user_info, args, opts)
  const fetchModAvatar = async (args: ModAvatarReq & TokenParam, opts?) => generateRequestFunction(user_modavatar, args, opts)
  const fetchModNickname = async (args: ModNicknameReq & TokenParam, opts?) => generateRequestFunction(user_modnickname, args, opts)
  const fetchQueryAvatar = async (args: TokenParam, opts?) => generateRequestFunction<UserAvatarListRes>(user_queryAvatar, args, opts)
  const fetchUserLoginTimes = async (args: TokenParam, opts?) => generateRequestFunction<UserLoginTime>(user_LoginTimes, args, opts)
  const fetchNoticeDetail = async (args: NoticeDetailReq & TokenParam, opts?) => generateRequestFunction<NoticeDetailRes>(user_noticenoticeDetail, args, opts)
  const fetchVipLevels = async (args: TokenParam, opts?) => generateRequestFunction<VipLevelListRes>(user_viplevel, args, opts)
  const fetchVipWithdrawDetail = async (args: TokenParam, opts?) => generateRequestFunction<VipWithdrawDetailRes>(user_vipwithdrawdetail, args, opts)
  const fetchVipAuths = async (args: TokenParam, opts?) => generateRequestFunction<VipAuthsRes>(user_vipauths, args, opts)
  const fetchVipConfig = async (args: TokenParam, opts?) => generateRequestFunction<VipConfigRes>(user_vipconfig, args, opts)
  const fetchWalletFundLogs = async (args: WalletFundLogsReq & TokenParam, opts?) => generateRequestFunction<WalletFundLogsRes>(user_walletfundlogs, args, opts)
  const fetchWalletFundLogsTotal = async (args: WalletFundLogsReq & TokenParam, opts?) => generateRequestFunction<WalletFundLogsTotalRes>(user_walletfundlogstotal, args, opts)
  const fetchWalletInfo = async (args: TokenParam, opts?) => generateRequestFunction<WalletInfoRes>(user_walletinfo, args, opts)
  const fetchWalletBalance = async (args: TokenParam, opts?) => generateRequestFunction<BalanceInfoRes>(user_walletgetBalance, args, opts)
  const fetchWalletQueryReward = async (args: WalletQueryRewardReq & TokenParam, opts?) => generateRequestFunction<WalletQueryRewardListRes>(user_walletQueryReward, args, opts)
  const fetchPayExchange = async (args: PayExchangeReq & TokenParam, opts?) => generateRequestFunction(user_payexchange, args, opts)
  const fetchPayGetLogs = async (args: PayLogsReq & TokenParam, opts?) => generateRequestFunction<PayLogsRes>(user_paygetlogs, args, opts)
  const fetchPayPaymentConfig = async (args: TokenParam, opts?) => generateRequestFunction<PayConfigRes>(user_paypaymentconfig, args, opts)
  const fetchPayPaymentConfigV2 = async (args: TokenParam, opts?) => generateRequestFunction<PayConfigV2Res>(uesr_paypaymentconfigV2, args, opts)
  const fetchPayPaymentConfigChannel = async (args: PayConfigChannelReq & TokenParam, opts?) => generateRequestFunction<PayConfigRes>(user_paypaymentconfigchannel, args, opts)
  const fetchPayHomePaymentConfig = async (args: TokenParam, opts?) => generateRequestFunction<CommonPayConfigRes>(user_payhomepaymentconfig, args, opts)
  const fetchPayRechargeIndex = async (args: PayRechargeReq & TokenParam, opts?) => generateRequestFunction<PayRechargeRes>(user_payrechargeindex, args, opts)
  const fetchPaySetPayInfo = async (args: PaySetPayInfoReq & TokenParam, opts?) => generateRequestFunction(user_paysetpayinfo, args, opts)
  const fetchPayOrderBrief = async (args: TokenParam, opts?) => generateRequestFunction<PayBriefListRes>(user_payorderbrief, args, opts)
  const fetchPayConfigRechargePage = async (args: TokenParam, opts?) => generateRequestFunction<PayPageConfigRes>(user_payconfigrechargePage, args, opts)
  const fetchPayConfigQuickList = async (args: TokenParam, opts?) => generateRequestFunction<QuickRechargeConfigRes>(user_payhomepaymentQuickList, args, opts)

  const fetchGameothertypeMerchants = async (args: GameTypeMerchantsReq, opts?) => generateRequestFunction<GameTypeMerchantsResponse>(user_gameothertypeMerchants, args, opts)
  const fetchGametypeMerchants = async (args: GameTypeMerchantsReq, opts?) => generateRequestFunction<GameTypeMerchantsResponse>(user_gametypeMerchants, args, opts)

  const fetchGamemerchantTypes = async (args: MerchantTypesReq, opts?) => generateRequestFunction<MerchantListItem>(user_gamemerchantTypes, args, opts)
  const fetchGameBets = async (args: GameBetsReq & TokenParam, opts?) => generateRequestFunction<GameBetsRes>(user_gamebets, args, opts)
  const fetchGameSearch = async (args: GameSearchReq, opts?) => generateRequestFunction<GameListRes>(user_gamesearch, args, opts)
  const fetchGameList = async (args: GameListReq, opts?) => generateRequestFunction<GameListRes>(user_game_list, args, opts)
  const fetchGameCategoryGames = async (args: GameListReq, opts?) => generateRequestFunction<GameListRes>(user_gamecategorygames, args, opts)
  const fetchGameTopicGames = async (args: GameListReq, opts?) => generateRequestFunction<GameListRes>(user_gametopicgames, args, opts)
  const fetchGameLogin = async (args: GameLoginReq & TokenParam, opts?) => generateRequestFunction<GameLoginRes>(user_gamelogin, args, opts)
  const fetchGameTrial = async (args: GameTrialReq, opts?) => generateRequestFunction<GameTrialRes>(user_gametrial, args, opts)
  const fetchGameUnderScore = async (args: TokenParam, opts?) => generateRequestFunction<UnderScoreRes>(user_gameunderscore, args, opts)
  const fetchGameName = async (args: GameNameReq & TokenParam, opts?) => generateRequestFunction<GameNameRes>(user_gamegamename, args, opts)
  const fetchGameCollectsJudge = async (args: GameNameReq & TokenParam, opts?) => generateRequestFunction(user_gamecollectsjudge, args, opts)
  const fetchGameCollectsAdd = async (args: GameNameReq & TokenParam, opts?) => generateRequestFunction(user_gamecollectsadd, args, opts)
  const fetchGameCollectsCancel = async (args: GameNameReq & TokenParam, opts?) => generateRequestFunction(user_gamecollectscancel, args, opts)
  const fetchGameCollects = async (args: GameListReq & TokenParam, opts?) => generateRequestFunction<GameListRes>(user_gamecollects, args, opts)
  const fetchGameHistories = async (args: GameListReq & TokenParam, opts?) => generateRequestFunction<GameListRes>(user_gamehistory, args, opts)
  const fetchNotcieAdvertisement = async (args: TokenParam, opts?) => generateRequestFunction<AdvertisementStatsRes>(user_notcieAdvertisement, args, opts)
  const fetchPoPupsAdvertisement = async (args: TokenParam, opts?) => generateRequestFunction<PopupDataRes>(user_popupsAdvertisement, args, opts)
  const fetchNoticeAnnouncement = async (args: TokenParam, opts?) => generateRequestFunction<AdvertisementRes>(user_noticeAnnouncement, args, opts)

  // 厂家模块
  const fetchGamemodelMerchants = async (args: {modelId:string|number}, opts?) => generateRequestFunction<any>(user_gamemodelMerchants, args, opts)
  const fetchmodelMerchantGameTypes = async (args: {modelId:string|number, mid:string}, opts?) => generateRequestFunction<any>(user_modelMerchantGameTypes, args, opts)

  // 标签模块
  const fetchgameinfocategory = async (args: {modelId:string|number}, opts?) => generateRequestFunction<any>(user_gameinfocategory, args, opts)
  const fetchmodelMerchants = async (args: {modelId:string|number}, opts?) => generateRequestFunction<any>(user_modelMerchants, args, opts)
  
  // 下载奖励
  const fetchdownloadrewardconfig = async (opts?) => generateRequestFunction<any>(user_downloadrewardconfig, opts)
  const fetchdownloadrewardsave = async (args: TokenParam & downLoadRewardRecordReq, opts?) => generateRequestFunction<any>(user_downloadrewardsave, args, opts)
  const fetchdownloadrealKey = async (opts?) => generateRequestFunction<any>(user_downloadrealKey, {}, opts)

  // 跳转字典
  const fetchRedirectDict = async (opts?) => generateRequestFunction<any>(user_redirect_dict, opts)

  return {
    fetchUserHomeHalls,
    fetchUserBase,
    fetchUserInfo,
    fetchModAvatar,
    fetchModNickname,
    fetchQueryAvatar,
    fetchVipLevels,
    fetchVipWithdrawDetail,
    fetchUserLoginTimes,
    fetchVipAuths,
    fetchVipConfig,
    fetchWalletFundLogs,
    fetchWalletFundLogsTotal,
    fetchWalletInfo,
    fetchWalletBalance,
    fetchWalletQueryReward,
    fetchNoticeDetail,
    fetchPayExchange,
    fetchPayGetLogs,
    fetchPayPaymentConfig,
    fetchPayPaymentConfigV2,
    fetchPayRechargeIndex,
    fetchPaySetPayInfo,
    fetchPayOrderBrief,
    fetchPayConfigRechargePage,
    fetchPayHomePaymentConfig,
    fetchPayPaymentConfigChannel,
    fetchPayConfigQuickList,
    fetchGametypeMerchants,
    fetchGameothertypeMerchants,
    fetchGamemerchantTypes,
    fetchGameBets,
    fetchGameList,
    fetchGameSearch,
    fetchGameCategoryGames,
    fetchGameTopicGames,
    fetchGameLogin,
    fetchGameTrial,
    fetchGameUnderScore,
    fetchGameName,
    fetchGameCollectsJudge,
    fetchGameCollectsAdd,
    fetchGameCollectsCancel,
    fetchGameCollects,
    fetchGameHistories,
    fetchNotcieAdvertisement,
    fetchPoPupsAdvertisement,
    fetchNoticeAnnouncement,
    fetchGamemodelMerchants,
    fetchmodelMerchantGameTypes,
    fetchgameinfocategory,
    fetchmodelMerchants,
    fetchdownloadrewardconfig,
    fetchdownloadrewardsave,
    fetchdownloadrealKey,
    // 跳转字典
    fetchRedirectDict
  }
}
