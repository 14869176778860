import { default as Account9N8PySlc5MMeta } from "/Users/leo/nuxt_client/pages/Account.vue?macro=true";
import { default as ActivityCenterLV01Rw3jVCMeta } from "/Users/leo/nuxt_client/pages/ActivityCenter.vue?macro=true";
import { default as BetbyYKflfPLugZMeta } from "/Users/leo/nuxt_client/pages/Betby.vue?macro=true";
import { default as BetsHistorypwN27ILlrWMeta } from "/Users/leo/nuxt_client/pages/BetsHistory.vue?macro=true";
import { default as BindCPFxjEgDjnIdHMeta } from "/Users/leo/nuxt_client/pages/BindCPF.vue?macro=true";
import { default as BindEmaildCoOwgwG6nMeta } from "/Users/leo/nuxt_client/pages/BindEmail.vue?macro=true";
import { default as BindMobilezSb0xeqK06Meta } from "/Users/leo/nuxt_client/pages/BindMobile.vue?macro=true";
import { default as BonusCenterSvbYdcLjPJMeta } from "/Users/leo/nuxt_client/pages/BonusCenter.vue?macro=true";
import { default as BonusRecorde1ipMfpDw8Meta } from "/Users/leo/nuxt_client/pages/BonusRecord.vue?macro=true";
import { default as _91id_93mLmvR7lV2TMeta } from "/Users/leo/nuxt_client/pages/category/[id].vue?macro=true";
import { default as Championship1h3dKbOEE7Meta } from "/Users/leo/nuxt_client/pages/Championship.vue?macro=true";
import { default as ChampionshipRankjZdlju8mFTMeta } from "/Users/leo/nuxt_client/pages/ChampionshipRank.vue?macro=true";
import { default as ChangeAvatarnKBP9fshVXMeta } from "/Users/leo/nuxt_client/pages/ChangeAvatar.vue?macro=true";
import { default as CommissionhNo6Ss5dKZMeta } from "/Users/leo/nuxt_client/pages/Commission.vue?macro=true";
import { default as ContinuousRechargelJ9p1JmksQMeta } from "/Users/leo/nuxt_client/pages/ContinuousRecharge.vue?macro=true";
import { default as DailyTasksMdKmRzMIocMeta } from "/Users/leo/nuxt_client/pages/DailyTasks.vue?macro=true";
import { default as DailyTasksHistoryWtHhWAaA8HMeta } from "/Users/leo/nuxt_client/pages/DailyTasksHistory.vue?macro=true";
import { default as DepositpgonTryYzrMeta } from "/Users/leo/nuxt_client/pages/Deposit.vue?macro=true";
import { default as DepositActivityW8FR3cHISzMeta } from "/Users/leo/nuxt_client/pages/DepositActivity.vue?macro=true";
import { default as DepositPIXLAkikMruWsMeta } from "/Users/leo/nuxt_client/pages/DepositPIX.vue?macro=true";
import { default as DepositUSDTMjvRPXJSAJMeta } from "/Users/leo/nuxt_client/pages/DepositUSDT.vue?macro=true";
import { default as DepositViewhr8Oj64KsoMeta } from "/Users/leo/nuxt_client/pages/DepositView.vue?macro=true";
import { default as DownloadBootjR5j4dBlvRMeta } from "/Users/leo/nuxt_client/pages/DownloadBoot.vue?macro=true";
import { default as DownloadMergeURUR8tKmsJMeta } from "/Users/leo/nuxt_client/pages/DownloadMerge.vue?macro=true";
import { default as EditProfileUFwxzxt82wMeta } from "/Users/leo/nuxt_client/pages/EditProfile.vue?macro=true";
import { default as FirstChargecfJVL4VDyzMeta } from "/Users/leo/nuxt_client/pages/FirstCharge.vue?macro=true";
import { default as FirstChargePageVh0Y1V0ExHMeta } from "/Users/leo/nuxt_client/pages/FirstChargePage.vue?macro=true";
import { default as Forgot1rJ7U48lhxMeta } from "/Users/leo/nuxt_client/pages/Forgot.vue?macro=true";
import { default as GameDepositvmJejQbqgiMeta } from "/Users/leo/nuxt_client/pages/GameDeposit.vue?macro=true";
import { default as checkInQ13JIRWW13Meta } from "/Users/leo/nuxt_client/pages/index/checkIn.vue?macro=true";
import { default as shortcutyA39DDG7dpMeta } from "/Users/leo/nuxt_client/pages/index/shortcut.vue?macro=true";
import { default as slotsTdy0CHfyqPMeta } from "/Users/leo/nuxt_client/pages/index/slots.vue?macro=true";
import { default as slotsDaily0A1yL6GEpfMeta } from "/Users/leo/nuxt_client/pages/index/slotsDaily.vue?macro=true";
import { default as indexvhQ7P5nsbwMeta } from "/Users/leo/nuxt_client/pages/index.vue?macro=true";
import { default as LoginJpQdzHRkdxMeta } from "/Users/leo/nuxt_client/pages/Login.vue?macro=true";
import { default as LotteryDrawofcosERfqRMeta } from "/Users/leo/nuxt_client/pages/LotteryDraw.vue?macro=true";
import { default as LotteryDrawListk0OGy8m7BfMeta } from "/Users/leo/nuxt_client/pages/LotteryDrawList.vue?macro=true";
import { default as LotteryHelpTvSK5Z69ODMeta } from "/Users/leo/nuxt_client/pages/LotteryHelp.vue?macro=true";
import { default as McategoryFYz2uP7nb0Meta } from "/Users/leo/nuxt_client/pages/Mcategory.vue?macro=true";
import { default as MessageUdbprGEbr2Meta } from "/Users/leo/nuxt_client/pages/Message.vue?macro=true";
import { default as MessageDetail4FAv340VQdMeta } from "/Users/leo/nuxt_client/pages/MessageDetail.vue?macro=true";
import { default as MoneyHelpZt95CskfpvMeta } from "/Users/leo/nuxt_client/pages/MoneyHelp.vue?macro=true";
import { default as MProvidersmazt6axgXOMeta } from "/Users/leo/nuxt_client/pages/MProviders.vue?macro=true";
import { default as Mtypealtz5wlwVsMeta } from "/Users/leo/nuxt_client/pages/Mtype.vue?macro=true";
import { default as PromotionKysvbfUCCdMeta } from "/Users/leo/nuxt_client/pages/Promotion.vue?macro=true";
import { default as Providers4Wy8gD3a6zMeta } from "/Users/leo/nuxt_client/pages/Providers.vue?macro=true";
import { default as ReagrasvvpVzbWSCdMeta } from "/Users/leo/nuxt_client/pages/Reagras.vue?macro=true";
import { default as RedActivity52ciMv1rquMeta } from "/Users/leo/nuxt_client/pages/RedActivity.vue?macro=true";
import { default as RedemptionMhMNnfwF9tMeta } from "/Users/leo/nuxt_client/pages/Redemption.vue?macro=true";
import { default as RedPacketTB228AWnl2Meta } from "/Users/leo/nuxt_client/pages/RedPacket.vue?macro=true";
import { default as RedPacketList878ncAsCgwMeta } from "/Users/leo/nuxt_client/pages/RedPacketList.vue?macro=true";
import { default as RegisterydV9Mjyh2oMeta } from "/Users/leo/nuxt_client/pages/Register.vue?macro=true";
import { default as RouletteCeblw5r9SeMeta } from "/Users/leo/nuxt_client/pages/Roulette.vue?macro=true";
import { default as RouletteDetailXfXpn6o8xxMeta } from "/Users/leo/nuxt_client/pages/RouletteDetail.vue?macro=true";
import { default as RouletteHelpg1oZr82p9tMeta } from "/Users/leo/nuxt_client/pages/RouletteHelp.vue?macro=true";
import { default as RouletteLogYOkfe1rR9PMeta } from "/Users/leo/nuxt_client/pages/RouletteLog.vue?macro=true";
import { default as RouletteShareAiYGckwL0wMeta } from "/Users/leo/nuxt_client/pages/RouletteShare.vue?macro=true";
import { default as RunGameFasfJDY6ehMeta } from "/Users/leo/nuxt_client/pages/RunGame.vue?macro=true";
import { default as SharingRebatesVuS15I4zcoMeta } from "/Users/leo/nuxt_client/pages/SharingRebates.vue?macro=true";
import { default as SlotsListebXRwZ6THQMeta } from "/Users/leo/nuxt_client/pages/SlotsList.vue?macro=true";
import { default as SlotsShareivYcPvmk9CMeta } from "/Users/leo/nuxt_client/pages/SlotsShare.vue?macro=true";
import { default as testabcd6xi1KgKy4KMeta } from "/Users/leo/nuxt_client/pages/testabcd.vue?macro=true";
import { default as ThemeGqZnheFuY2Meta } from "/Users/leo/nuxt_client/pages/Theme.vue?macro=true";
import { default as TransactionYlz4NG9jSRMeta } from "/Users/leo/nuxt_client/pages/Transaction.vue?macro=true";
import { default as _91id_93DNOEnsFnhYMeta } from "/Users/leo/nuxt_client/pages/type/[id].vue?macro=true";
import { default as UserInfo1Vxg45Ssg5Meta } from "/Users/leo/nuxt_client/pages/UserInfo.vue?macro=true";
import { default as VipCashbackqMWvNT0tttMeta } from "/Users/leo/nuxt_client/pages/VipCashback.vue?macro=true";
import { default as VipLossRebatedVP4wcWemqMeta } from "/Users/leo/nuxt_client/pages/VipLossRebate.vue?macro=true";
import { default as VipNewPdqeoAXqhRMeta } from "/Users/leo/nuxt_client/pages/VipNew.vue?macro=true";
import { default as VipNewDetailaMuBwlLKx7Meta } from "/Users/leo/nuxt_client/pages/VipNewDetail.vue?macro=true";
import { default as WebviewaeqW2SbzwpMeta } from "/Users/leo/nuxt_client/pages/Webview.vue?macro=true";
import { default as WeeklyCard1yaVQ7r63JMeta } from "/Users/leo/nuxt_client/pages/WeeklyCard.vue?macro=true";
import { default as WeeklyList4n2N4G71jzMeta } from "/Users/leo/nuxt_client/pages/WeeklyList.vue?macro=true";
import { default as WithdrawalPIXSrNhgwvh6XMeta } from "/Users/leo/nuxt_client/pages/WithdrawalPIX.vue?macro=true";
export default [
  {
    name: Account9N8PySlc5MMeta?.name ?? "Account",
    path: Account9N8PySlc5MMeta?.path ?? "/Account",
    meta: Account9N8PySlc5MMeta || {},
    alias: Account9N8PySlc5MMeta?.alias || [],
    redirect: Account9N8PySlc5MMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Account.vue").then(m => m.default || m)
  },
  {
    name: ActivityCenterLV01Rw3jVCMeta?.name ?? "ActivityCenter",
    path: ActivityCenterLV01Rw3jVCMeta?.path ?? "/ActivityCenter",
    meta: ActivityCenterLV01Rw3jVCMeta || {},
    alias: ActivityCenterLV01Rw3jVCMeta?.alias || [],
    redirect: ActivityCenterLV01Rw3jVCMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/ActivityCenter.vue").then(m => m.default || m)
  },
  {
    name: BetbyYKflfPLugZMeta?.name ?? "Betby",
    path: BetbyYKflfPLugZMeta?.path ?? "/Betby",
    meta: BetbyYKflfPLugZMeta || {},
    alias: BetbyYKflfPLugZMeta?.alias || [],
    redirect: BetbyYKflfPLugZMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Betby.vue").then(m => m.default || m)
  },
  {
    name: BetsHistorypwN27ILlrWMeta?.name ?? "BetsHistory",
    path: BetsHistorypwN27ILlrWMeta?.path ?? "/BetsHistory",
    meta: BetsHistorypwN27ILlrWMeta || {},
    alias: BetsHistorypwN27ILlrWMeta?.alias || [],
    redirect: BetsHistorypwN27ILlrWMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/BetsHistory.vue").then(m => m.default || m)
  },
  {
    name: BindCPFxjEgDjnIdHMeta?.name ?? "BindCPF",
    path: BindCPFxjEgDjnIdHMeta?.path ?? "/BindCPF",
    meta: BindCPFxjEgDjnIdHMeta || {},
    alias: BindCPFxjEgDjnIdHMeta?.alias || [],
    redirect: BindCPFxjEgDjnIdHMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/BindCPF.vue").then(m => m.default || m)
  },
  {
    name: BindEmaildCoOwgwG6nMeta?.name ?? "BindEmail",
    path: BindEmaildCoOwgwG6nMeta?.path ?? "/BindEmail",
    meta: BindEmaildCoOwgwG6nMeta || {},
    alias: BindEmaildCoOwgwG6nMeta?.alias || [],
    redirect: BindEmaildCoOwgwG6nMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/BindEmail.vue").then(m => m.default || m)
  },
  {
    name: BindMobilezSb0xeqK06Meta?.name ?? "BindMobile",
    path: BindMobilezSb0xeqK06Meta?.path ?? "/BindMobile",
    meta: BindMobilezSb0xeqK06Meta || {},
    alias: BindMobilezSb0xeqK06Meta?.alias || [],
    redirect: BindMobilezSb0xeqK06Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/BindMobile.vue").then(m => m.default || m)
  },
  {
    name: BonusCenterSvbYdcLjPJMeta?.name ?? "BonusCenter",
    path: BonusCenterSvbYdcLjPJMeta?.path ?? "/BonusCenter",
    meta: BonusCenterSvbYdcLjPJMeta || {},
    alias: BonusCenterSvbYdcLjPJMeta?.alias || [],
    redirect: BonusCenterSvbYdcLjPJMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/BonusCenter.vue").then(m => m.default || m)
  },
  {
    name: BonusRecorde1ipMfpDw8Meta?.name ?? "BonusRecord",
    path: BonusRecorde1ipMfpDw8Meta?.path ?? "/BonusRecord",
    meta: BonusRecorde1ipMfpDw8Meta || {},
    alias: BonusRecorde1ipMfpDw8Meta?.alias || [],
    redirect: BonusRecorde1ipMfpDw8Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/BonusRecord.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mLmvR7lV2TMeta?.name ?? "category-id",
    path: _91id_93mLmvR7lV2TMeta?.path ?? "/category/:id()",
    meta: _91id_93mLmvR7lV2TMeta || {},
    alias: _91id_93mLmvR7lV2TMeta?.alias || [],
    redirect: _91id_93mLmvR7lV2TMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/category/[id].vue").then(m => m.default || m)
  },
  {
    name: Championship1h3dKbOEE7Meta?.name ?? "Championship",
    path: Championship1h3dKbOEE7Meta?.path ?? "/Championship",
    meta: Championship1h3dKbOEE7Meta || {},
    alias: Championship1h3dKbOEE7Meta?.alias || [],
    redirect: Championship1h3dKbOEE7Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Championship.vue").then(m => m.default || m)
  },
  {
    name: ChampionshipRankjZdlju8mFTMeta?.name ?? "ChampionshipRank",
    path: ChampionshipRankjZdlju8mFTMeta?.path ?? "/ChampionshipRank",
    meta: ChampionshipRankjZdlju8mFTMeta || {},
    alias: ChampionshipRankjZdlju8mFTMeta?.alias || [],
    redirect: ChampionshipRankjZdlju8mFTMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/ChampionshipRank.vue").then(m => m.default || m)
  },
  {
    name: ChangeAvatarnKBP9fshVXMeta?.name ?? "ChangeAvatar",
    path: ChangeAvatarnKBP9fshVXMeta?.path ?? "/ChangeAvatar",
    meta: ChangeAvatarnKBP9fshVXMeta || {},
    alias: ChangeAvatarnKBP9fshVXMeta?.alias || [],
    redirect: ChangeAvatarnKBP9fshVXMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/ChangeAvatar.vue").then(m => m.default || m)
  },
  {
    name: CommissionhNo6Ss5dKZMeta?.name ?? "Commission",
    path: CommissionhNo6Ss5dKZMeta?.path ?? "/Commission",
    meta: CommissionhNo6Ss5dKZMeta || {},
    alias: CommissionhNo6Ss5dKZMeta?.alias || [],
    redirect: CommissionhNo6Ss5dKZMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Commission.vue").then(m => m.default || m)
  },
  {
    name: ContinuousRechargelJ9p1JmksQMeta?.name ?? "ContinuousRecharge",
    path: ContinuousRechargelJ9p1JmksQMeta?.path ?? "/ContinuousRecharge",
    meta: ContinuousRechargelJ9p1JmksQMeta || {},
    alias: ContinuousRechargelJ9p1JmksQMeta?.alias || [],
    redirect: ContinuousRechargelJ9p1JmksQMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/ContinuousRecharge.vue").then(m => m.default || m)
  },
  {
    name: DailyTasksMdKmRzMIocMeta?.name ?? "DailyTasks",
    path: DailyTasksMdKmRzMIocMeta?.path ?? "/DailyTasks",
    meta: DailyTasksMdKmRzMIocMeta || {},
    alias: DailyTasksMdKmRzMIocMeta?.alias || [],
    redirect: DailyTasksMdKmRzMIocMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/DailyTasks.vue").then(m => m.default || m)
  },
  {
    name: DailyTasksHistoryWtHhWAaA8HMeta?.name ?? "DailyTasksHistory",
    path: DailyTasksHistoryWtHhWAaA8HMeta?.path ?? "/DailyTasksHistory",
    meta: DailyTasksHistoryWtHhWAaA8HMeta || {},
    alias: DailyTasksHistoryWtHhWAaA8HMeta?.alias || [],
    redirect: DailyTasksHistoryWtHhWAaA8HMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/DailyTasksHistory.vue").then(m => m.default || m)
  },
  {
    name: DepositpgonTryYzrMeta?.name ?? "Deposit",
    path: DepositpgonTryYzrMeta?.path ?? "/Deposit",
    meta: DepositpgonTryYzrMeta || {},
    alias: DepositpgonTryYzrMeta?.alias || [],
    redirect: DepositpgonTryYzrMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Deposit.vue").then(m => m.default || m)
  },
  {
    name: DepositActivityW8FR3cHISzMeta?.name ?? "DepositActivity",
    path: DepositActivityW8FR3cHISzMeta?.path ?? "/DepositActivity",
    meta: DepositActivityW8FR3cHISzMeta || {},
    alias: DepositActivityW8FR3cHISzMeta?.alias || [],
    redirect: DepositActivityW8FR3cHISzMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/DepositActivity.vue").then(m => m.default || m)
  },
  {
    name: DepositPIXLAkikMruWsMeta?.name ?? "DepositPIX",
    path: DepositPIXLAkikMruWsMeta?.path ?? "/DepositPIX",
    meta: DepositPIXLAkikMruWsMeta || {},
    alias: DepositPIXLAkikMruWsMeta?.alias || [],
    redirect: DepositPIXLAkikMruWsMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/DepositPIX.vue").then(m => m.default || m)
  },
  {
    name: DepositUSDTMjvRPXJSAJMeta?.name ?? "DepositUSDT",
    path: DepositUSDTMjvRPXJSAJMeta?.path ?? "/DepositUSDT",
    meta: DepositUSDTMjvRPXJSAJMeta || {},
    alias: DepositUSDTMjvRPXJSAJMeta?.alias || [],
    redirect: DepositUSDTMjvRPXJSAJMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/DepositUSDT.vue").then(m => m.default || m)
  },
  {
    name: DepositViewhr8Oj64KsoMeta?.name ?? "DepositView",
    path: DepositViewhr8Oj64KsoMeta?.path ?? "/DepositView",
    meta: DepositViewhr8Oj64KsoMeta || {},
    alias: DepositViewhr8Oj64KsoMeta?.alias || [],
    redirect: DepositViewhr8Oj64KsoMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/DepositView.vue").then(m => m.default || m)
  },
  {
    name: DownloadBootjR5j4dBlvRMeta?.name ?? "DownloadBoot",
    path: DownloadBootjR5j4dBlvRMeta?.path ?? "/DownloadBoot",
    meta: DownloadBootjR5j4dBlvRMeta || {},
    alias: DownloadBootjR5j4dBlvRMeta?.alias || [],
    redirect: DownloadBootjR5j4dBlvRMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/DownloadBoot.vue").then(m => m.default || m)
  },
  {
    name: DownloadMergeURUR8tKmsJMeta?.name ?? "DownloadMerge",
    path: DownloadMergeURUR8tKmsJMeta?.path ?? "/DownloadMerge",
    meta: DownloadMergeURUR8tKmsJMeta || {},
    alias: DownloadMergeURUR8tKmsJMeta?.alias || [],
    redirect: DownloadMergeURUR8tKmsJMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/DownloadMerge.vue").then(m => m.default || m)
  },
  {
    name: EditProfileUFwxzxt82wMeta?.name ?? "EditProfile",
    path: EditProfileUFwxzxt82wMeta?.path ?? "/EditProfile",
    meta: EditProfileUFwxzxt82wMeta || {},
    alias: EditProfileUFwxzxt82wMeta?.alias || [],
    redirect: EditProfileUFwxzxt82wMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/EditProfile.vue").then(m => m.default || m)
  },
  {
    name: FirstChargecfJVL4VDyzMeta?.name ?? "FirstCharge",
    path: FirstChargecfJVL4VDyzMeta?.path ?? "/FirstCharge",
    meta: FirstChargecfJVL4VDyzMeta || {},
    alias: FirstChargecfJVL4VDyzMeta?.alias || [],
    redirect: FirstChargecfJVL4VDyzMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/FirstCharge.vue").then(m => m.default || m)
  },
  {
    name: FirstChargePageVh0Y1V0ExHMeta?.name ?? "FirstChargePage",
    path: FirstChargePageVh0Y1V0ExHMeta?.path ?? "/FirstChargePage",
    meta: FirstChargePageVh0Y1V0ExHMeta || {},
    alias: FirstChargePageVh0Y1V0ExHMeta?.alias || [],
    redirect: FirstChargePageVh0Y1V0ExHMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/FirstChargePage.vue").then(m => m.default || m)
  },
  {
    name: Forgot1rJ7U48lhxMeta?.name ?? "Forgot",
    path: Forgot1rJ7U48lhxMeta?.path ?? "/Forgot",
    meta: Forgot1rJ7U48lhxMeta || {},
    alias: Forgot1rJ7U48lhxMeta?.alias || [],
    redirect: Forgot1rJ7U48lhxMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Forgot.vue").then(m => m.default || m)
  },
  {
    name: GameDepositvmJejQbqgiMeta?.name ?? "GameDeposit",
    path: GameDepositvmJejQbqgiMeta?.path ?? "/GameDeposit",
    meta: GameDepositvmJejQbqgiMeta || {},
    alias: GameDepositvmJejQbqgiMeta?.alias || [],
    redirect: GameDepositvmJejQbqgiMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/GameDeposit.vue").then(m => m.default || m)
  },
  {
    name: indexvhQ7P5nsbwMeta?.name ?? "index",
    path: indexvhQ7P5nsbwMeta?.path ?? "/",
    meta: indexvhQ7P5nsbwMeta || {},
    alias: indexvhQ7P5nsbwMeta?.alias || [],
    redirect: indexvhQ7P5nsbwMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: checkInQ13JIRWW13Meta?.name ?? "index-checkIn",
    path: checkInQ13JIRWW13Meta?.path ?? "checkIn",
    meta: checkInQ13JIRWW13Meta || {},
    alias: checkInQ13JIRWW13Meta?.alias || [],
    redirect: checkInQ13JIRWW13Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/index/checkIn.vue").then(m => m.default || m)
  },
  {
    name: shortcutyA39DDG7dpMeta?.name ?? "index-shortcut",
    path: shortcutyA39DDG7dpMeta?.path ?? "shortcut",
    meta: shortcutyA39DDG7dpMeta || {},
    alias: shortcutyA39DDG7dpMeta?.alias || [],
    redirect: shortcutyA39DDG7dpMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/index/shortcut.vue").then(m => m.default || m)
  },
  {
    name: slotsTdy0CHfyqPMeta?.name ?? "index-slots",
    path: slotsTdy0CHfyqPMeta?.path ?? "slots",
    meta: slotsTdy0CHfyqPMeta || {},
    alias: slotsTdy0CHfyqPMeta?.alias || [],
    redirect: slotsTdy0CHfyqPMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/index/slots.vue").then(m => m.default || m)
  },
  {
    name: slotsDaily0A1yL6GEpfMeta?.name ?? "index-slotsDaily",
    path: slotsDaily0A1yL6GEpfMeta?.path ?? "slotsDaily",
    meta: slotsDaily0A1yL6GEpfMeta || {},
    alias: slotsDaily0A1yL6GEpfMeta?.alias || [],
    redirect: slotsDaily0A1yL6GEpfMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/index/slotsDaily.vue").then(m => m.default || m)
  }
]
  },
  {
    name: LoginJpQdzHRkdxMeta?.name ?? "Login",
    path: LoginJpQdzHRkdxMeta?.path ?? "/Login",
    meta: LoginJpQdzHRkdxMeta || {},
    alias: LoginJpQdzHRkdxMeta?.alias || [],
    redirect: LoginJpQdzHRkdxMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Login.vue").then(m => m.default || m)
  },
  {
    name: LotteryDrawofcosERfqRMeta?.name ?? "LotteryDraw",
    path: LotteryDrawofcosERfqRMeta?.path ?? "/LotteryDraw",
    meta: LotteryDrawofcosERfqRMeta || {},
    alias: LotteryDrawofcosERfqRMeta?.alias || [],
    redirect: LotteryDrawofcosERfqRMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/LotteryDraw.vue").then(m => m.default || m)
  },
  {
    name: LotteryDrawListk0OGy8m7BfMeta?.name ?? "LotteryDrawList",
    path: LotteryDrawListk0OGy8m7BfMeta?.path ?? "/LotteryDrawList",
    meta: LotteryDrawListk0OGy8m7BfMeta || {},
    alias: LotteryDrawListk0OGy8m7BfMeta?.alias || [],
    redirect: LotteryDrawListk0OGy8m7BfMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/LotteryDrawList.vue").then(m => m.default || m)
  },
  {
    name: LotteryHelpTvSK5Z69ODMeta?.name ?? "LotteryHelp",
    path: LotteryHelpTvSK5Z69ODMeta?.path ?? "/LotteryHelp",
    meta: LotteryHelpTvSK5Z69ODMeta || {},
    alias: LotteryHelpTvSK5Z69ODMeta?.alias || [],
    redirect: LotteryHelpTvSK5Z69ODMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/LotteryHelp.vue").then(m => m.default || m)
  },
  {
    name: McategoryFYz2uP7nb0Meta?.name ?? "Mcategory",
    path: McategoryFYz2uP7nb0Meta?.path ?? "/Mcategory",
    meta: McategoryFYz2uP7nb0Meta || {},
    alias: McategoryFYz2uP7nb0Meta?.alias || [],
    redirect: McategoryFYz2uP7nb0Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Mcategory.vue").then(m => m.default || m)
  },
  {
    name: MessageUdbprGEbr2Meta?.name ?? "Message",
    path: MessageUdbprGEbr2Meta?.path ?? "/Message",
    meta: MessageUdbprGEbr2Meta || {},
    alias: MessageUdbprGEbr2Meta?.alias || [],
    redirect: MessageUdbprGEbr2Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Message.vue").then(m => m.default || m)
  },
  {
    name: MessageDetail4FAv340VQdMeta?.name ?? "MessageDetail",
    path: MessageDetail4FAv340VQdMeta?.path ?? "/MessageDetail",
    meta: MessageDetail4FAv340VQdMeta || {},
    alias: MessageDetail4FAv340VQdMeta?.alias || [],
    redirect: MessageDetail4FAv340VQdMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/MessageDetail.vue").then(m => m.default || m)
  },
  {
    name: MoneyHelpZt95CskfpvMeta?.name ?? "MoneyHelp",
    path: MoneyHelpZt95CskfpvMeta?.path ?? "/MoneyHelp",
    meta: MoneyHelpZt95CskfpvMeta || {},
    alias: MoneyHelpZt95CskfpvMeta?.alias || [],
    redirect: MoneyHelpZt95CskfpvMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/MoneyHelp.vue").then(m => m.default || m)
  },
  {
    name: MProvidersmazt6axgXOMeta?.name ?? "MProviders",
    path: MProvidersmazt6axgXOMeta?.path ?? "/MProviders",
    meta: MProvidersmazt6axgXOMeta || {},
    alias: MProvidersmazt6axgXOMeta?.alias || [],
    redirect: MProvidersmazt6axgXOMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/MProviders.vue").then(m => m.default || m)
  },
  {
    name: Mtypealtz5wlwVsMeta?.name ?? "Mtype",
    path: Mtypealtz5wlwVsMeta?.path ?? "/Mtype",
    meta: Mtypealtz5wlwVsMeta || {},
    alias: Mtypealtz5wlwVsMeta?.alias || [],
    redirect: Mtypealtz5wlwVsMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Mtype.vue").then(m => m.default || m)
  },
  {
    name: PromotionKysvbfUCCdMeta?.name ?? "Promotion",
    path: PromotionKysvbfUCCdMeta?.path ?? "/Promotion",
    meta: PromotionKysvbfUCCdMeta || {},
    alias: PromotionKysvbfUCCdMeta?.alias || [],
    redirect: PromotionKysvbfUCCdMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Promotion.vue").then(m => m.default || m)
  },
  {
    name: Providers4Wy8gD3a6zMeta?.name ?? "Providers",
    path: Providers4Wy8gD3a6zMeta?.path ?? "/Providers",
    meta: Providers4Wy8gD3a6zMeta || {},
    alias: Providers4Wy8gD3a6zMeta?.alias || [],
    redirect: Providers4Wy8gD3a6zMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Providers.vue").then(m => m.default || m)
  },
  {
    name: ReagrasvvpVzbWSCdMeta?.name ?? "Reagras",
    path: ReagrasvvpVzbWSCdMeta?.path ?? "/Reagras",
    meta: ReagrasvvpVzbWSCdMeta || {},
    alias: ReagrasvvpVzbWSCdMeta?.alias || [],
    redirect: ReagrasvvpVzbWSCdMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Reagras.vue").then(m => m.default || m)
  },
  {
    name: RedActivity52ciMv1rquMeta?.name ?? "RedActivity",
    path: RedActivity52ciMv1rquMeta?.path ?? "/RedActivity",
    meta: RedActivity52ciMv1rquMeta || {},
    alias: RedActivity52ciMv1rquMeta?.alias || [],
    redirect: RedActivity52ciMv1rquMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/RedActivity.vue").then(m => m.default || m)
  },
  {
    name: RedemptionMhMNnfwF9tMeta?.name ?? "Redemption",
    path: RedemptionMhMNnfwF9tMeta?.path ?? "/Redemption",
    meta: RedemptionMhMNnfwF9tMeta || {},
    alias: RedemptionMhMNnfwF9tMeta?.alias || [],
    redirect: RedemptionMhMNnfwF9tMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Redemption.vue").then(m => m.default || m)
  },
  {
    name: RedPacketTB228AWnl2Meta?.name ?? "RedPacket",
    path: RedPacketTB228AWnl2Meta?.path ?? "/RedPacket",
    meta: RedPacketTB228AWnl2Meta || {},
    alias: RedPacketTB228AWnl2Meta?.alias || [],
    redirect: RedPacketTB228AWnl2Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/RedPacket.vue").then(m => m.default || m)
  },
  {
    name: RedPacketList878ncAsCgwMeta?.name ?? "RedPacketList",
    path: RedPacketList878ncAsCgwMeta?.path ?? "/RedPacketList",
    meta: RedPacketList878ncAsCgwMeta || {},
    alias: RedPacketList878ncAsCgwMeta?.alias || [],
    redirect: RedPacketList878ncAsCgwMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/RedPacketList.vue").then(m => m.default || m)
  },
  {
    name: RegisterydV9Mjyh2oMeta?.name ?? "Register",
    path: RegisterydV9Mjyh2oMeta?.path ?? "/Register",
    meta: RegisterydV9Mjyh2oMeta || {},
    alias: RegisterydV9Mjyh2oMeta?.alias || [],
    redirect: RegisterydV9Mjyh2oMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Register.vue").then(m => m.default || m)
  },
  {
    name: RouletteCeblw5r9SeMeta?.name ?? "Roulette",
    path: RouletteCeblw5r9SeMeta?.path ?? "/Roulette",
    meta: RouletteCeblw5r9SeMeta || {},
    alias: RouletteCeblw5r9SeMeta?.alias || [],
    redirect: RouletteCeblw5r9SeMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Roulette.vue").then(m => m.default || m)
  },
  {
    name: RouletteDetailXfXpn6o8xxMeta?.name ?? "RouletteDetail",
    path: RouletteDetailXfXpn6o8xxMeta?.path ?? "/RouletteDetail",
    meta: RouletteDetailXfXpn6o8xxMeta || {},
    alias: RouletteDetailXfXpn6o8xxMeta?.alias || [],
    redirect: RouletteDetailXfXpn6o8xxMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/RouletteDetail.vue").then(m => m.default || m)
  },
  {
    name: RouletteHelpg1oZr82p9tMeta?.name ?? "RouletteHelp",
    path: RouletteHelpg1oZr82p9tMeta?.path ?? "/RouletteHelp",
    meta: RouletteHelpg1oZr82p9tMeta || {},
    alias: RouletteHelpg1oZr82p9tMeta?.alias || [],
    redirect: RouletteHelpg1oZr82p9tMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/RouletteHelp.vue").then(m => m.default || m)
  },
  {
    name: RouletteLogYOkfe1rR9PMeta?.name ?? "RouletteLog",
    path: RouletteLogYOkfe1rR9PMeta?.path ?? "/RouletteLog",
    meta: RouletteLogYOkfe1rR9PMeta || {},
    alias: RouletteLogYOkfe1rR9PMeta?.alias || [],
    redirect: RouletteLogYOkfe1rR9PMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/RouletteLog.vue").then(m => m.default || m)
  },
  {
    name: RouletteShareAiYGckwL0wMeta?.name ?? "RouletteShare",
    path: RouletteShareAiYGckwL0wMeta?.path ?? "/RouletteShare",
    meta: RouletteShareAiYGckwL0wMeta || {},
    alias: RouletteShareAiYGckwL0wMeta?.alias || [],
    redirect: RouletteShareAiYGckwL0wMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/RouletteShare.vue").then(m => m.default || m)
  },
  {
    name: RunGameFasfJDY6ehMeta?.name ?? "RunGame",
    path: RunGameFasfJDY6ehMeta?.path ?? "/RunGame",
    meta: RunGameFasfJDY6ehMeta || {},
    alias: RunGameFasfJDY6ehMeta?.alias || [],
    redirect: RunGameFasfJDY6ehMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/RunGame.vue").then(m => m.default || m)
  },
  {
    name: SharingRebatesVuS15I4zcoMeta?.name ?? "SharingRebates",
    path: SharingRebatesVuS15I4zcoMeta?.path ?? "/SharingRebates",
    meta: SharingRebatesVuS15I4zcoMeta || {},
    alias: SharingRebatesVuS15I4zcoMeta?.alias || [],
    redirect: SharingRebatesVuS15I4zcoMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/SharingRebates.vue").then(m => m.default || m)
  },
  {
    name: SlotsListebXRwZ6THQMeta?.name ?? "SlotsList",
    path: SlotsListebXRwZ6THQMeta?.path ?? "/SlotsList",
    meta: SlotsListebXRwZ6THQMeta || {},
    alias: SlotsListebXRwZ6THQMeta?.alias || [],
    redirect: SlotsListebXRwZ6THQMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/SlotsList.vue").then(m => m.default || m)
  },
  {
    name: SlotsShareivYcPvmk9CMeta?.name ?? "SlotsShare",
    path: SlotsShareivYcPvmk9CMeta?.path ?? "/SlotsShare",
    meta: SlotsShareivYcPvmk9CMeta || {},
    alias: SlotsShareivYcPvmk9CMeta?.alias || [],
    redirect: SlotsShareivYcPvmk9CMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/SlotsShare.vue").then(m => m.default || m)
  },
  {
    name: testabcd6xi1KgKy4KMeta?.name ?? "testabcd",
    path: testabcd6xi1KgKy4KMeta?.path ?? "/testabcd",
    meta: testabcd6xi1KgKy4KMeta || {},
    alias: testabcd6xi1KgKy4KMeta?.alias || [],
    redirect: testabcd6xi1KgKy4KMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/testabcd.vue").then(m => m.default || m)
  },
  {
    name: ThemeGqZnheFuY2Meta?.name ?? "Theme",
    path: ThemeGqZnheFuY2Meta?.path ?? "/Theme",
    meta: ThemeGqZnheFuY2Meta || {},
    alias: ThemeGqZnheFuY2Meta?.alias || [],
    redirect: ThemeGqZnheFuY2Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Theme.vue").then(m => m.default || m)
  },
  {
    name: TransactionYlz4NG9jSRMeta?.name ?? "Transaction",
    path: TransactionYlz4NG9jSRMeta?.path ?? "/Transaction",
    meta: TransactionYlz4NG9jSRMeta || {},
    alias: TransactionYlz4NG9jSRMeta?.alias || [],
    redirect: TransactionYlz4NG9jSRMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Transaction.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DNOEnsFnhYMeta?.name ?? "type-id",
    path: _91id_93DNOEnsFnhYMeta?.path ?? "/type/:id()",
    meta: _91id_93DNOEnsFnhYMeta || {},
    alias: _91id_93DNOEnsFnhYMeta?.alias || [],
    redirect: _91id_93DNOEnsFnhYMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/type/[id].vue").then(m => m.default || m)
  },
  {
    name: UserInfo1Vxg45Ssg5Meta?.name ?? "UserInfo",
    path: UserInfo1Vxg45Ssg5Meta?.path ?? "/UserInfo",
    meta: UserInfo1Vxg45Ssg5Meta || {},
    alias: UserInfo1Vxg45Ssg5Meta?.alias || [],
    redirect: UserInfo1Vxg45Ssg5Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/UserInfo.vue").then(m => m.default || m)
  },
  {
    name: VipCashbackqMWvNT0tttMeta?.name ?? "VipCashback",
    path: VipCashbackqMWvNT0tttMeta?.path ?? "/VipCashback",
    meta: VipCashbackqMWvNT0tttMeta || {},
    alias: VipCashbackqMWvNT0tttMeta?.alias || [],
    redirect: VipCashbackqMWvNT0tttMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/VipCashback.vue").then(m => m.default || m)
  },
  {
    name: VipLossRebatedVP4wcWemqMeta?.name ?? "VipLossRebate",
    path: VipLossRebatedVP4wcWemqMeta?.path ?? "/VipLossRebate",
    meta: VipLossRebatedVP4wcWemqMeta || {},
    alias: VipLossRebatedVP4wcWemqMeta?.alias || [],
    redirect: VipLossRebatedVP4wcWemqMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/VipLossRebate.vue").then(m => m.default || m)
  },
  {
    name: VipNewPdqeoAXqhRMeta?.name ?? "VipNew",
    path: VipNewPdqeoAXqhRMeta?.path ?? "/VipNew",
    meta: VipNewPdqeoAXqhRMeta || {},
    alias: VipNewPdqeoAXqhRMeta?.alias || [],
    redirect: VipNewPdqeoAXqhRMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/VipNew.vue").then(m => m.default || m)
  },
  {
    name: VipNewDetailaMuBwlLKx7Meta?.name ?? "VipNewDetail",
    path: VipNewDetailaMuBwlLKx7Meta?.path ?? "/VipNewDetail",
    meta: VipNewDetailaMuBwlLKx7Meta || {},
    alias: VipNewDetailaMuBwlLKx7Meta?.alias || [],
    redirect: VipNewDetailaMuBwlLKx7Meta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/VipNewDetail.vue").then(m => m.default || m)
  },
  {
    name: WebviewaeqW2SbzwpMeta?.name ?? "Webview",
    path: WebviewaeqW2SbzwpMeta?.path ?? "/Webview",
    meta: WebviewaeqW2SbzwpMeta || {},
    alias: WebviewaeqW2SbzwpMeta?.alias || [],
    redirect: WebviewaeqW2SbzwpMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/Webview.vue").then(m => m.default || m)
  },
  {
    name: WeeklyCard1yaVQ7r63JMeta?.name ?? "WeeklyCard",
    path: WeeklyCard1yaVQ7r63JMeta?.path ?? "/WeeklyCard",
    meta: WeeklyCard1yaVQ7r63JMeta || {},
    alias: WeeklyCard1yaVQ7r63JMeta?.alias || [],
    redirect: WeeklyCard1yaVQ7r63JMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/WeeklyCard.vue").then(m => m.default || m)
  },
  {
    name: WeeklyList4n2N4G71jzMeta?.name ?? "WeeklyList",
    path: WeeklyList4n2N4G71jzMeta?.path ?? "/WeeklyList",
    meta: WeeklyList4n2N4G71jzMeta || {},
    alias: WeeklyList4n2N4G71jzMeta?.alias || [],
    redirect: WeeklyList4n2N4G71jzMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/WeeklyList.vue").then(m => m.default || m)
  },
  {
    name: WithdrawalPIXSrNhgwvh6XMeta?.name ?? "WithdrawalPIX",
    path: WithdrawalPIXSrNhgwvh6XMeta?.path ?? "/WithdrawalPIX",
    meta: WithdrawalPIXSrNhgwvh6XMeta || {},
    alias: WithdrawalPIXSrNhgwvh6XMeta?.alias || [],
    redirect: WithdrawalPIXSrNhgwvh6XMeta?.redirect,
    component: () => import("/Users/leo/nuxt_client/pages/WithdrawalPIX.vue").then(m => m.default || m)
  }
]