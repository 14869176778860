import * as directives from 'vuetify/directives'
import {VBtn} from 'vuetify/components/VBtn'
import * as labsComponents from 'vuetify/labs/components'
import {VDatePicker} from 'vuetify/components/VDatePicker'

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"theme":{"defaultTheme":"light","themes":{"light":{"dark":false,"colors":{"background":"#FFFFFF","surface":"#FFFFFF","surface-bright":"#FFFFFF","surface-variant":"#424242","on-surface-variant":"#EEEEEE","primary":"#1867C0","primary-darken-1":"#1F5592","secondary":"#48A9A6","secondary-darken-1":"#018786","error":"#B00020","info":"#2196F3","success":"#4CAF50","warning":"#FB8C00"},"variables":{}},"dark":{"dark":true,"colors":{"background":"#191a2e","surface":"#1E1E2A","surface-bright":"#1E1E2A","surface-variant":"#A6A6BD","on-surface-variant":"#1E1E2A","primary":"#503FE4","primary-darken-1":"#E4B384","primary-2":"#FFFFFF","secondary":"#A6A6BD","secondary-darken-1":"#707274","secondary-2":"#09EAA0","secondary-3":"rgba(79, 64, 227, 0.2)","secondary-4":"#7669F5","weak":"rgba(255, 255, 255, 1)","weak-1":"#1a1b2f","weak-2":"rgba(147, 147, 147, 0.5)","weak-3":"#262837","error":"#DB5E3A","info":"#169AE5","success":"#09EAA0","warning":"#EABD31"},"variables":{"border-color":"#505363","border-opacity":0.12,"high-emphasis-opacity":1,"medium-emphasis-opacity":0.7,"disabled-opacity":0.5,"idle-opacity":0.1,"hover-opacity":0,"focus-opacity":0.12,"selected-opacity":0.08,"activated-opacity":0.12,"pressed-opacity":0.16,"dragged-opacity":0.08,"theme-kbd":"#212529","theme-on-kbd":"#FFFFFF","theme-code":"#343434","theme-on-code":"#CCCCCC","default-text-color":"#FFFFFF","color1":"#F5F1D0","color2":"#E4B384","color3":"#CC9F58","color4":"#A88161","color5":"#F9F36F","color6":"#EABD31","color7":"#B48433","color8":"#8F632E","color9":"#09EAA0","color10":"#0EAE70","color11":"#0B4031","color12":"#01240F","color13":"#169AE5","color14":"#2665A5","color15":"#DB5E3A","color16":"#A85539"}}}},"defaults":{"VImg":{"transition":false}},"display":{"mobileBreakpoint":"xs","thresholds":{"xs":0,"sm":640,"md":768,"lg":1024,"xl":1280,"xxl":1536}},"ssr":{"clientWidth":100}}')
  options.directives = directives
  options.aliases = {'VBtnSquare': VBtn,'VIconBtn': VBtn}
  options.components = {VDatePicker,...labsComponents}
  
  return options
}

