
import { useBus } from '~/composables/xfun/bus'

export default defineNuxtRouteMiddleware(async(to, from) => {
    // console.log("to:", to);
    // console.log("from:", from);
    let bus = useBus()
    let userStore = useUserStore()

    if (to.meta.auth && userStore.isLogin == false) {
        return navigateTo({ path: "/login"})
    }

    if (to.name == "index-slotsDaily"||to.name == "index-slots"||to.name == "index-shortcut" || to.name == "index-checkIn" ) {
        setTimeout(() => {
            bus.fireopendialog.emit()    
        }, 500);
    }

   
    if(to.name=="index"&&from.name=="Webview"){
       await userStore.wallet_get_balance_actions()
    }
    if(to.name=="DepositPIX"&&from.name=="Webview"){
        let promiseArr=[userStore.wallet_get_balance_actions(),userStore.user_info_actions()]
        await Promise.all(promiseArr)   
     }
    if (to.name == "index-a" ) {
        setTimeout(() => {
            bus.fireopendialog.emit()    
        }, 500);
    }
})
