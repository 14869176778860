// import devtools from '@vue/devtools'

export default defineNuxtPlugin(() => {
  // if (process.env.NODE_ENV === 'development') {
  //   // devtools.connect(/* host, port */)
  //   // (window as any) = devtools
  //   // @ts-ignore
  //   window.devtoolsKey = devtools
  // }
})
