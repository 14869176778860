import type { H3Event } from "h3"

export const useXCookie = () => {
  const getServerCookie = (event: H3Event, k: string) => {
    return getCookie(event, k)
  }

  const setServerCookie = (event: H3Event, k: string, v: string,opts?) => {
    if (!opts) opts = {
      maxAge: 365 * 24 * 3600, // 1年
      path: '/',
      httpOnly: false,
      sameSite: true
    }
    setCookie(event, k, v,opts)
  }

  const getClientCookie = (k: string, opts?) => {
    let cookie = useCookie(k, opts)
    return cookie.value
  }

  const setClientCookie = (k: string, v: string, opts?) => {
    if (!opts) opts = {
      maxAge: 365 * 24 * 3600, // 1年
      path: '/',
      httpOnly: false,
      sameSite: true
    }
    let cookie = useCookie(k, opts)
    cookie.value = v
  }

  return {
    getServerCookie,
    setServerCookie,
    getClientCookie,
    setClientCookie
  }
}
