import { defineStore } from 'pinia'
import { useCommon } from '~/composables/xfun/common'
import type { HomeHallList } from '~/types/xfun'

export const useCommonStore = defineStore('xfun-common', () => {

  let commonApi = useCommon()
  let userStore = useUserStore()

  /** 界面相关 */
  // 大厅
  const halls = ref<HomeHallList>([])
  // 轮播
  const carousels = ref<CarouselInfo[]>([])
  // 公告弹窗
  const moduleAnnouncement = ref<AnnouncementInfo[]>([])
  // 底部信息
  const bottomInfo = ref<BottomInfo>({
    mediumList: [],
    manufacturerList: []
  })
  // 渠道信息
  const channelInfo = ref<ChannelInfo>({
    id: 0,
    platId: 0,
    name: '',
    agentId: 0,
    code: '',
    activityConfigId: 0,
    gameConfigId: 0,
    rechargeConfigId: 0,
    apkUrl: '',
    iosUrl: '',
    pixelIdGoogle: '',
    pixelIdTiktok: '',
    pixelIdKwai: '',
    pixelIdFb: '',
    promoteType: 0,
    apkStyleId: 0,
    status: 0,
    createTime: 0
  })
  // Apk下载信息
  const apkDownloadInfo = ref<ApkDownloadInfo>({
    iosUrl: "",
    apkUrl: ""
  })
  // 侧滑菜单
  const menuInfo = ref<MenuInfo>({
    menuPageList: [],
    redirect: []
  })
  // 注册弹窗轮播图
  const registerBannerInfo = ref<RegisterBannerInfo>({
    status: 0,
    icon: "",
    jumpCondition: ""
  })
  // 活动图标
  const activityIcons = reactive<{ icons: ActivityIconInfo[], iconsStatus: any }>({
    icons: [],
    iconsStatus: undefined,
  })

  /** 游戏列表相关 */
  const gameCategories = ref<GameCategoryListItem[]>([])
  const gameTypes = ref<GameTypeListItem[]>([])
  const gameMerchants = ref<GameMerchantListItem[]>([])
  const gameTopics = ref<GameTopicListItem[]>([])

  // MerId--》MerName的字典
  const merchantNameMap = ref<Map<number, string>>(new Map())

  const categories = computed(() =>
    gameCategories.value.map(e => {
      let data: GameCategory = {
        id: e.id,
        name: e.name,
        icon: e.icon,
        iconActive: e.iconActive,
        sort: e.sort,
        createTime: e.createTime,
        count: e.count
      }
      return data
    }))

  const types = computed(() => {
    let list = gameTypes.value.map(e => {
      let data: GameType = {
        id: e.id,
        name: e.name,
        sort: e.sort,
        icon: e.icon,
        iconActive: e.iconActive,
        desc: e.desc,
        status: e.status,
        gameTotal: e.gameTotal,
        createTime: e.createTime
      }
      return data
    })

    // 登录后额外补充两个类型
    if (userStore.isLogin) {
      list.unshift({
        id: -1,
        name: userStore.lang == 'en' ? "FAVOR" : "FAVOR",
        sort: 1,
        icon: "/Favor.png",
        iconActive: "/Favor1.png",
        desc: "favor",
        status: 1,
        gameTotal: 0,
        createTime: 1679736075,
      })
      list.push({
        id: -2,
        name: userStore.lang == 'en' ? "HISTORY" : "Histórico",
        sort: 1,
        icon: "/sc1.png",
        iconActive: "/sc.png",
        desc: "History",
        status: 1,
        gameTotal: 0,
        createTime: 1679736075,
      })
    }
    return list
  })

  const userTypes = computed(() => {
    let list = types.value

    // 登录后额外补充两个类型
    if (userStore.isLogin) {
      list.unshift({
        id: -1,
        name: userStore.lang == 'en' ? "FAVOR" : "FAVOR",
        sort: 1,
        icon: "/Favor.png",
        iconActive: "/Favor1.png",
        desc: "favor",
        status: 1,
        gameTotal: 0,
        createTime: 1679736075,
      })
      list.push({
        id: -2,
        name: userStore.lang == 'en' ? "HISTORY" : "Histórico",
        sort: 1,
        icon: "/sc1.png",
        iconActive: "/sc.png",
        desc: "History",
        status: 1,
        gameTotal: 0,
        createTime: 1679736075,
      })
    }
    return list
  })

  const merchants = computed(() =>
    gameMerchants.value.map(e => {
      let data: GameMerchant = {
        count: e.count,
        id: e.id,
        name: e.name,
        nickname: e.nickname,
        adsPic: e.adsPic,
        subtitle: e.subtitle,
        gameRow: e.gameRow,
        gameColumn: e.gameColumn
      }
      return data
    })
  )

  const topics = computed(() =>
    gameTopics.value.map(e => {
      let data: GameTopic = {
        id: e.id,
        name: e.name,
        sort: e.sort,
        count: e.count
      }
      return data
    })
  )

  const gameBriefCategories = computed(() => {
    return gameCategories.value.map(e => {
      let data: GameBlockDataSource = {
        id: e.id,
        title: e.name,
        subtitle: e.subtitle,
        icon: e.icon,
        count: e.count,
        games: (e.games || []).map(e => {
          let g: GameBriefInfo = {
            title: e.gameName,
            sectitle: getMerchantName(e.gameMid),
            value: 0,
            icon: e.icon,
            userdata: {
              gameid: e.gameId,
              mid: e.gameMid,
              id: e.typeId
            },
            hasDemo: e.hasDemo,
            rtp: e.rtp,
            maxOdds: e.maxOdds,
            topicNames: e.topicNames,
            subIcon: e.subIcon,
            subIconWidth: e.subIconWidth,
            subIconHeight: e.subIconHeight,
            subIconPos: e.subIconPos,
          }
          return g
        }),
        rows: getRow(e.count)
      }
      return data
    })
  })

  const gameBriefTypes = computed(() => {
    return gameTypes.value.map(e => {
      let data: GameBlockDataSource = {
        id: e.id,
        title: e.name,
        subtitle: e.subtitle,
        icon: null,
        count: e.gameTotal,
        games: (e.games || []).map(e => {
          let g: GameBriefInfo = {
            title: e.gameName,
            sectitle: getMerchantName(e.gameMid),
            value: 0,
            icon: e.icon,
            userdata: {
              gameid: e.gameId,
              mid: e.gameMid,
              id: e.typeId
            },
            hasDemo: e.hasDemo,
            rtp: e.rtp,
            maxOdds: e.maxOdds,
            topicNames: e.topicNames,
            subIcon: e.subIcon,
            subIconWidth: e.subIconWidth,
            subIconHeight: e.subIconHeight,
            subIconPos: e.subIconPos,

          }
          return g
        }),
        rows: getRow(e.gameTotal)
      }
      return data
    })
  })

  const gameBriefMerchants = computed(() => {
    return gameMerchants.value.map(e => {
      let data: GameBlockDataSource = {
        id: e.id,
        title: e.nickname,
        subtitle: e.subtitle,
        icon: null,
        count: e.count,
        games: (e.games || []).map(e => {
          let g: GameBriefInfo = {
            title: e.gameName,
            sectitle: getMerchantName(e.gameMid),
            value: 0,
            icon: e.icon,
            userdata: {
              gameid: e.gameId,
              mid: e.gameMid,
              id: e.typeId
            },
            hasDemo: e.hasDemo,
            rtp: e.rtp,
            maxOdds: e.maxOdds,
            topicNames: e.topicNames,
            subIcon: e.subIcon,
            subIconWidth: e.subIconWidth,
            subIconHeight: e.subIconHeight,
            subIconPos: e.subIconPos,
          }
          return g
        }),
        rows: getRow(e.count)
      }
      return data
    })
  })

  const gameBriefTopics = computed(() => {
    return gameTopics.value.map(e => {
      let data: GameBlockDataSource = {
        id: e.id,
        title: e.name,
        subtitle: e.subtitle,
        icon: null,
        count: e.count,
        games: (e.games || []).map(e => {
          let g: GameBriefInfo = {
            title: e.gameName,
            sectitle: getMerchantName(e.gameMid),
            value: 0,
            icon: e.icon,
            userdata: {
              gameid: e.gameId,
              mid: e.gameMid,
              id: e.typeId
            },
            hasDemo: e.hasDemo,
            rtp: e.rtp,
            maxOdds: e.maxOdds,
            topicNames: e.topicNames,
            subIcon: e.subIcon,
            subIconWidth: e.subIconWidth,
            subIconHeight: e.subIconHeight,
            subIconPos: e.subIconPos,
          }
          return g
        }),
        rows: getRow(e.count)
      }
      return data
    })
  })

  const getRow = (count: number) => {
    if (count <= 3) {
      return 1
    }
    else if (count <= 6) {
      return 2
    }
    return 3
  }

  /** 活动相关 */
  const activityFirstRechargeConfig = ref<ActivityFirstConfig>({
    status: 0,
    start_time: 0,
    end_time: 0,
    type: 0,
    levels: []
  })
  const activityMarqueeStatus = ref<ActivityMarqueeStatus>({
    textStatus: 2,
    gameStatus: 2
  })
  const activitySharingConfig = ref<ActivitySharingConfig>({
    config: [],
  })


  const loadCommon = async (body?) => {
    console.log('--->loadCommon', body)
    let data = await commonApi.fetchCommon(body)
    console.log('<---loadCommon', data)
    loadViewBaseData(data.view)
    loadGameBaseData(data.game)
    loadActivityBaseData(data.activity)
    // loadHallBaseData(data.hall)
  }

  const loadViewBaseData = (data: ViewCommonInfo) => {
    carousels.value = data?.ads
    moduleAnnouncement.value = data.moduleAnnouncement||[]
    bottomInfo.value = data.bottomResp
    channelInfo.value = data.channelCodeInfoResp
    apkDownloadInfo.value = data.channelCodeResp
    menuInfo.value = data.menuIndexResp
    activityIcons.icons = data.moduleActivityIcons || []
    activityIcons.iconsStatus = data.moduleActivityIconsStatus
    registerBannerInfo.value = data.registerBannerConfig
  }

  const loadGameBaseData = (data: GameCommonInfo) => {
    gameCategories.value = data.gamesCategories
    gameTopics.value = data.gamesTopicRespList
    gameTypes.value = data.gamesTypes
    gameMerchants.value = data.merchant

    // 缓存Merchant信息
    _cacheMerchantNames(data.merchant)
  }

  const loadActivityBaseData = async (data: ActivityCommonInfo) => {
    activityFirstRechargeConfig.value = data.firstRechargeConfig
    activityMarqueeStatus.value = data.marqueeStatusResp
    activitySharingConfig.value = data.sharingConfig
  }

  const loadHallBaseData = async (data: HomeHallList) => {
    halls.value = data
  }

  const _cacheMerchantNames = (merchants: GameMerchantListItem[]) => {
    merchants.forEach(e => {
      merchantNameMap.value.set(e.id, e.nickname)
    })
  }

  const getMerchantName = (merId: number) => {
    return merchantNameMap.value.get(merId) || ''
  }

  return {
    // >>>>> states
    // view
    halls,
    carousels,
    moduleAnnouncement,
    bottomInfo,
    channelInfo,
    apkDownloadInfo,
    menuInfo,
    registerBannerInfo,

    // game
    categories,
    topics,
    types,
    merchants,
    merchantNameMap,
    userTypes,
    gameCategories,
    gameTopics,
    gameTypes,
    gameMerchants,
    gameBriefCategories,
    gameBriefTopics,
    gameBriefTypes,
    gameBriefMerchants,

    // 活动
    activityIcons,
    activityFirstRechargeConfig,
    activityMarqueeStatus,
    activitySharingConfig,

    // actions
    loadCommon,
    getMerchantName
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCommonStore as any, import.meta.hot))
}