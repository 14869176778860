import { defineStore } from 'pinia'
import { useAccount } from '@/composables/xfun/account'
import { useUser } from '@/composables/xfun/user'
import { useActivity } from '~/composables/xfun/activity'
import { readKV, encryption } from '../utils/index'
import { postMessage } from '~/utils'

import type {
  ActivityFirstConfig,
  ActivityIconInfo,
  ActivityMarqueeStatus,
  ActivitySharingConfig,
  AnnouncementInfo,
  ApkDownloadInfo,
  Auth2LoginReq,
  BalanceInfo,
  BottomInfo,
  ChannelInfo,
  GameCategoryListItem,
  GameListReq,
  GameMerchantListItem,
  GameNameReq,
  GameTopicListItem,
  GameTypeListItem,
  MenuInfo,
  NoticeDetailReq,
  NoticeInfo,
  PayConfigInfo,
  RegisterBannerInfo,
  RegisterReq,
  SendEmailVerifyReq,
  SendSMSVerifyReq,
  UserInfo,
  VipLevelInfo,
  WalletInfo,
  GameBetByInfo,
  GameMaintainInfo,
  GameJumpInfo,
  AccountInfo,
  MerchantTypesReq,
  HomeHallItem,
  DownloadRewardConfig,
  downLoadRewardRecordReq
} from '~/types/xfun'
import type { TokenParam } from '~/server/api/xfun/config'
import { useXCookie } from '~/composables/xfun/useXCookie'

export const useUserStore = defineStore('xfun-user', () => {
  const useAcc = useAccount()
  const useUsr = useUser()
  const useAct = useActivity()
  const nuxtApp = useNuxtApp()
  const xCookie = useXCookie()
  const activeStore = useActiveStore()
  // 邀请码
  const invitationCode = ref(null)
  const AgentCode = ref(null)

  // --------------用户信息
  const userToken = ref<string>(null)
  const userNotices = ref<NoticeInfo[]>([])
  let userInfo = reactive<UserInfo>({
    id: 0,
    platId: 0,
    email: '',
    thirdPartyAccount: '',
    registerType: 0,
    countryNo: '',
    phoneNo: '',
    password: '',
    salt: '',
    exchangePassword: '',
    realname: '',
    nickname: '',
    sex: '',
    avatar: '',
    lang: '',
    status: 0,
    exchangeStatus: 0,
    online: 0,
    channel: '',
    device: '',
    lastDevice: '',
    regTime: 0,
    lastLoginTime: 0,
    continuousLoginDay: 0,
    regIp: '',
    lastLoginIp: '',
    channelPackage: '',
    lastOnlineRechargeTime: 0,
    lastOnlineRechargeMoney: 0,
    createTime: 0,
    firstReId: '',
    firstReAmount: 0,
    promoteCode: '',
    extParams: '',
    taskMinutes: 0,
    vipFreeze:false
  })
  let userWallet = reactive<WalletInfo>({
    id: 0,
    totalExchange: 0,
    totalExchangeBonus: 0,
    totalBet: 0,
    totalBetBonus: 0,
    totalWin: 0,
    totalWinBonus: 0,
    totalReward: 0,
    exp: 0,
    vipId: 0,
    totalRecharge: 0,
    points: 0,
    upgradeTime: 0,
  })
  let userBalance = reactive<BalanceInfo>({
    balance: 0,
    balanceGift: 0,
    firstReId: '',
    curMerIds: [],
    remainingBalanceAudit: 0,
    remainingGiftAudit: 0,
    totalBalance: 0,
  })
  let LoginTime: any = ref(0)
  const lang = computed(() => userInfo.lang || 'pt')

  // --------------界面相关
  // 大厅分配集合
  const homeHalls = ref<HomeHallItem[]>([])

  // 游戏列表相关
  const gameCategories = ref<GameCategoryListItem[]>([])
  const gameTopics = ref<GameTopicListItem[]>([])
  const gameTypes = ref<GameTypeListItem[]>([])
  const gameMerchants = ref<GameMerchantListItem[]>([])
  const gamestypesfilterlist = ref<GameTypeListItem[]>([])

  // 侧滑菜单
  const menuInfo = ref<MenuInfo>({
    menuPageList: [],
    redirect: [],
  })
  // 轮播图片
  const carousels = ref<CarouselInfo[]>([])
  // 公告弹窗
  const announcementInfo = ref<AnnouncementInfo>({
    status: 0,
    name: '',
    icon: '',
    jumpCondition: '',
    content_en: '',
    content_pt: '',
    content: '',
  })
  // 底部信息
  const bottomInfo = ref<BottomInfo>({
    mediumList: [],
    manufacturerList: [],
  })
  // 渠道信息
  const channelInfo = ref<ChannelInfo>({
    id: 0,
    platId: 0,
    name: '',
    agentId: 0,
    code: '',
    activityConfigId: 0,
    gameConfigId: 0,
    rechargeConfigId: 0,
    apkUrl: '',
    iosUrl: '',
    pixelIdGoogle: '',
    pixelIdTiktok: '',
    pixelIdKwai: '',
    pixelIdFb: '',
    promoteType: 0,
    apkStyleId: 0,
    status: 0,
    createTime: 0,
  })
  // Apk下载信息
  const apkDownloadInfo = ref<ApkDownloadInfo>({
    iosUrl: '',
    apkUrl: '',
  })
  // 注册弹窗轮播图
  const registerBannerInfo = ref<RegisterBannerInfo>({
    status: 0,
    icon: '',
    jumpCondition: '',
  })
  // 是否显示app下载条
  const canShowDownload = computed(() => {
    // const dominList = ['afun5.com', 'www.afun5.com']
    // return !dominList.includes(window.location.host)
    return rewardconfig.isvaild && getdevicesInfo() > 3
  })

  // --------------活动相关
  // 首充配置
  const activityFirstRechargeConfig = ref<ActivityFirstConfig>({
    status: 0,
    start_time: 0,
    end_time: 0,
    type: 0,
    levels: [],
  })
  // 跑马灯状态
  const activityMarqueeStatus = ref<ActivityMarqueeStatus>({
    textStatus: 2,
    gameStatus: 2,
  })
  // 活动分享配置
  const activitySharingConfig = ref<ActivitySharingConfig>({
    config: [],
  })
  // 活动图标信息
  let activityIcons = reactive<{ icons: ActivityIconInfo[]; iconsStatus: any }>(
    {
      icons: [],
      iconsStatus: undefined,
    },
  )
  //分享第三方
  const activitySharingList = ref<ActivitySharingConfig>({
    config: [],
  })
  // -------------- VIP
  // vip
  const vipLevelList = ref<VipLevelInfo[]>([])
  //头像
  const vipAvatarList = ref<AvatarInfo[]>([])

  // -------------- 支付
  //支付配置信息(非登录)
  const payConfigInfoOfChannel = ref<PayConfigInfo>({
    in: [],
    out: [],
    payInfo: {
      id: 0,
      userid: 0,
      type: '',
      usdtType: '',
      usdtAddress: '',
      bankNo: '',
      bankName: '',
      bankCode: '',
      pixNumber: '',
      pixType: '',
      pixName: '',
      cpfNumber: '',
      status: 0,
      createTime: 0,
    },
    outTipsLevel:1,
  })

  //支付配置信息(登录)
  const payConfigInfo = ref<PayConfigInfo>({
    in: [],
    out: [],
    payInfo: {
      id: 0,
      userid: 0,
      type: '',
      usdtType: '',
      usdtAddress: '',
      bankNo: '',
      bankName: '',
      bankCode: '',
      pixNumber: '',
      pixType: '',
      pixName: '',
      cpfNumber: '',
      status: 0,
      createTime: 0,
    },
    outTipsLevel:1,
  })
  // 充值页配置集合
  const payPageConfigs = ref<PayPageConfig[]>([])
  //充值整合接口
  const PayConfigV2Info = ref<PayConfigV2Info>({
    content: '',
    pageV2RespList:[],
    selfMax:0,
    selfMin:0,
  })

  const PayConfigV2InfoIndex=ref<number>(0)
  const PayOrderInfo:any=ref({
    
  })
  // 
  const payBriefRespList = ref<PayBriefListRes[]>([])
  //弹窗广告开关（登录后）
  const advertisementStatus = ref<{
    status: number
  }>({
    status: 0,
  })

  //弹窗广告内容 （登录后）
  const advertisementData = ref<Array<PopupData>>([])

  // 设置token
  const setToken = (args: any = {}) => {
    args.token = userToken.value
    return args
  }

  //betbyruninfo
  const betbyruninfo = reactive<GameBetByInfo>({
    brandId: '',
    token: '',
  })
  //gameruninfo
  const gameruninfo = reactive<GameJumpInfo>({
    url: '',
  })

  //游戏维护信息
  const gameMaintaininfo: any = reactive<GameMaintainInfo>({
    repairTitle: '',
    repairContent: '',
    repairPic: '',
  })

  // 临时厂家列表
  const merchantsTemp = ref<any>([])

  // 下载奖励
  const rewardconfig = reactive<DownloadRewardConfig>({
    terminalType: '',
    rewardWay: 0,
    realStatus: 0,
    rewardAmount: 0,
    realPersonMonitoring: false,
    isvaild: false,
  })

  // 跳转字典
  const redirectDict = ref<any>([])

  // 获取首页大厅数据
  const user_home_halls_actions = async (opts?) => {
    const ret = await useUsr.fetchUserHomeHalls(opts)
    homeHalls.value = ret.data
    return ret
  }

  // 获取用户基础数据
  const user_basedata_actions = async (opts?) => {
    const ret = await useUsr.fetchUserBase(setToken(), opts)
    Object.assign(userInfo, ret.data.userInfo)
    Object.assign(userWallet, ret.data.userWallet)
    Object.assign(userBalance, ret.data.balanceResp)
    userNotices.value = ret.data.systemNotices
    return ret
  }

  // 根据模块id获取旗下的厂家信息
  const user_GamemodelMerchants = async (mid:number|string, opts?) => {
    const ret = await useUsr.fetchGamemodelMerchants({modelId:mid}, opts)
    merchantsTemp.value = ret.data
    return ret
  }

  // 获取模块下的厂家的游戏类型列表
  const user_MerchantGameTypes = async (modelId:number|string, mer:string,opts?) => {
    const ret = await useUsr.fetchmodelMerchantGameTypes({modelId, mer}, opts)
    return ret
  }

  // 获取模块下的标签
  const user_gameinfocategory  = async (mid:number|string, opts?) => {
    const ret = await useUsr.fetchgameinfocategory({modelId:mid}, opts)
    return ret
  }

  // 获取标签下的厂家
  const user_fetchmodelMerchants = async (mid:number, cid:number, opts?) => {
    const ret = await useUsr.fetchmodelMerchants({modelId:mid, cid}, opts)
    return ret
  }

  // 下载奖励
  const user_fetchdownloadrewardconfig = async (opts?) => {
    const ret = await useUsr.fetchdownloadrewardconfig(opts)
    if (ret.data) {
      Object.assign(rewardconfig, ret.data)
      rewardconfig.isvaild = true
    }
    else {
      rewardconfig.isvaild = false
    }
    
    return ret.data
  }

  // 生成下载奖励奖励
  const user_fetchdownloadrewardsave = async (args:downLoadRewardRecordReq, opts?) => {
    const ret = await useUsr.fetchdownloadrewardsave(setToken(args), opts)
    return ret.data
  }

  // 获取下载真人检测key
  const user_fetchdownloadrealKey = async (opts?) => {
    const ret = await useUsr.fetchdownloadrealKey(opts)
    return ret.data
  }

  // 跳转字典
  const  user_fetchredirect_dict = async ( opts?) => {
    const ret = await useUsr.fetchRedirectDict( opts)
    redirectDict.value = ret.data
    return ret.data
  }
  // 获取字典内容
  const getRedirectDict = () => {
    return redirectDict.value
  }

  // 登录
  const user_login_actions = async (
    args: LoginReq,
    savepwd: boolean = true,
  ) => {

    //点击登录埋点
    postMessage('loginClick');

    if (args.password != user_getaccountinfo_actions().password) {
      // @ts-ignore
      args.password = encryption(args.password)
    }

    const ret = await useAcc.fetchLogin(args)
    userToken.value = ret.data.token
    // writeKV('token', ret.data.token)
    console.log('-------->setToken', userToken.value)
    xCookie.setClientCookie('token', ret.data.token)

    if (savepwd) {
      console.log('Save account information')
      user_saveaccount_actions(args)
    } else {
      console.log('Remove account information')
      user_removeaccountinfo_actions()
    }
    if (user_isUserChange_actions(args)) {
      console.log('Reset default data when the current user changes')
      user_resetaccount_actions()
    }

    //登录成功埋点
    postMessage('login');

    return ret
  }

  /** 账户保存 */
  const user_saveaccount_actions = (data: any) => {
    const password = data.password || data.newpwd
    const phoneNo = data.phoneNo
    const email = data.email
    const accounttype = email ? 'email' : 'phoneNo'
    const account = email || phoneNo

    writeKV('account', account)
    writeKV('accounttype', accounttype)
    writeKV('password', password)
  }

  const user_issaveaccount_actions = () => {
    return readKV('account') != null
  }

  const user_removeaccountinfo_actions = () => {
    removeKV('account')
    removeKV('accounttype')
    removeKV('password')
  }

  const user_rawaccountinfo_actions = (): AccountInfo => {
    const accounttype = readKV('accounttype')
    const account = readKV('account')
    const password = readKV('password')

    const v: AccountInfo = {
      accounttype,
      account,
      password,
    }

    if (accounttype == 'email') {
      v['email'] = account
    } else {
      v['phoneNo'] = account
    }
    delete v.account
    delete v.accounttype

    return v
  }

  const user_getaccountinfo_actions = (): AccountInfo => {
    const account = readKV('account')
    const accounttype = readKV('accounttype')
    const password = readKV('password')
    return {
      accounttype,
      account,
      password,
    }
  }

  /** 用户是否发生改变 */
  const user_isUserChange_actions = (logininfo: any) => {
    const account = logininfo.email || logininfo.phoneNo
    return account != readKV('account')
  }

  const user_resetaccount_actions = () => {
    writeKV('lang', 'pt')
  }

  // 注册
  const user_register_actions = async (args: RegisterReq, savepwd: boolean) => {

    //点击注册埋点
    postMessage('registerClick');

    // @ts-ignore
    args.password = encryption(args.password)
    let ret = await useAcc.fetchRegister(args)
    userToken.value = ret.data.token
    xCookie.setClientCookie('token', ret.data.token)

    if (savepwd) {
      console.log('Save account information')
      user_saveaccount_actions(args)
    } else {
      console.log('Remove account information')
      user_removeaccountinfo_actions()
    }

    //注册成功埋点
    postMessage('register');

    return ret
  }

  // 注销
  const user_logout_actions = async () => {
    try {
      const ret = await useAcc.fetchLogout(setToken())
      // 登出埋点
      postMessage("logout")

    } catch (err) {
      console.log('[user_logout_actions]logout fail, perform force logout', err)
      throw err
    } finally {
      // 强制注销
      user_force_logout_actions()
    }
  }

  // 强制注销
  const user_force_logout_actions = () => {
    userToken.value = null
    xCookie.setClientCookie('token', null)
  }

  // 找回密码
  const user_retrievepwd_actions = async (args: FindPasswordReq) => {
    args.newpwd = encryption(args.newpwd)
    args.confirmpwd = encryption(args.confirmpwd)
    return await useAcc.fetchRetrievePwd(setToken(args))
  }

  // 绑定邮箱
  const user_verifyemail_actions = async (args: BindEmailReq) =>
    await useAcc.fetchVerifyEmail(setToken(args))

  // 绑定手机
  const user_verifyphone_actions = async (args: BindPhoneReq) =>
    await useAcc.fetchVerifyPhone(setToken(args))

  // token续约
  const user_extendtoken_actions = async (opts?) => {
    const res = await useAcc.fetchExtendToken(setToken(), opts)
    userToken.value = res.data.token
    xCookie.setClientCookie('token', res.data.token)
    return res
  }

  // 修改密码
  const user_modpwd_actions = async (args: ModPasswordReq) =>
    await useAcc.fetchModPwd(setToken(args))

  // 发送短信
  const user_sendsms_actions = async (args: SendSMSVerifyReq) =>
    await useAcc.fetchSendSMS(args)

  // 发送邮件
  const user_sendemail_actions = async (args: SendEmailVerifyReq) =>
    await useAcc.fetchSendEmail(args)

  // 第三方登录
  const user_auth2login_actions = async (args: Auth2LoginReq) => {
    args.registerType = 2
    let res = await useAcc.fetchAuth2Login(args)

    userToken.value = res.data.token
    xCookie.setClientCookie('token', userToken.value)
  }

  // 获取用户信息
  const user_info_actions = async (opts?) => {
    const res = await useUsr.fetchUserInfo(setToken(), opts)
    Object.assign(userInfo, res.data)
    return res
  }

  // 修改头像
  const user_modavatar_actions = async (args: ModAvatarReq) =>
    await useUsr.fetchModAvatar(setToken(args))

  // 修改昵称
  const user_modnickname_actions = async (args: ModNicknameReq) =>
    await useUsr.fetchModNickname(setToken(args))

  // 查询头像集合
  const user_queryavatar_action = async (opts?) =>
    await useUsr.fetchQueryAvatar(setToken(), opts)
  //查询登录次数
  const user_logincount_actions = async (opts?) => {
    const res = await useUsr.fetchUserLoginTimes(setToken(), opts)
    LoginTime.value = res.data
  }
  // 游戏下分
  const user_gameunderscore_actions = async (opts?) => {
    const res = await useUsr.fetchGameUnderScore(setToken(), opts)
    Object.assign(userBalance, res.data)
    return res
  }

  // 公告详情
  const user_noticedetail_actions = async (args: NoticeDetailReq, opts?) =>
    await useUsr.fetchNoticeDetail(setToken(args), opts)

  // 获取vip等级列表
  const vip_level_actions = async (opts?) => {
    const data = await useUsr.fetchVipLevels(setToken(), opts)
    vipLevelList.value = data.data
    return data
  }

  // vip提现明细
  const vip_withdraw_detail_actions = async () =>
    await useUsr.fetchVipWithdrawDetail(setToken())

  // 获取vip头像列表
  const vip_queryavatar_actions = async () => {
    const data = await useUsr.fetchQueryAvatar(setToken())
    vipAvatarList.value = data.data.avatarList
    return data
  }

  // 获取vip认证
  const vip_auths_actions = async (opts?) => await useUsr.fetchVipAuths(setToken(), opts)

  // 获取vip配置
  const vip_vip_config_actions = async (opts?) =>
    await useUsr.fetchVipConfig(setToken(), opts)

  // 获取钱包资金记录
  const wallet_fundlogs_actoins = async (req: WalletFundLogsReq, opts?) =>
    await useUsr.fetchWalletFundLogs(setToken(req), opts)

  // 获取钱包资金记录总数
  const wallet_fundlogs_total_actions = async (req: WalletFundLogsReq, opts?) =>
    await useUsr.fetchWalletFundLogsTotal(setToken(req), opts)


  //获取钱包信息
  const wallet_info_actions = async (opts?) =>{
    let data=await useUsr.fetchWalletInfo(setToken(), opts)
    Object.assign(userWallet, data.data)
    return data
  }
    

  //获取钱包余额 刷新余额
  const wallet_get_balance_actions = async (opts?) => {
    const data = await useUsr.fetchWalletBalance(setToken(), opts)
    Object.assign(userBalance, data.data)
    return data
  }
  //获取钱包余额并进行下分操作
  const wallet_get_balance_underScore_actions = async (opts?) => {
    const data = await useUsr.fetchWalletBalance(setToken(), opts)
    Object.assign(userBalance, data.data)
    if (data.data.curMerIds && data.data.curMerIds.length > 0) {
      data.data.curMerIds.forEach(async (item: any) => {
        await user_gameunderscore_actions(opts)
      })
    }
    return data
  }

  // 获取奖金记录
  const wallet_get_Wallet_reward_actions = async (
    args: WalletQueryRewardReq, opts?
  ) => {
    return await useUsr.fetchWalletQueryReward(setToken(args), opts)
  }

  // 获取支付记录
  const pay_get_logs_actions = async (args: PayLogsReq) =>
    await useUsr.fetchPayGetLogs(setToken(args))

  // 充值
  const pay_recharge_index_actions = async (args: any) => {
    //点击充值埋点
    postMessage('rechargeClick')
    return await useUsr.fetchPayRechargeIndex(setToken(args))
  }
  // 快捷充值
  const pay_recharge_quick_actions = async () =>
    await useUsr.fetchPayConfigQuickList(setToken())

  // 提现
  const pay_exchange_actions = async (req: PayExchangeReq) => {
    //点击提现埋点
    postMessage('withdrawClick')
    const res = await useUsr.fetchPayExchange(setToken(req))
    //提现成功埋点
    postMessage('withdrawOrderSuccess', { amount: req.money, currency: 'PIX', success: 1 })
    return res
  }

  // 获取支付配置
  const pay_payment_config_actions = async (opts?) => {
    const res = await useUsr.fetchPayPaymentConfig(setToken(), opts)
    payConfigInfo.value = res.data
    return res.data
  }

  // 获取支付整合配置
  const pay_payment_configV2_actions = async (opts?) => {
    const res = await useUsr.fetchPayPaymentConfigV2(setToken(), opts)
    PayConfigV2Info.value = res.data
    return res.data
  }



  // 设置支付信息
  const pay_setpayinfo_actions = async (args: PaySetPayInfoReq) => {
    await useUsr.fetchPaySetPayInfo(setToken(args))
    if (!payConfigInfo.value.payInfo) {
      // @ts-ignore
      payConfigInfo.value.payInfo = {}
    }
    payConfigInfo.value.payInfo.pixNumber = args.pixnumber;
    payConfigInfo.value.payInfo.pixName = args.name;
  }

  // 获取支付订单简要信息
  const pay_order_brief_actions = async () =>
    await useUsr.fetchPayOrderBrief(setToken())

  // 获取支付配置
  const pay_config_rechargepage_actions = async (args: any = {}, opts?) => {
    const res = await useUsr.fetchPayConfigRechargePage(setToken(args), opts)
    payPageConfigs.value = res.data
    return res.data
  }

  // 获取公共支付配置common
  const pay_config__home_common_actions = async (opts?) => {
    const res = await useUsr.fetchPayHomePaymentConfig(setToken(), opts)
    payConfigInfo.value = res.data.paymentConfigResp
    payBriefRespList.value = res.data.payBriefRespList
    return res.data
  }

  // 获取支付配置
  const pay_payment_configchannel_actions = async (
    args: PayConfigChannelReq,
    opts?
  ) => {
    const res = await useUsr.fetchPayPaymentConfigChannel(setToken(args), opts)
    payConfigInfoOfChannel.value = res.data
    return res.data
  }

  // 获取指定游戏类型下的厂家列表
  const game_gamemerchantTypes_actions = async (args: MerchantTypesReq, opts?) =>
    await useUsr.fetchGamemerchantTypes(args, opts)

  // 获取历史和收藏的下的厂家列表
  const game_gametypeMerchants_actions = async (args: GameTypeMerchantsReq, opts?) =>
    await useUsr.fetchGametypeMerchants(args, opts)

  // 获取历史和收藏的下的厂家列表
  const game_gameothertypeMerchants_actions = async (
    args: GameTypeMerchantsReq,
    opts?
  ) => await useUsr.fetchGameothertypeMerchants(setToken(args), opts)

  // 获取注单列表
  const game_gamebets_actions = async (args: GameBetsReq, opts?) =>
    await useUsr.fetchGameBets(setToken(args), opts)

  // 获取游戏列表
  const game_gamegames_actions = async (args: GameListReq, opts?) => {
    const res = await useUsr.fetchGameList(args, opts)
    return deal_game_data(res)
  }

  // 获取游戏列表
  const game_gamesearch_actions = async (args: GameSearchReq, opts?) => {
    const res = await useUsr.fetchGameSearch(args, opts)
    return deal_game_data(res)
  }

  // 获取游戏列表
  const game_gamecategorygames_actions = async (args: GameListReq, opts?) => {
    const res = await useUsr.fetchGameCategoryGames(args, opts)
    return deal_game_data(res)
  }

  // 给游戏列表添加收藏和历史
  const addandremovecollecthistory = () => {
    if (gamestypesfilterlist.value.findIndex(e => e.id == -1) == -1) {
      gamestypesfilterlist.value.unshift({
        createTime: 1679736075,
        desc: 'favor',
        gameTotal: 0,
        icon: '/Favor.png',
        iconActive: '/Favor1.png',
        id: -1,
        name: 'FAVOR',
        sort: 1,
        status: 1,
        games: [],
        subtitle: ''
      })
    }

    if (gamestypesfilterlist.value.findIndex(e => e.id == -2) == -1) {
      gamestypesfilterlist.value.push({
        createTime: 1679736075,
        desc: 'History',
        gameTotal: 0,
        icon: '/sc1.png',
        iconActive: '/sc.png',
        id: -2,
        name: 'Histórico',
        sort: 1,
        status: 1,
        games: [],
        subtitle: ''
      })
    }
  }

  //  获取游戏列表
  const game_gametopicgames_actions = async (args: GameListReq) =>
    await useUsr.fetchGameTopicGames(args)

  // 登录游戏
  const game_gameLogin_actions = async (args: GameLoginReq) => {
    const res = await useUsr.fetchGameLogin(setToken(args))

    postMessage('enterGame')

    gameruninfo.url = res.data.gameUrl
    userBalance.balance = res.data.balance
    userBalance.balanceGift = res.data.balanceGift
    betbyruninfo.brandId = res.data.merchantNo
    betbyruninfo.token = res.data.token
    if (res.data.isMaintain) {
      Object.assign(gameMaintaininfo, res.data)
    }
    return res
  }

  // 试玩游戏
  const game_gametrial_actions = async (args: GameTrialReq) => {
    const res = await useUsr.fetchGameTrial(args)

    postMessage('tryGame')

    gameruninfo.url = res.data.gameUrl
    betbyruninfo.brandId = res.data.merchantNo
    betbyruninfo.token = res.data.token
    if (res.data.isMaintain) {
      Object.assign(gameMaintaininfo, res.data)
    }
    return res
  }

  // 获取游戏名称
  const game_gamename_actions = async (args: GameNameReq, opts?) =>
    await useUsr.fetchGameName(setToken(args), opts)

  // 添加收藏
  const game_addcollects_actions = async (args: GameNameReq, opts?) =>
    await useUsr.fetchGameCollectsAdd(setToken(args), opts)

  // 取消收藏
  const game_removecollects_actions = async (args: GameNameReq, opts?) =>
    await useUsr.fetchGameCollectsCancel(setToken(args), opts)

  // 获取收藏列表
  const game_gamecollects_actions = async (args: GameListReq, opts?) => {
    let res = await useUsr.fetchGameCollects(setToken(args), opts)
    return deal_game_data(res)
  }


  // 获取游戏历史
  const game_gamehistories_actions = async (args: GameListReq, opts?) => {
    let res = await useUsr.fetchGameHistories(setToken(args), opts)

    return deal_game_data(res)

  }


  // 判断是否收藏
  const game_iscollect_actions = async (args: GameNameReq) =>
    await useUsr.fetchGameCollectsJudge(setToken(args))

  // 活动共享配置
  const activity_sharingconfig_actions = async () => {
    const res = await useAct.fetchShareSharingConfig(setToken())
    activitySharingList.value = res.data
  }

  //弹窗广告状态(登录后)
  const user_notice_advertisementStatus_actions = async (opts?) => {
    const res = await useUsr.fetchNotcieAdvertisement(setToken(), opts)
    advertisementStatus.value = res.data
    return res
  }

  //弹窗广告内容
  const user_notice_advertisement_actions = async (opts?) => {
    const res = await useUsr.fetchPoPupsAdvertisement(setToken(), opts)
    advertisementData.value = res.data
    return res
  }

  //弹窗公告(登录前)
  const user_notice_announcementStatus_actions = async () => {
    return announcementInfo.value
  }


  // 同步和token无关的基础数据
  const sync_common_data_actions = () => {
    const useCommon = useCommonStore()
    gameCategories.value = useCommon.gameCategories
    gameTopics.value = useCommon.gameTopics
    gameTypes.value = useCommon.gameTypes
    gameMerchants.value = useCommon.gameMerchants

    gamestypesfilterlist.value = JSON.parse(JSON.stringify(gameTypes.value))

    menuInfo.value = JSON.parse(JSON.stringify(useCommon.menuInfo))
    carousels.value = useCommon.carousels
    announcementInfo.value = useCommon.moduleAnnouncement && useCommon.moduleAnnouncement[0] || null
    bottomInfo.value = useCommon.bottomInfo
    channelInfo.value = useCommon.channelInfo
    apkDownloadInfo.value = useCommon.apkDownloadInfo
    registerBannerInfo.value = useCommon.registerBannerInfo

    activityFirstRechargeConfig.value = useCommon.activityFirstRechargeConfig
    activityMarqueeStatus.value = useCommon.activityMarqueeStatus
    activitySharingConfig.value = useCommon.activitySharingConfig
    activityIcons.icons = useCommon.activityIcons.icons
    activityIcons.iconsStatus = useCommon.activityIcons.iconsStatus
    menuInfo.value.menuPageList = transMenuData(menuInfo.value)
  }

  const deal_game_data = (data: GameListRes) => {
    const useCommon = useCommonStore()
    const gamelist = data.data.map((item: any) => {
      return {
        title: item.gameName,
        sectitle: useCommon.getMerchantName(item.gameMid),
        value: item.typeId,
        icon: item.icon,
        userdata: {
          gameid: item.gameId,
          mid: item.gameMid,
          id: item.id,
        },
        topicNames: item.topicNames,
        hasDemo: item.hasDemo,
        subIcon: item.subIcon,
        subIconPos: item.subIconPos,
        subIconWidth: item.subIconWidth,
        subIconHeight: item.subIconHeight,
        rtp: item.rtp,
        maxOdds: item.maxOdds,
      }
    })
    return {
      games: gamelist,
      total: data.total,
    }
  }

  /*** getter 定义  ****/
  //获取vip等级
  const getVipLevel = () => {
    return userWallet.vipId
  }

  //获取当前vip经验值
  const getVipExp = () => {
    return userWallet.exp
  }

  //获取余额
  const getUserMoney = () => {
    if (!userBalance.totalBalance) {
      return '0.00'
    }
    return userBalance.totalBalance
  }

  const getUserBalance = () => {
    if (!userBalance.balance) {
      return '0.00'
    }
    return userBalance.balance
  }

  const getUserBalanceGift = () => {
    if (!userBalance.balanceGift) {
      return '0.00'
    }
    return userBalance.balanceGift
  }
  const getUserRemainingBalanceAudit = () => {
    if (!userBalance.remainingBalanceAudit) {
      return '0.00'
    }
    return userBalance.remainingBalanceAudit
  }

  const getUserRemainingGiftAudit = () => {
    if (!userBalance.remainingGiftAudit) {
      return '0.00'
    }
    return userBalance.remainingGiftAudit
  }
  const getAdvertisementStatus = (): boolean => {
    return advertisementStatus.value.status == 1
  }
  const getPwaStatus = (): boolean => {
    return LoginTime.value == 1 && userToken.value !== null
  }
  const getPopupIndexWrapOrder = () => {
    let orderArr = []
    if (getPwaStatus()&&getdevicesInfo()>3) {
      orderArr.push('showPwa')
    }
    if (getAdvertisementStatus()) {
      orderArr.push('showAdvertisement')
    }
    if (activeStore.getLimittimeOpportunityFlag().haveQuota) {
      orderArr.push('showLimittimeOpportunity')
    }
    return orderArr
  }
  //是否登录
  const isLogin = computed(() => {
    return userToken.value !== null
  })

  const floorTwoDecimals = (number: any) => {
    return (Math.floor(number * 100) / 100).toFixed(2)
  }

  const getproviderName = (mid: any) => {
    const item: any = gameMerchants.value.find((item: any) => {
      if (item.id == mid) {
        return true
      }
    })

    if (item) return item.nickname

    console.error('getproviderName error', gameMerchants.value)
    return 'unknown'
  }

  return {
    // >>>>> utils
    addandremovecollecthistory,
    setToken,

    // >>>>> states
    // view
    carousels,
    announcementInfo,
    bottomInfo,
    channelInfo,
    apkDownloadInfo,
    canShowDownload,
    menuInfo,
    registerBannerInfo,
    // game
    homeHalls,
    gameCategories,
    gameTopics,
    gameTypes,
    gamestypesfilterlist,
    gameMerchants,
    gameMaintaininfo,
    betbyruninfo,
    gameruninfo,
    merchantsTemp,
    // activity
    activityIcons,
    activityFirstRechargeConfig,
    activityMarqueeStatus,
    activitySharingConfig,
    activitySharingList,
    // user
    lang,
    isLogin,
    userToken,
    userInfo,
    userWallet,
    userNotices,
    userBalance,
    advertisementData,
    invitationCode,
    AgentCode,
    rewardconfig,
    // pay
    payConfigInfoOfChannel,
    payConfigInfo,
    payPageConfigs,
    PayConfigV2Info,
    PayConfigV2InfoIndex,
    PayOrderInfo,
    // vip
    vipLevelList,
    vipAvatarList,
    // >>>>> getter
    // 字典
    redirectDict,
    getRedirectDict,
    getUserMoney,
    getUserBalance,
    getUserBalanceGift,
    getUserRemainingBalanceAudit,
    getUserRemainingGiftAudit,
    getVipLevel,
    getVipExp,
    getAdvertisementStatus,
    getPwaStatus,
    getproviderName,
    getPopupIndexWrapOrder,
    // >>>>> actions
    sync_common_data_actions,
    user_home_halls_actions,
    user_basedata_actions,
    user_login_actions,
    user_register_actions,
    user_logout_actions,
    user_force_logout_actions,
    // user_setloginFlag_actions,
    user_saveaccount_actions,
    user_issaveaccount_actions,
    user_removeaccountinfo_actions,
    user_rawaccountinfo_actions,
    user_getaccountinfo_actions,
    user_isUserChange_actions,
    user_resetaccount_actions,
    user_retrievepwd_actions,
    user_verifyemail_actions,
    user_verifyphone_actions,
    user_extendtoken_actions,
    user_modpwd_actions,
    user_sendsms_actions,
    user_sendemail_actions,
    user_auth2login_actions,
    user_info_actions,
    user_modavatar_actions,
    user_modnickname_actions,
    user_queryavatar_action,
    user_logincount_actions,
    user_gameunderscore_actions,
    user_noticedetail_actions,
    user_notice_advertisementStatus_actions,
    user_notice_advertisement_actions,
    user_notice_announcementStatus_actions,
    vip_level_actions,
    vip_queryavatar_actions,
    vip_withdraw_detail_actions,
    vip_auths_actions,
    vip_vip_config_actions,
    wallet_fundlogs_actoins,
    wallet_fundlogs_total_actions,
    wallet_info_actions,
    wallet_get_balance_actions,
    wallet_get_balance_underScore_actions,
    wallet_get_Wallet_reward_actions,
    pay_get_logs_actions,
    pay_exchange_actions,
    pay_payment_config_actions,
    pay_payment_configV2_actions,
    pay_config__home_common_actions,
    pay_recharge_index_actions,
    pay_recharge_quick_actions,
    pay_setpayinfo_actions,
    pay_order_brief_actions,
    pay_config_rechargepage_actions,
    pay_payment_configchannel_actions,
    game_gamemerchantTypes_actions,
    game_gametypeMerchants_actions,
    game_gameothertypeMerchants_actions,
    game_gamebets_actions,
    game_gamegames_actions,
    game_gamesearch_actions,
    game_gamecategorygames_actions,
    game_gametopicgames_actions,
    game_gameLogin_actions,
    game_gametrial_actions,
    game_gamename_actions,
    game_addcollects_actions,
    game_removecollects_actions,
    game_gamecollects_actions,
    game_gamehistories_actions,
    game_iscollect_actions,
    activity_sharingconfig_actions,
    user_GamemodelMerchants,
    user_MerchantGameTypes,
    user_gameinfocategory,
    user_fetchmodelMerchants,
    user_fetchdownloadrewardconfig,
    user_fetchdownloadrewardsave,
    user_fetchdownloadrealKey,
    user_fetchredirect_dict
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore as any, import.meta.hot))
}
