import revive_payload_client_RvGTB9DB6q from "/Users/leo/nuxt_client/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_xogpoflmiec4e7nkulssnntec4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_I4dtRRS1C1 from "/Users/leo/nuxt_client/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_xogpoflmiec4e7nkulssnntec4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7RNhL5iguh from "/Users/leo/nuxt_client/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_xogpoflmiec4e7nkulssnntec4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_39nJTVjtDt from "/Users/leo/nuxt_client/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_xogpoflmiec4e7nkulssnntec4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_nDiAof2PAE from "/Users/leo/nuxt_client/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_xogpoflmiec4e7nkulssnntec4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Yzf8BExG9q from "/Users/leo/nuxt_client/node_modules/.pnpm/@pinia+nuxt@0.4.11_@vue+composition-api@1.7.2_vue@3.4.27_typescript@5.4.5___rollup@4.17.2_typ_t2mxwsvztycsmo33r4pxjmjgyq/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/leo/nuxt_client/.nuxt/components.plugin.mjs";
import prefetch_client_St5E4VWWet from "/Users/leo/nuxt_client/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_xogpoflmiec4e7nkulssnntec4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_date_wu07sN3zyv from "/Users/leo/nuxt_client/node_modules/.pnpm/vuetify-nuxt-module@0.6.7_rollup@4.17.2_typescript@5.4.5_vite@5.2.11_@types+node@20.12.10_sas_k2v423czusucshm4jqwasngecy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-date.mjs";
import vuetify_i18n_KEgKVJ5OKw from "/Users/leo/nuxt_client/node_modules/.pnpm/vuetify-nuxt-module@0.6.7_rollup@4.17.2_typescript@5.4.5_vite@5.2.11_@types+node@20.12.10_sas_k2v423czusucshm4jqwasngecy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.mjs";
import vuetify_icons_giEvWprYlV from "/Users/leo/nuxt_client/node_modules/.pnpm/vuetify-nuxt-module@0.6.7_rollup@4.17.2_typescript@5.4.5_vite@5.2.11_@types+node@20.12.10_sas_k2v423czusucshm4jqwasngecy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_client_hints_client_GTA3af9s55 from "/Users/leo/nuxt_client/node_modules/.pnpm/vuetify-nuxt-module@0.6.7_rollup@4.17.2_typescript@5.4.5_vite@5.2.11_@types+node@20.12.10_sas_k2v423czusucshm4jqwasngecy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-client-hints.client.mjs";
import pwa_icons_fjmdiFz6LR from "/Users/leo/nuxt_client/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@4.17.2_vite@5.2.11_@types+node@20.12.10_sass@1.77.0_terser@5.31.0_sbigphfryghw2hbqnqz2od5qu4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_XtrQb0LiiN from "/Users/leo/nuxt_client/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@4.17.2_vite@5.2.11_@types+node@20.12.10_sass@1.77.0_terser@5.31.0_sbigphfryghw2hbqnqz2od5qu4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_LK7ijhW814 from "/Users/leo/nuxt_client/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.2_vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_TFUMVQd8Os from "/Users/leo/nuxt_client/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.10_@unocss+reset_xogpoflmiec4e7nkulssnntec4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _21_init_client_DDo37cjP3A from "/Users/leo/nuxt_client/plugins/21.init.client.ts";
import _22_ws_client_OvUwnYvFnH from "/Users/leo/nuxt_client/plugins/22.ws.client.ts";
import Vue3Lottie_client_bMZiOS6AB0 from "/Users/leo/nuxt_client/plugins/Vue3Lottie.client.ts";
import devtools_client_1xQqGOY7Py from "/Users/leo/nuxt_client/plugins/devtools.client.ts";
import route_mgr_client_FqDcE2EtHT from "/Users/leo/nuxt_client/plugins/route-mgr.client.ts";
import userinfo_client_hZi2MtfGyv from "/Users/leo/nuxt_client/plugins/userinfo.client.ts";
import vuetify_7h9QAQEssH from "/Users/leo/nuxt_client/plugins/vuetify.ts";
import vuetify_sync_hgBHc08oFg from "/Users/leo/nuxt_client/node_modules/.pnpm/vuetify-nuxt-module@0.6.7_rollup@4.17.2_typescript@5.4.5_vite@5.2.11_@types+node@20.12.10_sas_k2v423czusucshm4jqwasngecy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-sync.mjs";
export default [
  revive_payload_client_RvGTB9DB6q,
  unhead_I4dtRRS1C1,
  router_7RNhL5iguh,
  payload_client_39nJTVjtDt,
  check_outdated_build_client_nDiAof2PAE,
  plugin_vue3_Yzf8BExG9q,
  components_plugin_KR1HBZs4kY,
  prefetch_client_St5E4VWWet,
  vuetify_date_wu07sN3zyv,
  vuetify_i18n_KEgKVJ5OKw,
  vuetify_icons_giEvWprYlV,
  vuetify_client_hints_client_GTA3af9s55,
  pwa_icons_fjmdiFz6LR,
  pwa_client_XtrQb0LiiN,
  i18n_LK7ijhW814,
  chunk_reload_client_TFUMVQd8Os,
  _21_init_client_DDo37cjP3A,
  _22_ws_client_OvUwnYvFnH,
  Vue3Lottie_client_bMZiOS6AB0,
  devtools_client_1xQqGOY7Py,
  route_mgr_client_FqDcE2EtHT,
  userinfo_client_hZi2MtfGyv,
  vuetify_7h9QAQEssH,
  vuetify_sync_hgBHc08oFg
]