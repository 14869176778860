import { defineStore } from 'pinia'
import { useActivity } from '~/composables/xfun/activity'
import { useUserStore } from './xfun-user'
import { TaskType, type CanWeeklyCardRewardDetail, type CardGetBonusRewardReq, type CashRainAnimationConfig, type CashRainIsReachStandardResult, type CashRainPageConfig, type ChampionshipJoinReq, type ChampionshipPageResult, type ChampionshipPopResult, type ChampionshipRankingReq, type ChampionshipRankingResult, type ChampionshipReceiveReq, type ContinuousRechargeItem, type LimitTimeOpportunityFlag, type LimitTimeRewardConfig, type LotteryDailyResult, type LotteryDrawLog, type LotteryDrawLogsReq, type LotteryDrawPageResult, type LotteryFlag, type LotteryNewResult, type LotteryStatus, type LotteryUserLogsReq, type NoviceTask, type PromoteConfigResult, type PromoteRewardRecord, type PromoteRewardSummary, type ReceiveRankingRewardReq, type RechargeShareCanGetReward, type RechargeShareConfig, type RedeemPrizeReq, type RedPacketConfigResult, type RedPacketHighlightResult, type RedPacketListResult, type RedPacketShootRecord, type RedPacketShootReq, type RedPacketShootResult, type TaskListItem, type TaskReceiveReq, type TurntableConfig, type TurntableRewardLog, type TurntableSpinReq, type WeekBetMyRankingResult, type WeekBetQueryRankingRewardResult, type WeekBetRankingListItem, type WeekBetSwitchResult, type WeeklyCard, type WeeklyCardConfigReq, type WeeklyCardConfigResult, type RedPacketShootRecordReq, type OpenActivityListResult, type ActivityHomeInfoRes, type NewTaskReceive, type SignConfigResult, type VipItem } from '~/types/xfun'

export const useActiveStore = defineStore('xfun-active', () => {
    const useact = useActivity()
    const userStore = useUserStore()

    // 设置token
    const setToken = (args: any = {}) => {
        return userStore.setToken(args)
    }

    // 活动列表
    const activityCenterList = ref([])

    //所有任务
    const allTaskList = ref([])

    //已经开启的活动信息
    const activityOpenList = reactive<{ openlList: OpenActivityListResult[] }>({
        openlList: []
    })

    // 推广活动数据
    const promoteActivityState = reactive<{
        promoteRecord: PromoteRewardRecord,             //推广奖励记录
        promoteConfig: PromoteConfigResult,             //推广配置
        promoteRewardSummary: PromoteRewardSummary,     //推广奖励汇总
        promoteRewardSummary2: PromoteRewardSummary2,     //推广奖励汇总2
        promoteUrl: string                              //推广链接
    }>({
        promoteRecord: {
            yesterdayInviteNum: 0,
            totalInviteNum: 0,
            rechargeNum: 0,
            acceptedRechargeNum: 0
        },
        promoteConfig: {
            rewardConfig: {
                betRewardGameIds: '',
                betRewardRate: 0,
                firstBetRewardRate: 0,
                limitTimes: 0,
                rechargeRewardMin: 0,
                rewardType: 0,
                secondBetRewardRate: 0,
                status: 0,
                thirdBetRewardRate: 0
            },
            config: []
        },
        promoteRewardSummary: {
            allReward: 0,
            promoteTotalRewardList: [],
        },
        promoteRewardSummary2: {
            allReward: 0,
            promoteTotalRewardList: [],
            inviteNumFirst: 0,
            inviteNumSecondThird: 0,
            inviteNumTotal: 0
        },
        promoteUrl: '',
    })

    //日常任务数据
    const dailytaskActivityState = reactive<{
        state: boolean,
        hasAward: boolean,
        listNew: TaskListItem[],
        listDaily: TaskListItem[],
        listWeekly: TaskListItem[],
        listBox: TaskListItem[],
        allList: []
    }>({
        state: false,
        hasAward: false,
        listNew: [],
        listDaily: [],
        listWeekly: [],
        listBox: [],
        allList: []
    })

    //转盘活动数据
    const turntableActivityState = reactive<{
        turntableConfig: TurntableConfig,
        turntableRewardLogs: TurntableRewardLog[],
        turntablePointGroup: any,
        turntablePointLogs: any // 类似 { "1":"99","2":"2","3":"4" }
    }>({
        turntableConfig: {
            status: 0,
            start_time: 0,
            end_time: 0,
            tips_en: '',
            tips_pt: '',
            tips: '',
            max_spin: 0,
            type: 0,
            limit_times: 0,
            reward_levels: []
        },
        turntableRewardLogs: [],
        turntablePointGroup: {},
        turntablePointLogs: {}
    })

    //充值分享数据
    const rechargeShareActivityState = reactive<{
        canGetReward: RechargeShareCanGetReward
        config: RechargeShareConfig
    }>({
        canGetReward: {
            status: 0,
            isRecharge: 0,
            isShare: 0,
            rechargeAmount: 0
        },
        config: {
            status: 0,
            min_money: 0,
            reward: 0,
            type: 0,
            limit_times: 0,
            icon: '',
            desc_en: '',
            desc_pt: '',
            desc: ''
        },
    })

    //新手每日抽奖数据
    const lotteryActivityState = reactive<{
        lotteryDaily: LotteryDailyResult,
        lotteryNew: LotteryNewResult,
        lotteryPageNew: LotteryDrawPageResult,
        lotteryPageDaily: LotteryDrawPageResult,
        lotteryDrawLogs: LotteryDrawLog[],
        lotteryUserDrawLogs: LotteryDrawLog[],
        lotteryStatus: LotteryStatus,
        lotteryFlag: LotteryFlag
    }>({
        lotteryDaily: {
            activityName: '',
            reward: 0,
            remainingTimes: 0,
            rewardStatus: 0,
            tips: '',
            taskList: [],
            expiration: 0
        }, //每日抽奖
        lotteryNew: {
            activityName: '',
            reward: 0,
            remainingTimes: 0,
            rewardStatus: 0,
            tips: '',
            taskList: []
        }, //新手抽奖
        lotteryPageNew: {
            activityName: '',
            expiration: 0,
            totalReward: 0,
            lotteryUser: {
                userid: 0,
                userName: '',
                lotteryCount: 0
            },
            remainingTimes: ''
        }, //新手抽奖页面
        lotteryPageDaily: {
            activityName: '',
            expiration: 0,
            totalReward: 0,
            lotteryUser: {
                userid: 0,
                userName: '',
                lotteryCount: 0
            },
            remainingTimes: ''
        }, //每日抽奖页面
        lotteryDrawLogs: [], //抽奖记录
        lotteryUserDrawLogs: [], //用户中奖记录
        lotteryStatus: {
            noviceStatus: 0,
            dailyStatus: 0
        }, //活动状态查询
        lotteryFlag: {
            switchStatus: 0,
            showStatus: 0
        } //抽奖标识
    })

    //红包雨（足球）数据
    const redPacketActivityState = reactive<{
        config: RedPacketConfigResult,
        areaList: RedPacketListResult,
        highlight: RedPacketHighlightResult,
        shoot: RedPacketShootResult,
        shootRecord: RedPacketShootRecord
    }>({
        config: [],
        areaList: {
            chanceLeft: 0,
            areaList: []
        },
        highlight: {
            showStatus: 0,
            switchStatus: 0
        },
        shoot: {
            areaNo: 0,
            displayAmount: 0,
            isHit: 0
        },
        shootRecord: {
            shootTimes: 0,
            sumAmount: 0,
            shootList: []
        },
    })

    //锦标赛数据
    const championshipActivityState = reactive<{
        page: ChampionshipPageResult,
        popup: ChampionshipPopResult,
        rank: ChampionshipRankingResult
    }>({
        page: {
            eventName: '',
            ranking: '',
            activityPoints: 0,
            rankingReward: 0,
            totalBonus: 0,
            activityStartTime: 0,
            activityEndTime: 0,
            activityDirections: '',
            activityBanner: '',
            gamesList: [],
            myBonusResp: [],
            eventSchedule: []
        },
        popup: {
            userid: 0,
            activityEndTime: 0,
            eventName: '',
            eventIcon: '',
            ranking: '',
            activityPoints: 0,
            rankingReward: 0,
            totalBonus: 0,
            championshipRankingRespList: [],
            rewardList: []
        },
        rank: {
            eventName: '',
            userid: 0,
            eventIcon: '',
            activityPoints: 0,
            ranking: '',
            reward: 0,
            rankingReward: 0,
            totalBonus: 0,
            rankingBoard: []
        }
    })

    //媒体配置
    const mediaActivityState = reactive<{
        mediaList: MediaItem[],
        sharingList: SharingItem[]
    }>({
        mediaList: [],
        sharingList: []
    })

    //首充数据
    const firstRechargeActivityState = reactive<{ firstConfig: ActivityFirstConfig }>({
        firstConfig: {
            status: 0,
            start_time: 0,
            end_time: 0,
            type: 0,
            levels: [],
        }
    })

    //连续充值
    const continueRechargeActivityState = reactive<{
        continuousList: ContinuousRechargeItem[]
    }>({
        continuousList: [],
    })

    //现金雨（撒钱）数据
    const cashRainActivityState = reactive<{
        cashRainAnimationConfig: CashRainAnimationConfig,
        cashRainPageConfig: CashRainPageConfig,
        cashRainIsReachStandard: CashRainIsReachStandardResult,
        cashRainReceiveReward: { data: number }
    }>({
        cashRainAnimationConfig: {
            status: 0,
            inProgress: false,
            countdown: 0,
            nextStartTime: '',
        },
        cashRainPageConfig: {
            limitReward: 0,
            singleMaxReward: 0,
            reachStandard: 0,
            nextStartTime: '',
            weekendPeriodRespList: [],
            dailyPeriodRespList: []
        },
        cashRainIsReachStandard: {
            isReachStandard: false,
            isReceive: false,
            overflows: false
        },
        cashRainReceiveReward: {
            data: 0
        },
    })

    //签到数据
    const signActivityState = reactive<{
        signConfig: SignConfigResult,
    }>({
        signConfig: {
            rewards: [],
            status: 0,
            rechargeAmount: 0,
            betAmount: 0,
            openDate: '',
        },
    })


    //限时充值数据
    const limitRechargeActivityState = reactive<{
        limittimeRewardConfig: LimitTimeRewardConfig[],
        limittimeOpportunityFlag: LimitTimeOpportunityFlag
    }>({
        limittimeRewardConfig: [],
        limittimeOpportunityFlag: {
            haveQuota: 0,
            activityList: [],
            expiration: 0
        },
    })


    //新手任务数据72h
    const newtaskActivityState = reactive<{
        listNew: NoviceTask[]
    }>({
        listNew: [],
    })

    //周卡数据
    const weekcardActivityState = reactive<{
        weeklyCardConfig: WeeklyCardConfigResult,
        weeklyCardList: WeeklyCard[],
        rewardDetail: CanWeeklyCardRewardDetail[],
        showStatus: number,
        switchStatus: number
    }>({
        weeklyCardConfig: {
            id: 0,
            cardName: '',
            rechargeAmount: 0,
            rewardAmount: 0,
            bonusDetail: '',
            bonusRatio: '',
            bonusToday: 0,
            createTime: 0,
            nextTimeForGet: 0,
            nextTimeForBuy: 0,
            cumulativeIncome: 0,
            cardStatus: 0
        },
        weeklyCardList: [],
        rewardDetail: [],
        showStatus: 0,
        switchStatus: 0
    })

    //周流水排行数据
    const weekBetActivityState = reactive<{
        weekBetSwitch: WeekBetSwitchResult,
        weekBetMyRanking: WeekBetMyRankingResult,
        weekBetQueryRankingReward: WeekBetQueryRankingRewardResult,
        weekBetRankingList: WeekBetRankingListItem[]
    }>({
        weekBetSwitch: {
            status: 0,
            rewardType: 0,
            limitTimes: 0
        },
        weekBetMyRanking: {
            userid: 0,
            rankingEn: '',
            avatar: '',
            betAmount: 0
        },
        weekBetQueryRankingReward: {
            id: 0,
            userid: 0,
            rankingEn: '',
            reward: 0,
            status: 0
        },
        weekBetRankingList: [],
    })

    //VIP权限数据
    const vipActivityState = reactive<{
        gradeReward: Array<VipItem>,
        lossRebate: Array<VipItem>,
        weeklyReturns: Array<VipItem>
    }>({
        gradeReward: [],
        lossRebate: [],
        weeklyReturns: []
    })

    //奖金中心数据
    const bonusCenterActivityState = reactive<{
        bonusCenterDetail: BonusCenterList,
        bonusCenterList: BonusCenterDetail
    }>({
        bonusCenterDetail: [{
            sort: 0,
            type: 0,
            flag: 0,
            icon: "",
            name: "",
            desc: "",
            tips: "",
            btnText: "",
            redirectId: 0,
            redirectContent: "",
            innerList: [
                {
                    name: "",
                    value: ""
                }
            ]
        }],
        bonusCenterList: {
            totalBonus: 0,
            totalDesignatedBonus: 0,
            totalVipBonus: 0,
            designatedBonus: [
                {
                    icon: "",
                    name: "",
                    amount: 0
                }
            ],
            vipBonus: [
                {
                    icon: "",
                    name: "",
                    amount: 0
                }
            ]
        }
    })

    /*******************************活动中心相关 *************************************************/
    // 获取活动中心列表 
    const activity_centerlist_actions = async (args: any = {}, opts?) => {
        let res = await useact.fetchActivityCenterList(args, opts)
        activityCenterList.value = res.data
    }
    // 获取开启的活动列表
    const activity_centerlist_openlist_actions = async (opts?) => {
        const res = await useact.fetchActivityCenterOpenList(setToken(), opts)
        Object.assign(activityOpenList.openlList, res.data)
        console.log(activityOpenList.openlList)
        return res
    }
    // 获取开启的活动列表
    const openActivityList = computed(() => activityOpenList.openlList)

    /************************************ 奖金中心 ********************************************************/
    //奖金中心列表
    const activity_bonus_centerlist_actions = async (opts?) => {
        const res: BonusCenterDetailRes = await useact.fetchBonusCenterList(setToken(), opts)
        bonusCenterActivityState.bonusCenterList = res.data
        return res
    }
    //获取奖金中心列表
    const getBonusCenterList = () => bonusCenterActivityState.bonusCenterList

    //奖金中心统计详情
    const activity_bonus_center_detail_actions = async (args: any = {}, opts?) => {
        const res: BonusCenterListRes = await useact.fetchBonusCenterStatistical(setToken(args), opts)
        bonusCenterActivityState.bonusCenterDetail = res.data
        return res
    }

    //获取奖金中心统计详情
    const getBonusCenterDetail = () => bonusCenterActivityState.bonusCenterDetail

    /*******************************推广相关 *************************************************/
    // 获取活动整合接口
    const activity_center_homeInfo_actions = async (opts?) => {
        const res: ActivityHomeInfoRes = await useact.fetchActivityCenterHomeInfo(setToken(), opts)
        Object.assign(cashRainActivityState.cashRainAnimationConfig, res.data.cashRainAnimationResp)
        Object.assign(cashRainActivityState.cashRainIsReachStandard, res.data.cashRainReachStandardResp)
        Object.assign(signActivityState.signConfig, res.data.checkInConfig)
        continueRechargeActivityState.continuousList = res.data.continuousRechargeBonus?.rechargeRewardDetailList ?? []
        dailytaskActivityState.hasAward = res.data.dailyTasksRewordReceive.data
        Object.assign(limitRechargeActivityState.limittimeOpportunityFlag, res.data.limitTimeRechargeBaseConfig)
        Object.assign(lotteryActivityState.lotteryStatus, res.data.lotteryStatus)
        Object.assign(newtaskActivityState.listNew, res.data.newbieTaskList)
        turntableActivityState.turntablePointLogs = res.data.rouletteCheckNumberDraws
        Object.assign(redPacketActivityState.highlight, res.data.shootingAble)
        weekcardActivityState.showStatus = res.data.weeklyCardReceiveLogo.showStatus
        weekcardActivityState.switchStatus = res.data.weeklyCardReceiveLogo.switchStatus
        return res
    }

    //获取推广配置
    const activity_promote_config_action = async (args: any = {}, opts?) => {
        const res = await useact.fetchPromoteConfig(setToken(args), opts)
        Object.assign(promoteActivityState.promoteConfig, res.data)
    }
    const getPromoteConfig = () => promoteActivityState.promoteConfig
    // 获取推广记录
    const activity_promote_rewardrecord_action = async (args: any = {}, opts?) => {
        const res = await useact.fetchPromoteRewardRecord(setToken(args), opts)
        Object.assign(promoteActivityState.promoteRecord, res.data)
    }
    const getPromoteRecord = () => promoteActivityState.promoteRecord
    // 获取推广统计
    const activity_promote_rewardtotal_action = async (args: any = {}, opts?) => {
        const res = await useact.fetchPromoteRewardTotal(setToken(args), opts)
        Object.assign(promoteActivityState.promoteRewardSummary, res.data)
    }
    // 获取推广统计
    const activity_promote_rewardtotal2_action = async (args: any = {}, opts?) => {
        const res = await useact.fetchPromoteRewardTotal2(setToken(args), opts)
        Object.assign(promoteActivityState.promoteRewardSummary2, res.data)
    }

    const getPromoteRewardSummary = () => promoteActivityState.promoteRewardSummary
    const getPromoteRewardSummary2 = () => promoteActivityState.promoteRewardSummary2
    // 获取推广链接
    const activity_promote_promoteurl_action = async (args: any = {}, opts?) => {
        const res = await useact.fetchPromotePromoteUrl(setToken(args), opts)
        promoteActivityState.promoteUrl = res.data.promoteUrl
    }
    const getPromoteUrl = () => promoteActivityState.promoteUrl
    //领取首充奖励
    const activity_promote_firstrecharge_action = async (opts?) => {
        const res = await useact.fetchPromoteGetReward(setToken(), opts)
        Object.assign(promoteActivityState.promoteRecord, res.data)
    }


    /*******************************日常任务相关*************************************************/
    //请求每日任务开关
    const activity_dailytask_switch_action = async (opts?) => {
        const res = await useact.fetchTaskStatus(opts)
        dailytaskActivityState.state = res.data.data
    }
    //获取每日任务开关
    const getDailyTaskState = () => dailytaskActivityState.state
    //获取每日任务列表-新手任务
    const activity_dailytask_list_new_action = async (opts?) => {
        let params = { taskType: TaskType.New }
        const res = await useact.fetchTaskList(setToken(params), opts)
        dailytaskActivityState.listNew = res.data.data
    }
    //获取每日任务列表-日常任务
    const activity_dailytask_list_daily_action = async (opts?) => {
        let params = { taskType: TaskType.Daily }
        const res = await useact.fetchTaskList(setToken(params), opts)
        dailytaskActivityState.listDaily = res.data.data
    }
    //获取每日任务列表-周常任务
    const activity_dailytask_list_weekly_action = async (opts?) => {
        let params = { taskType: TaskType.Weekly }
        const res = await useact.fetchTaskList(setToken(params), opts)
        dailytaskActivityState.listWeekly = res.data.data
    }
    //获取每日任务列表-宝箱任务
    const activity_dailytask_list_box_action = async (opts?) => {
        let params = { taskType: TaskType.Box }
        const res = await useact.fetchTaskList(setToken(params), opts)
        dailytaskActivityState.listBox = res.data.data
    }
    //查询是否有奖励可以领取
    const activity_dailytask_hasaward_action = async (opts?) => {
        const res = await useact.fetchTaskHasReward(setToken(), opts)
        dailytaskActivityState.hasAward = res.data.data
    }
    //领取任务奖励
    const activity_dailytask_getaward_action = async (data: TaskReceiveReq, opts?) => {
        await useact.fetchTaskReceiveAward(setToken(data), opts)
    }

    //任务列表
    const activity_dailytask_list123_action = async (opts?) => {
        const res = await useact.fetchTaskList123(setToken(), opts)
        // dailytaskActivityState.listNew = res.data.noviceTask
        // dailytaskActivityState.listDaily = res.data.dailyTask
        // dailytaskActivityState.listWeekly = res.data.weekTask

        // allTaskList.value.push(dailytaskActivityState.listNew)
        // allTaskList.value.push(dailytaskActivityState.listDaily)
        // allTaskList.value.push(dailytaskActivityState.listWeekly)
        allTaskList.value = res.data
        // console.log("ssss---"+JSON.stringify(res.data))
        // return res.data
    }

    //获取每日任务列表-新手任务
    const getDailytaskListNew = () => dailytaskActivityState.listNew
    //获取每日任务列表-日常任务
    const getDailytaskListDaily = () => dailytaskActivityState.listDaily
    //获取每日任务列表-周常任务
    const getDailytaskListWeekly = () => dailytaskActivityState.listWeekly
    //获取每日任务列表-宝箱任务
    const getDailytaskListBox = () => dailytaskActivityState.listBox
    //查询是否有奖励可以领取
    const getDailytaskHasAward = () => dailytaskActivityState.hasAward

    const getAllTaskList = () => allTaskList.value

    /***********************转盘相关*********************************************************/
    //获取轮盘基础配置
    const activity_turntable_config_action = async (opts?) => {
        const res = await useact.fetchTurntableConfig({}, opts)
        Object.assign(turntableActivityState.turntableConfig, res.data)
    }
    //抽奖
    const activity_turntable_draw_action = async (data: TurntableSpinReq, opts?) => {
        const res = await useact.fetchTurntableSpin(setToken(data), opts)
        return res.data
    }
    // 转盘抽奖记录
    const activity_turntable_rewardlogs_action = async (opts?) => {
        const res = await useact.fetchTurntableRewardLogs(setToken(), opts)
        res.data.forEach((item: any) => {
            var title = item.remark.split('#')[3]
            item.remark = title
        })
        Object.assign(turntableActivityState.turntableRewardLogs, res.data)
    }
    // 转盘抽奖次数
    const activity_turntable_spincount_action = async (opts?) => {
        const res = await useact.fetchTurntableSpinCount(setToken(), opts)
        Object.assign(turntableActivityState.turntablePointLogs, res.data)
    }
    // 积分分类汇总
    const activity_turntable_wallet_point_group_action = async (opts?) => {
        const res = await useact.fetchPromoteWalletPointGroup(setToken(), opts)
        turntableActivityState.turntablePointGroup = res.data
        Object.assign(turntableActivityState.turntablePointGroup, res.data)
        return res.data
    }

    const activity_turntable_wallet_point_logs_action = async (req: WalletPointsLogsReq, opts?) => {
        const res = await useact.fetchPromoteWalletPointLogs(setToken(req), opts)
        turntableActivityState.turntablePointGroup = res.data
        Object.assign(turntableActivityState.turntablePointGroup, res.data)
        return res.data
    }

    //获取轮盘基础配置
    const getTurntableConfig = () => turntableActivityState.turntableConfig
    //获取转盘抽奖记录
    const getTurntableRewardLogs = () => turntableActivityState.turntableRewardLogs
    //获取转盘抽奖次数
    const getTurntablePointLogs = () => turntableActivityState.turntablePointLogs
    //获取转盘抽奖次数
    const getTurntablePointGroup = () => turntableActivityState.turntablePointGroup

    /***********************充值分享数据相关**********************************************************/

    //获取充值分享配置
    const activity_recharge_share_config_action = async (opts?) => {
        const res = await useact.fetchRechargeShareConfig(setToken(), opts)
        Object.assign(rechargeShareActivityState.config, res.data)
    }
    //领取充值分享领取标识
    const activity_recharge_share_get_action = async (opts?) => {
        const res = await useact.fetchRechargeShare(setToken(), opts)
        Object.assign(rechargeShareActivityState.canGetReward, res.data)
    }
    //领取奖励
    const activity_recharge_share_reward_action = async (opts?) => {
        const res = await useact.fetchRechargeShareGetBonus(setToken(), opts)
        return res
    }
    //分享
    const activity_recharge_share_share_action = async (opts?) => {
        const res = await useact.fetchRechargeShareSharing(setToken(), opts)
        return res
    }
    //获取充值分享配置
    const getRechargeShareConfig = () => rechargeShareActivityState.config
    //获取充值分享领取标识
    const getRechargeShareState = () => rechargeShareActivityState

    /***********************新手、每日抽奖数据相关*********************************************************/
    //获取新手、每日抽奖活动状态
    const activity_lottery_status_action = async (opts?) => {
        const res = await useact.fetchLotteryGetActivityStatus(setToken(), opts)
        Object.assign(lotteryActivityState.lotteryStatus, res.data)
    }

    //获取新手、每日抽奖抽奖标识
    const activity_lottery_flag_action = async (opts?) => {
        const res = await useact.fetchLotteryGetLotteryStatus(setToken(), opts)
        Object.assign(lotteryActivityState.lotteryFlag, res.data)
    }

    //获取每日抽奖活动
    const activity_lottery_daily_action = async (opts?) => {
        const res = await useact.fetchLotteryDailyActivity(setToken(), opts)
        Object.assign(lotteryActivityState.lotteryDaily, res.data)
    }
    //获取新手抽奖活动
    const activity_lottery_new_action = async (opts?) => {
        const res = await useact.fetchLotteryNoviceActivity(setToken(), opts)
        Object.assign(lotteryActivityState.lotteryNew, res.data)
    }
    //获取新手抽奖页面
    const activity_lottery_page_new_action = async (opts?) => {
        let params = setToken()
        Object.assign(params, { activityType: 1 })
        const res = await useact.fetchLotteryPage(params, opts)
        Object.assign(lotteryActivityState.lotteryPageNew, res.data)
    }
    //获取每日抽奖页面
    const activity_lottery_page_daily_action = async () => {
        let params = setToken()
        Object.assign(params, { activityType: 2 })
        const res = await useact.fetchLotteryPage(params)
        Object.assign(lotteryActivityState.lotteryPageDaily, res.data)
    }
    //抽奖
    const active_lotteryLottery_new_action = async () => {
        let params = setToken()
        Object.assign(params, { activityType: 1 })
        const res = await useact.fetchLotteryLottery(params)
        return res
    }
    //抽奖
    const active_lotteryLottery_daily_action = async () => {
        let params = setToken()
        Object.assign(params, { activityType: 2 })
        const res = await useact.fetchLotteryLottery(params)
        return res
    }

    //用户中奖记录
    const activity_lottery_userlog_action = async (data: LotteryUserLogsReq, opts?) => {
        const res = await useact.fetchLotteryCurrentLotteryRecords(setToken(data), opts)
        Object.assign(lotteryActivityState.lotteryUserDrawLogs, res.data)
        return res.data
    }
    //获取抽奖记录
    const activity_lottery_log_action = async (data: LotteryDrawLogsReq) => {
        const res = await useact.fetchLotteryRecords(setToken(data))
        lotteryActivityState.lotteryDrawLogs = res.data
    }

    //获取每日抽奖活动
    const getLotteryDaily = () => lotteryActivityState.lotteryDaily
    //获取新手抽奖活动
    const getLotteryNew = () => lotteryActivityState.lotteryNew
    //获取新手抽奖页面
    const getLotteryPageNew = () => lotteryActivityState.lotteryPageNew
    //获取每日抽奖页面
    const getLotteryPageDaily = () => lotteryActivityState.lotteryPageDaily
    //获取用户中奖记录
    const getLotteryUserDrawLogs = () => lotteryActivityState.lotteryUserDrawLogs
    //获取抽奖记录
    const getLotteryDrawLogs = () => lotteryActivityState.lotteryDrawLogs
    //获取活动状态
    const getLotteryStatus = () => lotteryActivityState.lotteryStatus
    //获取抽奖标识
    const getLotteryFlag = () => lotteryActivityState.lotteryFlag

    /***********************兑换码相关*********************************************************/
    const activity_exchange_code_action = async (data: RedeemPrizeReq, opts?) => {
        const res = await useact.fetchExchangeCode(setToken(data), opts)
        return res
    }

    /***********************红包雨（足球）相关*********************************************************/
    //请求红包雨配置
    const activity_redpacket_rain_config_action = async (opts?) => {
        const res = await useact.fetchRedPacketConfig(setToken(), opts)
        Object.assign(redPacketActivityState.config, res.data)
    }
    //请求球门区域列表
    const activity_redpacket_rain_area_action = async (opts?) => {
        const res = await useact.fetchRedPacketAreaList(setToken(), opts)
        Object.assign(redPacketActivityState.areaList, res.data)
    }
    //请求存在可射门情况
    const activity_redpacket_has_rain_shoot_action = async (opts?) => {
        const res = await useact.fetchRedPacketHighlight(setToken(), opts)
        redPacketActivityState.highlight = res.data
    }
    //射门
    const activity_redpacket_rain_shoot_action = async (req: RedPacketShootReq, opts?) => {
        const res = await useact.fetchRedPacketShoot(setToken(req), opts)
        Object.assign(redPacketActivityState.shoot, res.data)
    }
    //用户射门记录
    const activity_redpacket_rain_shoot_records_action = async (req: RedPacketShootRecordReq, opts?) => {
        const res = await useact.fetchRedPacketshootRecords(setToken(req), opts)
        Object.assign(redPacketActivityState.shootRecord, res.data)
    }

    //获取红包雨配置
    const getRedPacketConfig = () => redPacketActivityState.config
    //获取球门区域列表
    const getRedPacketAreaList = () => redPacketActivityState.areaList
    //获取红包雨状态
    const getRedPacketStatus = () => redPacketActivityState.highlight.showStatus
    //获取红包雨开关状态
    const getRedPacketSwitchStatus = () => redPacketActivityState.highlight.switchStatus
    //获取射门结果
    const getRedPacketShoot = () => redPacketActivityState.shoot
    //获取用户射门记录
    const getRedPacketShootRecord = () => redPacketActivityState.shootRecord


    /***********************锦标赛相关*********************************************************/
    //请求锦标赛页面
    const activity_championship_page_action = async (opts?) => {
        const res = await useact.fetchChampionshipPage(setToken(), opts)
        Object.assign(championshipActivityState.page, res.data)
    }
    //请求活动弹窗
    const activity_championship_popup_action = async (opts?) => {
        const res = await useact.fetchChampionshipPopUp(setToken(), opts)
        Object.assign(championshipActivityState.popup, res.data)
    }

    //请求锦标赛排行榜
    const activity_championship_rank_action = async (data: ChampionshipRankingReq, opts?) => {
        const res = await useact.fetchChampionshipRanking(setToken(data), opts)
        Object.assign(championshipActivityState.rank, res.data)
    }

    //领取奖励
    const activity_championship_getaward_action = async (data: ChampionshipReceiveReq, opts?) => {
        const res = await useact.fetchChampionshipReceive(setToken(data), opts)
        return res
    }
    //游戏是否关联锦标赛
    const activity_championship_game_action = async (data: ChampionshipJoinReq, opts?) => {
        const res = await useact.fetchChampionshipJoinChampionship(setToken(data), opts)
        return res
    }

    //获取锦标赛页面
    const getChampionshipPage = () => championshipActivityState.page
    //获取锦标赛弹窗
    const getChampionshipPopup = () => championshipActivityState.popup
    //获取锦标赛排行榜
    const getChampionshipRank = () => championshipActivityState.rank

    /***********************媒体配置相关*********************************************************/
    //获取媒体配置
    const activity_media_config_action = async () => {
        const res = await useact.fetchShareMediaConfig(setToken())
        Object.assign(mediaActivityState.mediaList, res.data)
    }
    //获取分享配置
    const activity_sharing_config_action = async () => {
        const res = await useact.fetchShareSharingConfig(setToken())
        Object.assign(mediaActivityState.sharingList, res.data)
    }
    //获取媒体配置
    const getMediaConfig = () => mediaActivityState.mediaList
    //获取分享配置
    const getSharingConfig = () => mediaActivityState.sharingList

    /***********************跑马灯相关*********************************************************/
    //获取跑马灯状态
    const activity_marquee_status_action = async () => {
        const res = await useact.fetchMarqueeStatus(setToken())
        return res.data
    }

    /*************************首充相关*******************************************************/
    //获取首充配置
    const activity_first_config_action = async (opts?) => {
        const res = await useact.fetchFirstConfig(setToken(), opts)
        Object.assign(firstRechargeActivityState.firstConfig, res.data)
    }
    //获取首充配置
    const getFirstRechargeConfig = () => firstRechargeActivityState.firstConfig
    //是否存在首充奖励
    const getFirstRechargeStatus = () => {
        return userStore.userInfo.firstReId === null
    }


    /*************************连续充值相关*******************************************************/

    const activity_continue_recharge_config_action = async (args: any = {}, opts?) => {
        const res = await useact.fetchContinueRecharge(setToken(args), opts)

        continueRechargeActivityState.continuousList = res.data.rechargeRewardDetailList
    }
    //获取连续充值配置
    const getContinueRechargeConfig = () => continueRechargeActivityState.continuousList
    //是否连续充值奖励
    const getContinueRechargeStatus = () => continueRechargeActivityState.continuousList?.length > 0 && !getFirstRechargeStatus()



    /*************************现金雨（撒钱）*******************************************************/
    //获取现金雨动画页面
    const activity_cashrain_animation_action = async (opts?: any) => {
        let userId = userStore.userInfo.id.toString()
        const res = await useact.fetchCashRainAnimation({ userId }, opts)
        Object.assign(cashRainActivityState.cashRainAnimationConfig, res.data)
        return res
    }
    //用户是否达标
    const activity_cashrain_isReachStandard_action = async () => {
        const res = await useact.fetchCashIsReachStandard(setToken())
        Object.assign(cashRainActivityState.cashRainIsReachStandard, res.data)
    }
    //现金雨页面
    const activity_cashrain_page_action = async () => {
        const res = await useact.fetchCashRainPage(setToken())
        Object.assign(cashRainActivityState.cashRainPageConfig, res.data)
    }
    //领取奖励
    const activity_cashrain_receive_action = async () => {
        const res = await useact.fetchCashRainReceiveReward(setToken())
        Object.assign(cashRainActivityState.cashRainReceiveReward, res)

        return res
    }

    //获取现金雨动画页面
    const getCashRainConfig = () => cashRainActivityState.cashRainAnimationConfig
    //用户是否达标
    const getCashRainIsReachStandard = () => cashRainActivityState.cashRainIsReachStandard
    //现金雨页面
    const getCashRainPage = () => cashRainActivityState.cashRainPageConfig
    //领取奖励
    const getCashRainReceiveReward = () => cashRainActivityState.cashRainReceiveReward

    /*************************限时充值相关*******************************************************/
    //获取限时充值配置
    const activity_limit_recharge_config_action = async (arg?, opts?) => {
        const res = await useact.fetchLimitTimeGetRewardConfig(setToken(arg), opts)
        limitRechargeActivityState.limittimeRewardConfig = res.data
    }

    //限时充值标识
    const activity_limit_recharge_flag_action = async () => {
        const res = await useact.fetchLimitTimeIsOpportunity(setToken())
        Object.assign(limitRechargeActivityState.limittimeOpportunityFlag, res.data)
    }

    //获取限时充值配置
    const getLimittimeRewardConfig = () => limitRechargeActivityState.limittimeRewardConfig
    //获取限时充值标识
    const getLimittimeOpportunityFlag = () => limitRechargeActivityState.limittimeOpportunityFlag

    /*************************新手任务72h*******************************************************/
    //获取新手任务列表
    const activity_newtask_list_action = async () => {
        const res = await useact.fetchNoviceTaskList(setToken())
        Object.assign(newtaskActivityState.listNew, res.data)
        return res
    }
    //关注官媒 跳转成功调用
    const activity_newtask_follow_action = async () => {
        const res = await useact.fetchNoviceTaskFollowMedia(setToken())
        return res
    }
    //领取奖励
    const activity_newtask_receive_action = async (data: NewTaskReceive) => {
        const res = await useact.fetchNoviceTaskReceive(setToken(data))
        return res
    }

    //获取新手任务列表
    const getNewTaskList = () => newtaskActivityState.listNew


    /*************************周卡相关*******************************************************/
    //获取周卡配置
    const activity_weekcard_config_action = async (data: WeeklyCardConfigReq, opts?) => {
        const res = await useact.fetchWeeklyCardWeeklyCardConfig(setToken(data), opts)
        Object.assign(weekcardActivityState.weeklyCardConfig, res.data)
        return res
    }
    //获取可用周卡列表
    const activity_weekcard_list_action = async (opts?) => {
        const res = await useact.fetchWeeklyCardWeeklyCardList(setToken(), opts)
        Object.assign(weekcardActivityState.weeklyCardList, res.data)
    }
    //获取周卡及奖励明细
    const activity_weekcard_detail_action = async (opts?) => {
        const res = await useact.fetchWeeklyCardWeeklyCardRewardDetail(setToken(), opts)
        Object.assign(weekcardActivityState.rewardDetail, res.data)
        return res
    }
    //领取周卡奖励
    const activity_weekcard_receive_action = async (data: CardGetBonusRewardReq, opts?) => {
        const res = await useact.fetchWeeklyCardGetBonus(setToken(data), opts)
        return res
    }
    //获取领取标识
    const activity_weekcard_flag_action = async () => {
        const res = await useact.fetchWeeklyCardCanGetReward(setToken())
        weekcardActivityState.showStatus = res.data.showStatus
        weekcardActivityState.switchStatus = res.data.switchStatus
        return res
    }

    //获取周卡配置
    const getWeekcardConfig = () => weekcardActivityState.weeklyCardConfig
    //获取周卡列表
    const getWeekcardList = () => weekcardActivityState.weeklyCardList
    //获取周卡奖励明细
    const getWeekcardRewardDetail = () => weekcardActivityState.rewardDetail
    //获取领取标识
    const getWeekcardCanGetRewardReward = () => weekcardActivityState.showStatus
    //获取活动开关 1开启 2关闭
    const getWeekcardSwitchStatus = () => {
        return weekcardActivityState.switchStatus === 1
    }


    /*************************周流水排行*******************************************************/
    //周流水排行开关
    const activity_weekly_bet_rank_switch_action = async () => {
        const res = await useact.fetchWeeklyBetGetWeekBetSwitch(setToken())
        weekBetActivityState.weekBetSwitch = res.data;
        return res.data
    }
    //我的排名
    const activity_weekly_bet_rank_myRinking_action = async () => {
        const res = await useact.fetchWeeklyBetMyRanking(setToken())
        weekBetActivityState.weekBetMyRanking = res.data;
        return res.data
    }

    //查询上周奖励
    const activity_weekly_bet_rank_reward_action = async () => {
        const res = await useact.fetchWeeklyBetQueryRankingReward(setToken())
        weekBetActivityState.weekBetQueryRankingReward = res.data;
        return res.data
    }

    //周流水排行榜
    const activity_weekly_bet_rank_list_action = async () => {
        const res = await useact.fetchWeeklyBetQueryRankingList(setToken())
        weekBetActivityState.weekBetRankingList = res.data;
        return res.data
    }

    //领取上周奖励
    const activity_weekly_bet_rank_receive_action = async (data: ReceiveRankingRewardReq) => {
        const res = await useact.fetchWeeklyBetReceiveRankingReward(setToken(data))
        return res
    }
    //获取周流水排行开关
    const getWeekBetSwitch = () => weekBetActivityState.weekBetSwitch
    //获取我的排名
    const getWeekBetMyRanking = () => weekBetActivityState.weekBetMyRanking
    //获取上周奖励
    const getWeekBetQueryRankingReward = () => weekBetActivityState.weekBetQueryRankingReward
    //获取周流水排行榜
    const getWeekBetRankingList = () => weekBetActivityState.weekBetRankingList


    /*********************签到相关 */

    //签到
    const activity_sign_action = async (opts?) => {
        const res = await useact.fetchCheckIn(setToken(), opts)
        return res
    }
    //获取签到配置
    const activity_sign_config_action = async (opts?) => {
        const res = await useact.fetchCheckInConfig(setToken(), opts)
        Object.assign(signActivityState.signConfig, res.data)
    }
    //获取签到配置
    const getSignConfig = () => signActivityState.signConfig
    //是否签到
    const activity_sign_status_action = async (opts?) => {
        const res = await useact.fetchCheckInIsCheckIn(setToken(), opts)
        return res
    }

    /*********************VIP权限相关 */
    const activity_vip_privilege_action = async (opts?) => {
        const res = await useact.fetchVipPrivilege(setToken(), opts)
        Object.assign(vipActivityState, res.data)
    }

    const activity_vip_LossRebate_action = async (opts?) => {
        const res = await useact.fetchVipLossRebate(setToken(), opts)
        return res.data
    }

    const activity_vip_LossRebateReceive_action = async (data: { id: string }) => {
        const res = await useact.fetchReceiveVipLossRebate(setToken(data))
        return res
    }

    //获取VIP权限
    const getVipPrivilege = () => vipActivityState

    // 下载奖励
    const activity_RealPersonDetection = async (data: any, opts?) => {
        const res = await useact.fetchRealPersonDetection(setToken(data), opts)
        return res.data
    }

    return {
        // 活动列表相关
        allTaskList,
        activityCenterList,
        openActivityList,
        activity_centerlist_actions,
        activity_centerlist_openlist_actions,
        activity_center_homeInfo_actions,
        //奖金中心
        bonusCenterActivityState,
        activity_bonus_centerlist_actions,
        getBonusCenterList,
        activity_bonus_center_detail_actions,
        getBonusCenterDetail,
        // 推广相关
        promoteActivityState,
        activity_promote_config_action,
        activity_promote_rewardrecord_action,
        activity_promote_rewardtotal_action,
        activity_promote_rewardtotal2_action,
        activity_promote_promoteurl_action,
        activity_promote_firstrecharge_action,
        getPromoteConfig,
        getPromoteRecord,
        getPromoteRewardSummary,
        getPromoteRewardSummary2,
        getPromoteUrl,
        //日常任务相关
        dailytaskActivityState,
        activity_dailytask_switch_action,
        getDailyTaskState,
        activity_dailytask_list_new_action,
        activity_dailytask_list_daily_action,
        activity_dailytask_list_weekly_action,
        activity_dailytask_list_box_action,
        activity_dailytask_hasaward_action,
        activity_dailytask_getaward_action,
        activity_dailytask_list123_action,
        getDailytaskListNew,
        getDailytaskListDaily,
        getDailytaskListWeekly,
        getDailytaskListBox,
        getDailytaskHasAward,
        getAllTaskList,
        //转盘相关
        turntableActivityState,
        activity_turntable_config_action,
        activity_turntable_draw_action,
        activity_turntable_rewardlogs_action,
        activity_turntable_spincount_action,
        activity_turntable_wallet_point_group_action,
        activity_turntable_wallet_point_logs_action,
        getTurntableConfig,
        getTurntableRewardLogs,
        getTurntablePointLogs,
        getTurntablePointGroup,
        //充值分享相关
        rechargeShareActivityState,
        activity_recharge_share_config_action,
        activity_recharge_share_get_action,
        activity_recharge_share_reward_action,
        activity_recharge_share_share_action,
        getRechargeShareConfig,
        getRechargeShareState,
        //新手、每日抽奖相关
        lotteryActivityState,
        activity_lottery_status_action,
        activity_lottery_flag_action,
        activity_lottery_daily_action,
        activity_lottery_new_action,
        activity_lottery_page_new_action,
        activity_lottery_page_daily_action,
        active_lotteryLottery_new_action,
        active_lotteryLottery_daily_action,
        activity_lottery_userlog_action,
        activity_lottery_log_action,
        getLotteryDaily,
        getLotteryNew,
        getLotteryPageNew,
        getLotteryPageDaily,
        getLotteryUserDrawLogs,
        getLotteryDrawLogs,
        getLotteryStatus,
        getLotteryFlag,
        //兑换码相关
        activity_exchange_code_action,
        //红包雨（足球）相关
        redPacketActivityState,
        activity_redpacket_rain_config_action,
        activity_redpacket_rain_area_action,
        activity_redpacket_has_rain_shoot_action,
        activity_redpacket_rain_shoot_action,
        activity_redpacket_rain_shoot_records_action,
        getRedPacketConfig,
        getRedPacketAreaList,
        getRedPacketStatus,
        getRedPacketSwitchStatus,
        getRedPacketShoot,
        getRedPacketShootRecord,
        //锦标赛相关
        championshipActivityState,
        activity_championship_page_action,
        activity_championship_popup_action,
        activity_championship_rank_action,
        activity_championship_getaward_action,
        activity_championship_game_action,
        getChampionshipPage,
        getChampionshipPopup,
        getChampionshipRank,
        //媒体配置
        mediaActivityState,
        activity_media_config_action,
        activity_sharing_config_action,
        getMediaConfig,
        getSharingConfig,
        //跑马灯
        activity_marquee_status_action,
        //首充
        firstRechargeActivityState,
        activity_first_config_action,
        getFirstRechargeConfig,
        getFirstRechargeStatus,
        //连续充值
        continueRechargeActivityState,
        activity_continue_recharge_config_action,
        getContinueRechargeConfig,
        getContinueRechargeStatus,
        //现金雨
        cashRainActivityState,
        activity_cashrain_animation_action,
        activity_cashrain_isReachStandard_action,
        activity_cashrain_page_action,
        activity_cashrain_receive_action,
        getCashRainConfig,
        getCashRainIsReachStandard,
        getCashRainPage,
        getCashRainReceiveReward,
        //限时充值
        limitRechargeActivityState,
        activity_limit_recharge_config_action,
        activity_limit_recharge_flag_action,
        getLimittimeRewardConfig,
        getLimittimeOpportunityFlag,
        //新手任务72h
        newtaskActivityState,
        activity_newtask_list_action,
        activity_newtask_follow_action,
        activity_newtask_receive_action,
        getNewTaskList,
        //周卡
        weekcardActivityState,
        activity_weekcard_config_action,
        activity_weekcard_list_action,
        activity_weekcard_detail_action,
        activity_weekcard_receive_action,
        activity_weekcard_flag_action,
        getWeekcardConfig,
        getWeekcardList,
        getWeekcardRewardDetail,
        getWeekcardCanGetRewardReward,
        getWeekcardSwitchStatus,
        //周流水排行
        weekBetActivityState,
        activity_weekly_bet_rank_switch_action,
        activity_weekly_bet_rank_myRinking_action,
        activity_weekly_bet_rank_reward_action,
        activity_weekly_bet_rank_list_action,
        activity_weekly_bet_rank_receive_action,
        getWeekBetSwitch,
        getWeekBetMyRanking,
        getWeekBetQueryRankingReward,
        getWeekBetRankingList,
        //签到
        signActivityState,
        activity_sign_action,
        activity_sign_config_action,
        getSignConfig,
        activity_sign_status_action,
        //VIP权限
        vipActivityState,
        activity_vip_privilege_action,
        activity_vip_LossRebate_action,
        activity_vip_LossRebateReceive_action,
        getVipPrivilege,
        // 下载奖励
        activity_RealPersonDetection
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useActiveStore as any, import.meta.hot))
}
