import { defineStore } from 'pinia'

export type GlobalData = {
  wsServerURL: string
  betybyLIB: string
}
export const useGlobalStore = defineStore('xfun-global', () => {

  let data = ref<GlobalData>({
    wsServerURL: '',
    betybyLIB: ''
  });

  const init = () => {
    data.value.wsServerURL = process.env.NUXT_WSSERVER_URL
    data.value.betybyLIB = process.env.NUXT_BETYBY_LIB
  }

  return {
    data,
    init
  }
})
