<template>
    <v-bottom-sheet v-model="show">
        <div class="game-box">
            <div class="pa-3 game_card">
                <div class="d-flex mb-4">
                    <v-img :src="cards.icon" min-height="196" max-height="196" max-width="165" class="game_img">
                        <template v-slot:placeholder>
                            <div class="d-flex align-center justify-center fill-height bg-grey-darken-4">
                                <v-progress-circular :size="20" color="blue-grey" indeterminate></v-progress-circular>
                            </div>
                        </template>
                    </v-img>
                    <div class="game-content pr-6">
                        <div class="d-flex flex-row">
                            <div class="w-100">
                                <div class="game__name">{{ cards.title || cards.gameName }}</div>
                                <div class="game__title">{{ cards.sectitle || cards.nickname }}</div>
                                <div class="game__rtp" v-if="cards.rtp">RTP:{{ cards.rtp }}%</div>
                                <div class="game__rtp1" v-if="cards.maxOdds">Max Win:{{ cards.maxOdds }}x</div>
                                <div class="game__originals" v-if="cards?.userdata?.mid == 10">
                                    <v-img :src=OriginalsImg inline width="20" height="20" class="mr-1"></v-img><span>É
                                        possível fazer
                                        apostas do bônus</span>
                                </div>
                                <div class="game__no__originals" v-else>
                                    <img :src="IconImg" class="mr-1"/><span>Bônus não pode ser aposta</span>
                                </div>
                                <template v-if="cards.topicNames">
                                    <div class="game_topic">
                                        <span v-for="(item, index) in cards.topicNames" :key="index"
                                            class="game_topic_item">{{ item }}</span>
                                    </div>
                                </template>
                            </div>
                            <div class="d-flex flex-column">
                                <v-img :src="closeImg" width="24" class="closeIcon" @click="handleClose"></v-img>
                                <v-img v-if="userstore.isLogin" class="collectstyle" @click="handleCollect"
                                    :src="hascollect ? collect : bocollect" :width="24" :height="24"></v-img>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-space-between" style="margin: 0 -6px;">
                    <XButton class="gameBtn-left" v-if="!hidePlayButton" @click="handleLeft" skin="weak-1"
                        endicon="mdi-chevron-right">
                        Fun mode </XButton>
                    <XButton class="gameBtn-right" :class="{ 'gameBtn': hidePlayButton }" endicon="mdi-chevron-right"
                        @click="handleRight">
                        {{ showLoginText
                            ? 'Sign In' : 'Real mode' }} </XButton>
                </div>
            </div>
        </div>
    </v-bottom-sheet>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import bocollect from "@/assets/components/gamepop/icon_heart.png"
import collect from "@/assets/components/gamepop/Group48592.png" 
import closeImg from "@/assets/components/gamepop/close.png"
import OriginalsImg from "@/assets/components/gamepop/Originals.png"
import IconImg from "@/assets/components/gamepop/Icon.png"
import { ref, nextTick } from 'vue'
import { useBus } from '../composables/xfun/bus';
const { t } = useI18n()
const userstore = useUserStore()
const activityStore = useActiveStore()
const hidePlayButton = ref(false)
const showLoginText = ref(false)
const cards: any = ref()
const show = ref(false)
const router = useRouter()
let bus: any = useBus()
let Gamesaved = computed(() => t('GamePop.Gamesaved'))
let Gamesavecanceled = computed(() => t('GamePop.Gamesavecanceled'))

const hascollect = ref(false)

const handleGame = async (card: any) => {
    show.value = true
    cards.value = card
    //未登录情况
    if (!userstore.isLogin) {
        if (!cards.value.hasDemo) {
            hidePlayButton.value = true
        } else {
            //未登录但支持试玩
            hidePlayButton.value = false
        }
        showLoginText.value = true
    } else {
        //登录但不支持试玩 则隐藏试玩按钮

        if (!cards.value.hasDemo) {
            hidePlayButton.value = true
            showLoginText.value = false
        } else {
            //登录且支持试玩 则弹出试玩弹窗
            showLoginText.value = false
            hidePlayButton.value = false
        }
    }

    hascollect.value = cards.value.userdata.hascollect && cards.value.userdata.hascollect

    await nextTick()
    var node = document.getElementsByClassName('v-overlay__scrim')
    if (node && node.length > 0) {
        var n: any = node[0]
        n.style['backdropFilter'] = 'blur(6px)'
        // n.style['opacity'] = 1
    }
}

defineExpose({ handleGame })

const handleRight = async () => {
    handleClose()
    if (showLoginText.value) {
        router.replace({ name: 'Login' })
    } else {
        var gameid = cards.value.userdata.gameid
        var mid = cards.value.userdata.mid

        try {
            let res: any = await userstore.game_gameLogin_actions({
                merId: mid,
                gameId: gameid
            })
            if (res.data.token != null) {
                router.push("/Betby?bt-path=/")
                bus.fireburyingpoint.emit("game_real", "betby")
            }
            else {
                let resChampion = await activityStore.activity_championship_game_action({ gameMid: mid, gameId: gameid })
                let ischampion: any = resChampion.data
                router.push({
                    name: 'RunGame', query: {
                        gameid: gameid, mid: mid,
                        ischampion: ischampion,
                        type: res.data.urlType
                    }
                })

                bus.fireburyingpoint.emit("game_real", {
                    gamename: res.data.gameName,
                    gameid: gameid, mid: mid,
                })
            }


        } catch (error: any) {
            if (error.isMaintain) {
                bus.fireMaintainDialog.emit(error)
            }

            console.log(error)
        }
    }
}
const handleLeft = async () => {
    handleClose()
    var gameid = cards.value.userdata.gameid
    var mid = cards.value.userdata.mid
    try {
        let res: any = await userstore.game_gametrial_actions({
            merId: mid,
            gameId: gameid,
        })
        if (res.data.token != null) {
            router.push("/Betby?bt-path=/")
            bus.fireburyingpoint.emit("game_try", "betby")
        }
        else {
            router.push({
                name: 'RunGame', query: {
                    gameid: gameid, mid: mid,
                    type: res.data.urlType
                }
            })

            bus.fireburyingpoint.emit("game_try", {
                gamename: res.data.gameName,
                gameid: gameid, mid: mid,
            })
        }


    } catch (error: any) {
        if (error.isMaintain) {
            bus.fireMaintainDialog.emit(error)
        }
        console.log(error)
    }
}

const handleClose = () => {
    show.value = false
}

const handleCollect = async () => {

    if (hascollect.value) {
        var gameid = cards.value.userdata.gameid
        var mid = cards.value.userdata.mid

        var res = await userstore.game_removecollects_actions({
            mer: mid,
            gameId: gameid
        })

        if (res.code == 1) {
            hascollect.value = !hascollect.value
            bus.firemsg.emit(Gamesavecanceled.value, 'success')
        }
    }
    else {

        bus.fireburyingpoint.emit("game_collect")

        var gameid = cards.value.userdata.gameid
        var mid = cards.value.userdata.mid

        let res = await userstore.game_addcollects_actions({
            mer: mid,
            gameId: gameid
        })

        if (res.code == 1) {
            hascollect.value = !hascollect.value
            bus.firemsg.emit(Gamesaved.value, 'success')
            // if (userstore.getlocalgamecount().value > 30) {
            //     bus.firemsg.emit('Gamesaved.value', 'success')
            // }
            // else {
            //     bus.firemsg.emit(Gamesaved.value,, 'success')
            // }
        }
    }


}

</script>
<style lang="scss" scoped>
.game-box {
    border-top: 1px solid transparent;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(30deg, #FFFFFF 0%, #6B6B81 99.54%), linear-gradient(90deg, #FFFFFF 0%, #6B6B81 99.54%);
}

.game_card {
    background: rgba(#1E1E2A, 0.96);
    border-radius: 15px 15px 0 0;

    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.game_img {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    margin-right: 12px;
}

.game-content {
    flex: 1;
    position: relative;

    .game__name {
        color: #FFFFFF;
        font-size: 15px;
        word-break: break-all;
        font-weight: 700;
        font-family: "Microsoft YaHei Bold";
    }

    .game__title {
        color: #A6A6BD;
        font-size: 13px;
    }

    .game__rtp {
        color: #4F40E3;
        font-size: 13px;
        line-height: normal;
    }

    .game__rtp1 {
        color: #7669F5;
        font-size: 13px;
        line-height: normal;
    }

    .game__originals {
        font-size: 13px;
        line-height: normal;
        margin-top: 8px;
        color: #A6A6BD;
        display: flex;
        align-items: center;
    }

    .game__no__originals {
        font-size: 12px;
        line-height: normal;
        margin-top: 8px;
        color: #97a8c7;
        display: flex;
        align-items: center;

    }

    .game_topic {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
        width: 100%;
        gap: 5px;
        margin-top: 6px;
    }

    .game_topic_item {
        color: #A6A6BD;
        text-align: center;
        font-size: 13px;
        line-height: normal;
        background: rgba(112, 114, 116, 0.5);
        padding: 2px 4px;
        border-radius: 5px;
    }

    .closeIcon {
        position: absolute;
        top: 0px;
        right: 0px;
        color: #A6A6BD;
    }
}

.btn-left,
.btn-right {
    height: 40px;
    font-size: 16px;
    border: 1px solid #323c3c;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.btn-left {
    background: #1f2128;
    color: #9d4e18;
    margin-right: 12px;
}

.btn-right {
    background: #de6a1a;
}

.collectstyle {
    position: absolute;
    right: 0px;
    top: 40px;
}

:deep(.v-overlay__scrim) {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px) !important;
    opacity: 1;
}

.gameBtn-right.gameBtn {
    flex-basis: 45%;
    flex-grow: 1;
}

.gameBtn-left,
.gameBtn-right {
    flex-basis: 45%;
    flex-grow: 0;
}
</style>