<template>
  <LaunchPage v-if="!isReady" />
  <ClientOnly>
    <van-config-provider theme="dark">
      <HeadAndMeta />
      <VitePwaManifest />
      <NuxtLayout ref="root">
        <NuxtPage keepalive/>
      </NuxtLayout>
      <PopupWrap />
    </van-config-provider>
  </ClientOnly>
</template>

<script setup lang="ts">
  import { useBus } from '~/composables/xfun/bus'
  import { getEventValue, writeKV, postMessage } from './utils'
  import {
    FetchEvents,
    generateRequestFunction,
    onFetchEvent,
  } from './composables/xfun/net'
  const root = ref(null)
  const isReady = ref(false)
  
  if (process.client) {
    const bus = useBus()
    const userStore = useUserStore()
    const app = useNuxtApp()
    const router = useRouter()
    const route = useRoute()
    const { setLocale, t } = useI18n()
    
    let fromPage: any = route.name
    router.afterEach((to, from) => {
      fromPage = to.name
      return true
    })

    onMounted(async () => {
      console.log('App.onMounted')
      // 设置语言
      setLocale('pt')
      // @ts-ignore
      window.$appbus = bus

      var _window: any = window
      _window.deviceinfo = getMobSysInfo()
      console.info('mobile info: ', _window.deviceinfo)
      console.info('js env: ', import.meta.env.MODE)
      console.info('UA: ', navigator.userAgent)
      console.info('device', getdevicesInfo())
      console.info('lastDevice', userStore.userInfo.lastDevice)
      initBridge()

      userStore.user_fetchdownloadrewardconfig()

      let key = await userStore.user_fetchdownloadrealKey()
      console.info('key', key)
      setCaptchaKey(key)

      // if (userStore.isLogin) {
      //   userStore.user_fetchdownloadrewardsave({
      //     userid: userStore.userInfo.id,
      //     uuid: getuuid(),
      //     device: getdevicesInfo(),
      //     realResult: 0
      //   })
      // }

      if (getDevicesId(userStore.userInfo.lastDevice)  != getdevicesInfo()) {
        userStore.user_force_logout_actions()
      }
      // 循环检测
      checkReady()
    })

    const checkReady = () => {
      nextTick(() => {
        if (!root.value) {
          checkReady()
        } else {
          setTimeout(() => {
            isReady.value = true
          }, 100)
        }
      })
    }

    // 监听全局事件
    onFetchEvent((data: any) => {
      let name = data.name
      let args = data.args
      if (
        name == FetchEvents.EVENT_NET_MESSAGE ||
        name == FetchEvents.EVENT_SERVICE_MESSAGE
      ) {
        console.log('[onFetchEvent]process message', { args })
        // 网络，后端的错误消息
        bus.firemsg.emit(data.args, 'error')
      } else if (name == FetchEvents.EVENT_KICK_OUT) {
        console.log('[onFetchEvent]process kickout', { args })
        // 强制下线
        bus.firekickout.emit()
      } else if (name == FetchEvents.EVENT_EXTEND_TOKEN) {
        console.log('[onFetchEvent]process extend token', { args })
        // token续租
        var url = args.url
        var body = args.body
        var opts = args.opts
        var reject = args.reject
        var resolve = args.resolve
        userStore
          .user_extendtoken_actions()
          .then((res: any) => {
            console.warn('[user_extendtoken_actions]', url, body)
            generateRequestFunction(url, body, opts)
              .then((response) => {
                resolve(response)
              })
              .catch((err) => {
                console.error('[user_extendtoken_actions]Resend failed', args)
                reject(err)
              })
          })
          .catch((err) => {
            console.error(
              '[user_extendtoken_actions] Extend token failed',
              args,
            )
            reject(err)
          })
      }
    })

    let tips: any = ref(t('tabbar.Tips'))
    let tipsContent: any = ref(t('tabbar.tipsContent'))
    bus.firekickout.on(async () => {
      console.log('[firekickout]process kickout')
      bus.fireCommDialog.emit({
        title: tips,
        content: tipsContent,
        type: DialogStyle.OK,
        callbackOk: async () => {
          // 强制登出
          console.log('[client]user_force_logout_actions')
          userStore.user_force_logout_actions()
          // 回到首页
          setTimeout(() => {
            console.log('[dialog]firegohome')
            bus.firegohome.emit()
          }, 100)
          return true
        },
      })
    })

    // 埋点处理
    bus.fireburyingpoint.on((eventName: any, params: any = {}) => {
      if (userStore.userInfo) {
        //@ts-ignore
        app.$ws.wssend(
          JSON.stringify({
            msgType: MsgEnum.EVENTT,
            msgData: {
              userId: userStore.userInfo.id,
              eventType: getEventValue(eventName),
              extralParam: JSON.stringify(params),
            },
          }),
        )
      } else {
        //@ts-ignore
        app.$ws.wssend(
          JSON.stringify({
            msgType: MsgEnum.EVENTT,
            msgData: {
              userId: 0,
              eventType: getEventValue(eventName),
              extralParam: JSON.stringify(params),
            },
          }),
        )
      }
    })

    bus.fireNewVersion.reset()
    bus.fireNewVersion.on((version: any) => {
      // Only in apk & ios
      if (getdevicesInfo() > 3 ) return

      if (fromPage != 'index') return

      let oldver = readKV('version')

      if (oldver == null) {
        writeKV('version', version)
        oldver = version
      }

      if (oldver == version) {
        return
      }

      bus.fireCommDialog.emit({
        title: 'Dicas',
        content: 'Por favor atualize',
        type: DialogStyle.OK,
        callbackOk: () => {
          writeKV('version', version)
          location.reload()
          return true
        },
      })
    })

    bus.fireWsMessage.on(async (msg: any) => {
        if (msg.msgType == 'version') {
          bus.fireNewVersion.emit(msg.msgData)
        } else if (msg.msgType == MsgEnum.RECHARGE_SUCCESS) {
          await userStore.wallet_get_balance_actions()
          //bus.fireburyingpoint.emit("recharge_success")
          let data = JSON.parse(msg.msgData)
          console.log('recharge_success', data)

          // if (data.isFirst) {
          //   postMessage('firstrecharge', data)
          //   postMessage('recharge', data)
          // } else {
          //   postMessage('recharge', data)
          // }
        } else if (msg.msgType == MsgEnum.EXCHANGE_SUCCESS) {
          //bus.fireburyingpoint.emit("withdraw_success")
          postMessage('withdrawOrderSuccess')
        } else if (msg.msgType == MsgEnum.EXCHANGE_FAIL) {
          //bus.fireburyingpoint.emit("withdraw_fail")
        }
    })
  }
</script>

<style lang="scss">
  .default-enter-active,
  .default-leave-active {
    transition: all 0.4s;
  }

  .default-enter-from,
  .default-leave-to {
    opacity: 0;
    filter: blur(1rem);
  }

  body:before {
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('/assets/images/body/bk.png') center 0 no-repeat;
    background-size: cover;
  }

  body {
    line-height: 1.6;
  }

  *::-webkit-scrollbar {
    display: none !important;
    max-height: 0px !important;
  }

  *::-webkit-scrollbar-thumb {
    display: none !important;
  }

  .v-input__control .v-field {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.14) 23.23%,
      rgba(255, 255, 255, 0.0658) 100%
    );
    border-radius: 15px;
  }

  .v-list-group__header {
    background-color: rgb(38, 40, 40) !important;
    border-radius: 10px !important;
  }

  .v-input__control
    .v-field
    .v-field__field
    .v-field__input
    input::placeholder {
    color: rgba(147, 147, 147, 0.7) !important;
    font-size: 15px;
  }

  .v-input__details {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .van-picker__toolbar {
    background: #1d203a;

    .van-picker__title {
      font-weight: 400 !important;
    }

    .van-picker__cancel,
    .van-picker__confirm,
    .van-picker__title {
      color: #e4b384;
    }
  }

  .van-overlay {
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .loading {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px) !important;
    opacity: 1;
    z-index: 999;
  }

  .custom-important-text-700 {
    font-size: 17px;
    color: #ffffff;
    font-weight: 700;
    font-family: Microsoft YaHei Bold !important;
  }

  .custom-important-text-waring-700 {
    font-size: 17px;
    color: #eabd31;
    font-weight: 700;
    font-family: Microsoft YaHei Bold !important;
  }

  .custom-important-text-700-13 {
    font-size: 13px;
    color: #ffffff;
    font-weight: 700;
    font-family: Microsoft YaHei Bold !important;
  }

  .custom-important-text-400 {
    font-size: 17px;
    color: #ffffff;
    font-weight: 400;
    font-family: 'ARIAL';
  }

  .custom-ordinary-text {
    font-size: 13px;
    color: #a6a6bd;
    font-family: 'ARIAL';
  }

  .custom-ordinary-text-bule {
    font-size: 13px;
    color: #7669f5;
    font-family: 'ARIAL';
  }

  .custom-ordinary-text-white {
    font-size: 13px;
    color: #ffffff;
    font-family: 'ARIAL';
  }

  .custom-ordinary-text-waring {
    font-size: 13px;
    color: #db5e3a;
    font-family: 'ARIAL';
  }

  .custom-small-text-400 {
    color: #a6a6bd;
    font-size: 11px;
    font-family: 'ARIAL';
  }

  .custom-small-text-white {
    color: #ffffff;
    font-size: 11px;
    font-family: 'ARIAL';
  }

  .font-weight-bold {
    font-weight: 700;
    font-family: Microsoft YaHei Bold;
  }

  .game-custom-name {
    background: linear-gradient(
      180deg,
      rgba(75, 90, 255, 0.12) -19.77%,
      rgba(75, 90, 255, 0.12) 28.71%,
      rgba(75, 90, 255, 0) 100%
    );
  }

  .game--name1 {
    font-size: 9px;
    line-height: 10px;
    font-weight: 400;
    color: #ffffff;
  }

  .game--name2 {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    color: #0eae70;
  }

  .custom-swiper-slide {
    text-align: center;
    border-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: rgba(204, 202, 202, 0.12);
  }

  .custom-swiper-slide img {
    display: block;
    object-fit: fill;
    height: 100%;
    width: 100%;
  }
</style>
