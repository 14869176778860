<template>
    <div class="loadinPage" >
      <div class="vmain">
      </div>
    </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.loadinPage{
  background-color: #000;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  overflow: hidden
}

.vmain {
  background-image: url('../assets/images/LaunchPage/background.png') !important;
  background-size: 100% 100%;
  height: 100dvh;
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100dvw;
}

</style>
