import { useXCookie } from '~/composables/xfun/useXCookie'

export default defineNuxtPlugin((nuxtApp) => {
    let userStore = useUserStore()
    let useActive = useActiveStore()
    let cookie = useXCookie()
    /**
     * 这里的参数从用户信息里面的优先级最高。url带过来的参数可以先保存在本地存储里面 
     */
    
    window.promoteUrl = useActive.getPromoteUrl()

    // 从url中获取邀请码
    userStore.invitationCode = window.invitationCode

    // 从url中获取AgentCode
    userStore.AgentCode = window.AgentCode

    const fb_pixel = userStore.channelInfo.pixelIdFb.length == 0 ? window.fb_pixel : userStore.channelInfo.pixelIdFb
    userStore.channelInfo.pixelIdFb = fb_pixel

    const kwai_pixel = userStore.channelInfo.pixelIdKwai.length == 0 ? window.kwai_pixel : userStore.channelInfo.pixelIdKwai
    userStore.channelInfo.pixelIdKwai = kwai_pixel

    const tiktok_pixel = userStore.channelInfo.pixelIdTiktok.length == 0 ? window.tiktok_pixel : userStore.channelInfo.pixelIdTiktok
    userStore.channelInfo.pixelIdTiktok = tiktok_pixel

    const gtagid = userStore.channelInfo.pixelIdGoogle.length == 0 ? window.gtagid : userStore.channelInfo.pixelIdGoogle
    userStore.channelInfo.pixelIdGoogle = gtagid

    resetPixel()

    setTimeout(() => {
        // 尝试连接ws
        // @ts-ignore
        nuxtApp.$ws.wsconnent(userStore.userToken, userStore.userInfo.id)
    }, 200);
})